import { Accent, Headline1, media } from '@boxine/tonies-ui'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ReactComponent as Binocular } from '../../assets/binocular.svg'
import { Head } from '../head'
import { MyToniesLogo } from '../MyToniesLogo'
import { Text } from '../Typography'

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #f7f7f7;

  #navigation {
    height: 4rem;
    box-shadow: 0 -4px 2px 4px rgba(0, 0, 0, 0.333);
    display: flex;
    align-items: center;
    padding: 0 3rem;
    background-color: white;
    ${media.laptopL`
      height: 5rem;
    `}
  }

  #content {
    text-align: center;
    padding-top: 2em;
  }
  .binocular {
    width: 20em;
  }
`

function Maintenance() {
  const { t } = useTranslation(['default', 'status-pages'])
  return (
    <Wrapper>
      <Head pageTitle={t('status-pages:MaintenancePageTitle')} />
      <div id="navigation">
        <MyToniesLogo />
      </div>
      <div id="content">
        <Binocular />
        <Trans i18nKey="status-pages:WhereAreTonies">
          <Headline1>
            Well, where are you, <Accent>Tonies?</Accent>
          </Headline1>
        </Trans>
        <Text>{t('status-pages:BackSoon')}</Text>
        <Trans i18nKey="status-pages:MeanwhileFacebook">
          <Text>
            Meanwhile, you can visit our
            <a
              href={t('status-pages:FacebookHref')}
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            page!
          </Text>
        </Trans>
      </div>
    </Wrapper>
  )
}

export default Maintenance
