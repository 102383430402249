import {
  Headline4,
  Select,
  SeparationLine,
  themeHelpers,
  OmniButton,
  Modal,
  toast,
} from '@boxine/tonies-ui'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TonieboxIdInput from '../toniebox-id-input'
import { Text } from '../Typography'
import { MEMBER_ACCESS_RIGHTS } from '../../globals'
import { http } from '../../http.service'
import { Household } from '../../queries/tonieboxes-query'
import { zendeskLinks } from '../../zendesk'

const { media } = themeHelpers

const Headline = styled(Headline4)`
  font-size: 1.25rem;

  ${media.tabletL`
    margin-top: 0;
  `};
`

const SetupButton = styled(OmniButton)`
  margin: 1rem 0;
`

const StyledSelect = styled(Select)`
  width: 100%;

  ${media.tabletL`
    width: auto;
  `};
`

const StyledTonieboxIdInput = styled(TonieboxIdInput)`
  margin: 0 0 1rem 0;
  width: 100%;
  display: block;

  ${media.tabletL`
    width: auto;
  `};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${media.tabletL`
    justify-content: start;
  `}
`

const ImageWrapper = styled.div`
  text-align: center;
  ${media.tabletL`
    text-align: none;
  `}
`

const ImageContent = styled.div`
  ${media.tabletL`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1em;
  `};
`

const ContainedImage = styled.img`
  background-size: contain;
`
const ErrorLink = styled.a`
  color: inherit;
`

function mapHousholdOptions(households: Household[]) {
  return households
    .filter(({ access }) => access !== MEMBER_ACCESS_RIGHTS.limited)
    .map(({ id, name }) => ({ value: id, label: name }))
}

function formatTonieboxId(id: string) {
  return id.toUpperCase().replace(/[^A-Z0-9]/g, '')
}

function isHouseholdId(id: string) {
  return id.length === 36
}

interface AddTonieboxModalProps {
  isOpen: boolean
  households: Household[]
  onClose(): void
  onSuccess(): void
  trackingidPrefix: string
}

export function AddTonieboxModal({
  isOpen,
  households,
  onClose,
  onSuccess,
  trackingidPrefix,
}: AddTonieboxModalProps) {
  const { t } = useTranslation(['default', 'add-toniebox-modal'])
  const selectHouseholdOptions = useMemo(
    () => mapHousholdOptions(households),
    [households]
  )
  const {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    isValidating,
    touched,
    values,
  } = useFormik({
    initialValues: {
      tonieboxId: '',
      householdId:
        selectHouseholdOptions.length > 0
          ? selectHouseholdOptions[0].value
          : '',
    },
    // this is a big missed opportunity - we only check required, not the format itself => https://boxine.atlassian.net/browse/TOC-5500
    // validationSchema: yup.object({
    //   tonieboxId: yup.string().required(t('default:FieldMissing')),
    // }),
    async onSubmit(values, helpers) {
      try {
        const body = { id: formatTonieboxId(values.tonieboxId) }

        if (!isHouseholdId(values.householdId)) {
          const { data } = await http.post('/households')
          values.householdId = data.id
        }

        await http.post(`/households/${values.householdId}/tonieboxes`, body)

        toast(t('add-toniebox-modal:TOASTAddTonieboxSuccess'), 'success', {
          toastId: 'add-toniebox-success',
        })

        onSuccess()
      } catch (error) {
        if (!axios.isAxiosError(error) || !error.response) {
          // we only expect errors from axios here, can't handle anything else
          throw error
        }
        const { status } = error.response

        switch (status) {
          case 403:
            helpers.setFieldError(
              'tonieboxId',
              t('add-toniebox-modal:NoPermissionsToAddToniebox')
            )
            break
          case 404:
            helpers.setFieldError(
              'tonieboxId',
              t('add-toniebox-modal:TonieboxNotFound')
            )
            break
          case 409:
            const householdWithCurrentBoxId = households.find(household =>
              household.tonieboxes?.find(
                toniebox => toniebox.id === values.tonieboxId.replace(/-/g, '')
              )
            )
            if (householdWithCurrentBoxId) {
              const name = householdWithCurrentBoxId!.name

              helpers.setFieldError(
                'tonieboxId',
                // typed as setFieldError(string, string), but using the trans component
                // and wrapping it in a blocklevel element was required here for functionality
                // and layout pruposes and it works, so:
                // @ts-ignore
                <div>
                  <Trans
                    i18nKey="setup:TonieboxAlreadyInYourHousehold"
                    values={{ name }}
                    components={{
                      'support-link': (
                        <ErrorLink
                          href={
                            zendeskLinks().articleSetupTonieboxAnotherHousehold
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </div>
              )
            } else {
              helpers.setFieldError(
                'tonieboxId',
                // @ts-ignore
                <div>
                  <Trans
                    i18nKey="add-toniebox-modal:TonieboxAlreadyInAnotherHousehold"
                    components={{
                      supportLink: (
                        <ErrorLink
                          href={
                            zendeskLinks().articleSetupTonieboxAnotherHousehold
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </div>
              )
            }
            break
          default:
            helpers.setFieldError(
              'tonieboxId',
              t('add-toniebox-modal:CouldNotAddToniebox')
            )
            break
        }
      }
    },
  })

  const isSubmitDisabled = isValidating || isSubmitting || !isValid || !dirty
  const showSelectHouseholds =
    values.householdId !== '' && selectHouseholdOptions.length > 1

  return (
    <Modal
      headline={t('add-toniebox-modal:AddTonieboxModalTitle')}
      isOpen={isOpen}
      onClose={onClose}
      data-trackingid={`${trackingidPrefix}__add-toniebox-modal__close-x`}
      footer={
        <OmniButton
          data-trackingid={`${trackingidPrefix}__add-toniebox-modal__cancel`}
          onClick={onClose}
        >
          {t('default:Close')}
        </OmniButton>
      }
    >
      <ImageContent>
        <ImageWrapper>
          <ContainedImage
            src="https://cdn.tonies.de/pc/parentalcontrol/images/box_logo.svg"
            width={150}
          />
        </ImageWrapper>

        <div>
          <Headline weight={500}>
            {t('add-toniebox-modal:AddTonieboxModalHeadline1')}
          </Headline>
          <Text>{t('add-toniebox-modal:RequestToClickButton')}</Text>
          <ButtonWrapper>
            <SetupButton
              styling="primary"
              dataTestId="setup-toniebox-modal-button"
              data-trackingid={`${trackingidPrefix}__add-toniebox-modal__start-setup`}
              href={{
                pathname: '/setup-toniebox',
                state: { clearState: true },
              }}
            >
              {t('add-toniebox-modal:StartSetup')}
            </SetupButton>
          </ButtonWrapper>
        </div>
      </ImageContent>

      <SeparationLine />

      <ImageContent>
        <ImageWrapper>
          <ContainedImage
            src="https://cdn.tonies.de/pc/parentalcontrol/images/box_id_hand.svg"
            width={150}
          />
        </ImageWrapper>

        <div>
          <form onSubmit={handleSubmit}>
            <Headline weight={500}>
              {t('add-toniebox-modal:AddTonieboxModalHeadline2')}
            </Headline>
            <Text>{t('add-toniebox-modal:RequestForTonieboxID')}</Text>
            {showSelectHouseholds && (
              <StyledSelect
                dataTestId="household-id-select"
                label={t('add-toniebox-modal:SelectHouseholdLabel')}
                name="householdId"
                onChange={handleChange}
                options={selectHouseholdOptions}
                value={values.householdId}
              />
            )}

            <StyledTonieboxIdInput
              dataTestId="toniebox-id-input"
              isInvalid={touched.tonieboxId && Boolean(errors.tonieboxId)}
              label={t('add-toniebox-modal:TonieboxIDLabel')}
              message={
                touched.tonieboxId && {
                  ariaLive: 'polite',
                  message: errors.tonieboxId,
                }
              }
              name="tonieboxId"
              onChange={handleChange}
              placeholder={t('add-toniebox-modal:TonieboxIDPlaceholder')}
              value={values.tonieboxId}
            />
            <ButtonWrapper>
              <OmniButton
                onClick={handleSubmit}
                styling="primary"
                disabled={isSubmitDisabled}
                dataTestId="add-toniebox-modal-button"
                data-trackingid={`${trackingidPrefix}__add-toniebox-modal__confirm`}
              >
                {t('add-toniebox-modal:AddNow')}
              </OmniButton>
            </ButtonWrapper>
          </form>
        </div>
      </ImageContent>
    </Modal>
  )
}
