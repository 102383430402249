import React, { createContext, ReactNode, useContext, useState } from 'react'

interface Switch {
  showFooter: boolean
  showHeader: boolean
  setShowFooter: (state: boolean) => void
  setShowHeader: (state: boolean) => void
}

const HeaderFooterSwitchContext = createContext<Switch>({
  showFooter: true,
  setShowFooter: () => {},
  showHeader: true,
  setShowHeader: () => {},
})

export function useHeaderFooterSwitch() {
  return useContext(HeaderFooterSwitchContext)
}

export function HeaderFooterSwitchProvider({
  children,
}: {
  children: ReactNode
}) {
  const [showFooter, setShowFooter] = useState(true)
  const [showHeader, setShowHeader] = useState(true)
  return (
    <HeaderFooterSwitchContext.Provider
      value={{
        showFooter,
        showHeader,
        setShowFooter,
        setShowHeader,
      }}
    >
      {children}
    </HeaderFooterSwitchContext.Provider>
  )
}
