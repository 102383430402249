import React, { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import MainProviders from '../main'
import GenericErrorPage from '../../components/error-pages/GenericErrorPage'
import Maintenance from '../../components/error-pages/maintenance'
import { http } from '../../http.service'

export interface ConfigProps {
  accepts: string[]
  locales: string[]
  unicodeLocales: string[]
  maxBytes: number
  maxChapters: number
  maxSeconds: number
  paypalClientId: string
}

export const ConfigContext = React.createContext<ConfigProps>({} as ConfigProps)

export function ConfigProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<ConfigProps>({} as ConfigProps)

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await http.get<ConfigProps>(`/config`)
        setState(data)
      } catch (error) {
        handleError(error)
      }
    }
    fetch()
  }, [])

  function handleError(error: unknown) {
    // can only handle axios errors here; ignore network errors (no response)
    if (!axios.isAxiosError(error) || !error.response) {
      throw error
    }

    if (error.response.status === 503) {
      ReactDOM.render(
        <MainProviders>
          <Maintenance />
        </MainProviders>,
        document.getElementById('root')
      )
      return
    }

    // Note: This is a hack to avoid rendering the normal page layout
    ReactDOM.render(
      <MainProviders>
        <GenericErrorPage shouldDisableLink />
      </MainProviders>,
      document.getElementById('root')
    )
  }

  return (
    <ConfigContext.Provider value={state}>{children}</ConfigContext.Provider>
  )
}
