import React from 'react'
import PropTypes from 'prop-types'
import { Accent, Button, Modal } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import ModalFooter from '../ModalFooter/ModalFooter'
import TonieboxImage from '../../assets/toniebox_tonie.svg'
import { Text } from '../Typography'
import { zendeskLinks } from '../../zendesk'

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.BrandPrimary};
`

const FloatingImage = styled.img`
  float: right;
  width: 30%;
  margin: 0 0 1em 1em;
`

export const AddContentTonieModal = ({ onClose, onSubmit, isOpen }) => {
  const { t } = useTranslation(['content-tonies'])

  return (
    <Modal
      headline={t('AddContentTonieHeader')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FloatingImage src={TonieboxImage} />
      <Text>{t('PutOnContentTonie')}</Text>
      <Text>{t('TonieboxMustHaveWifi')}</Text>
      <Text>{t('ContentToniePlays')}</Text>

      <Text>
        <Trans i18nKey="content-tonies:SupportHint">
          Sollte der Tonie beharrlich ein Codewort melden, findest du
          <StyledLink
            href={zendeskLinks().articleRedLed}
            target="_blank"
            rel="noopener noreferrer"
          >
            in unserem Supportbereich
          </StyledLink>
          schnell Hilfe.
        </Trans>
      </Text>

      <ModalFooter>
        <Button
          variant="secondary"
          onClick={onClose}
          data-testid="modal-cancel"
          data-trackingid="content-tonie-overview__add-tonie-card__cancel"
        >
          {t('default:Close')}
        </Button>
        <Button
          onClick={onSubmit}
          data-testid="modal-confirm"
          data-trackingid="content-tonie-overview__add-tonie-card__confirm"
        >
          {t('AddContentTonieProceedButton')}
        </Button>
        <Accent />
      </ModalFooter>
    </Modal>
  )
}

AddContentTonieModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}
