import { useFlag } from '../providers/feature-flags'

export function useLucidDreamFlag() {
  const lucidDreamUserSegment = useFlag<string>('lucidDreamUserSegment')
  const isEnabled = useFlag<boolean>('lucidDreamEnabled')

  return {
    segment: lucidDreamUserSegment,
    isEnabled,
  }
}
