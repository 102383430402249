// notifies the user if a new deploy of the SPA is available
import { FlashMessage, OmniButton, Paragraph, Spacing } from '@boxine/tonies-ui'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MetaContext } from '../../providers/VersionCheckProvider'

const StyledFlashMessage = styled(FlashMessage)`
  position: fixed;
  top: 8rem;
  right: 3rem;
  left: 3rem;
  z-index: 10000;
`

function safeTarget(href) {
  const url = new URL(href)
  const location = window.location
  // link target must be on the same host and no be related to toniebox (wifi) setup
  return url.host === location.host && !/^\/(setup|wifi)/.test(url.pathname)
}

export function VersionUpdateNotification() {
  const { t } = useTranslation()
  const { bundledGitCommit, remoteGitCommit } = useContext(MetaContext)

  const isNewVersionAvailable =
    bundledGitCommit && remoteGitCommit && bundledGitCommit !== remoteGitCommit

  // hook into global navigation to force a refresh when safe
  useEffect(() => {
    if (!isNewVersionAvailable) {
      return
    }
    // Currently this effect will run on every navigation
    // That's due to the Layout(V2) component being a child of each route,
    // so everything underneath gets unmounted and mounted even if its exactly the
    // same, like this component and its effect
    const handler = event => {
      const link = event.target.closest('a')
      if (link && safeTarget(link.href)) {
        // prevent unnecessary client-side render
        event.stopPropagation()
        // trigger hard reload to load new version
        window.location.href = link.href
      }
    }
    document.addEventListener('click', handler, { capture: true })
    return () => {
      document.removeEventListener('click', handler, { capture: true })
    }
  }, [isNewVersionAvailable])

  if (!isNewVersionAvailable) return null

  return (
    <StyledFlashMessage type="info">
      <Paragraph size={2}>{t('default:FMNewVersionCopy')}</Paragraph>
      <Spacing my="spacing-s" media={{ m: { mx: 'spacing-s' } }}>
        <OmniButton
          onClick={() => window.location.reload(true)}
          styling="primary"
        >
          {t('default:FMNewVersionReloadButton')}
        </OmniButton>
      </Spacing>
    </StyledFlashMessage>
  )
}
