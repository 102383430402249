import PropTypes from 'prop-types'
import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../../providers/auth'

/**
 * For this routes the user MUST NOT be logged in.
 */
const AnonymousRoute = ({ component: Component, ...rest }) => {
  const { jwt } = useAuth()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const redirectPath = params.get('redirect')

  return (
    <Route
      render={props =>
        jwt ? <Redirect to={redirectPath || '/'} /> : <Component {...props} />
      }
      {...rest}
    />
  )
}

AnonymousRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export { AnonymousRoute }
