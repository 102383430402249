export default function formatAudioTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const min = Math.floor((seconds % 3600) / 60)
  const sec = Math.floor((seconds % 3600) % 60)

  return (
    (hours
      ? ('00' + hours).slice(String(hours).length >= 2 ? 0 : -2) + ':'
      : '') +
    ('00' + min).slice(-2) +
    ':' +
    ('00' + sec).slice(-2)
  )
}
