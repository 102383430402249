import { useEffect } from 'react'

// a tiny wrapper around useEffect() allowing async/await inside the callback
export function useAsyncEffect(
  effect: () => Promise<void>,
  propDependencies: any[]
) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    effect().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, propDependencies)
}
