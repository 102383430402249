import {
  Accent,
  Headline1,
  DoodleLayout,
  useLocalStorage,
  Button,
} from '@boxine/tonies-ui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'
import { CLIENT_ERROR_CODE } from '../../globals'
import { ReactComponent as RitterDrache } from '../../assets/ritterdrache.svg'
import { Head } from '../head'
import { MyToniesLogo } from '../MyToniesLogo'
import { ErrorHeadline, Text } from './styles/errorStyles'
import BackToHomepage from './BackToHomepage'

function renderErrorText(code, t) {
  switch (code) {
    case CLIENT_ERROR_CODE.chunkLoading:
      return <Text>{t('status-pages:ChunkLoadingFailed')}</Text>

    case CLIENT_ERROR_CODE.cookiesDisabled:
      return <Text>{t(`status-pages:Error602`)}</Text>

    case CLIENT_ERROR_CODE.i18nFailed:
      return <Text>{t('status-pages:I18nFailedExplanation')}</Text>

    default:
      return <Text>{t('status-pages:NoIdea')}</Text>
  }
}

export function CookiesDisabledPage({ code }) {
  const { t } = useTranslation(['status-pages'])
  return (
    <>
      <Head pageTitle={t('status-pages:5xxPageTitle')} />
      <DoodleLayout
        doodle={<RitterDrache />}
        headerLogo={<MyToniesLogo shouldDisableLink />}
      >
        <ErrorHeadline>
          <Accent>{t('status-pages:OhBoy')}</Accent>
        </ErrorHeadline>
        <Headline1 asHTMLTag="h2">
          {t('status-pages:ErrorCodeGeneric', { code })}
        </Headline1>

        {renderErrorText(code, t)}
      </DoodleLayout>
    </>
  )
}

type Params = {
  code?: string
}

type GenericErrorPageProps = {
  code?: number
  shouldIncludeHeader?: boolean
  shouldDisableLink?: boolean
}

function GenericErrorPage({
  code = 500,
  shouldIncludeHeader = false,
  shouldDisableLink = false,
}: GenericErrorPageProps) {
  const params = useParams<Params>()
  const location = useLocation()
  const { t } = useTranslation(['status-pages'])
  const [isChunkLoadingErrorReload, setIsChunkLoadingErrorReload] =
    useLocalStorage('isChunkLoadingErrorReload')
  const errorCode = params.code || code
  const prevUrl = new URLSearchParams(location.search).get('prev')
  const hasValidPrevUrl =
    prevUrl && prevUrl.startsWith('/') && !prevUrl.includes('script')

  useEffect(() => {
    // Reset token when generic error page has rendered
    if (isChunkLoadingErrorReload) {
      setIsChunkLoadingErrorReload(false)
    }

    // block to prevent infinite loop
    if (code === CLIENT_ERROR_CODE.chunkLoading && !isChunkLoadingErrorReload) {
      setIsChunkLoadingErrorReload(true)
      // Force a reload (without using the browser cache, where supported https://developer.mozilla.org/en-US/docs/Web/API/Location/reload )
      // @ts-ignore
      window.location.reload(true)
    }
  }, [isChunkLoadingErrorReload, setIsChunkLoadingErrorReload, code])

  return (
    <>
      <Head pageTitle={t('status-pages:5xxPageTitle')} />
      <DoodleLayout
        doodle={<RitterDrache />}
        headerLogo={
          shouldIncludeHeader ? (
            <MyToniesLogo shouldDisableLink={shouldDisableLink} />
          ) : null
        }
        data-testid="generic-error-page"
      >
        <ErrorHeadline>
          <Accent id="oh-boy" data-testid="error-code-500">
            {t('status-pages:OhBoy')}
          </Accent>
        </ErrorHeadline>
        <Headline1
          asHTMLTag="h2"
          id="error-code-500"
          data-testid="error-code-500"
        >
          {t('status-pages:ErrorCodeGeneric', { code: errorCode })}
        </Headline1>

        {renderErrorText(errorCode, t)}

        {hasValidPrevUrl ? (
          <Button
            as="a"
            variant="secondary"
            href={prevUrl}
            title={t('default:Back')}
          >
            {t('default:Back')}
          </Button>
        ) : (
          <BackToHomepage to="/" />
        )}
      </DoodleLayout>
    </>
  )
}

export default GenericErrorPage
