import {
  OmniButton,
  Paragraph,
  Spacing,
  theme,
  useMedia,
} from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useFlag } from '../../providers/feature-flags'
import { useShop } from '../../hooks/useShop'

const teaserCardStyles = css`
  align-content: space-between;
  border-radius: 1rem;
  display: inline-flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  min-height: 261px;
`

const ContentTeaserCard = styled.div`
  ${teaserCardStyles}
  background-color: ${props => props.theme.colors['pink-20']};
`

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 0.5rem;
`

export function ContentTonieTeaserCard() {
  const { t } = useTranslation()
  const { createLinkForTunesOverview } = useShop()

  const imageSrc = `${process.env.PUBLIC_URL}/images/tonies_tailored_content_teaser.png`
  const dataTrackingId = 'content-tonie-overview__tunes-teaser__card'
  const teaserCardText = t('content-tonies:ContentTonieTeaserCardText')

  const isLaptopWidth = useMedia({ minWidth: theme.screenTabletL })
  const isTeaserEnabled = useFlag('toniesOverviewTeaserCard')

  return (
    <>
      {isTeaserEnabled && (
        <ContentTeaserCard data-testid="content-tonie-teaser-card">
          <Image src={imageSrc} alt="" />
          <Spacing mt="spacing-m" mx="spacing-xs">
            <Paragraph size={2} weight={700} color="darkergrey" align="center">
              {teaserCardText}
            </Paragraph>
          </Spacing>
          <Spacing
            m={isLaptopWidth ? 'spacing-m' : 'spacing-s'}
            p={!isLaptopWidth ? 'spacing-xxs' : undefined}
          >
            {/* We keep the small Omnibutton here because the new Button causes layout issues on smaller mobile screens 
                TODO: raise UI-Kit topic.
            */}
            <OmniButton
              data-trackingid={dataTrackingId}
              href={createLinkForTunesOverview()}
              styling="primarySmall"
            >
              {t('creative-tonies:CreativeTonieTeaserCardButton')}
            </OmniButton>
          </Spacing>
        </ContentTeaserCard>
      )}
    </>
  )
}
