import {
  Accent,
  Headline4,
  Modal,
  Select,
  toast,
  toastify,
} from '@boxine/tonies-ui'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  hasOwnerOrFullAccess,
  mapHouseholdsToOptions,
} from '../../utils/functions/functions'
import {
  ResponsiveButton,
  ResponsiveLinkButton,
} from '../visual-elements/responsive-button'
import InviteModalContent from './InviteModalContent'

const StyledHeadline = styled(Headline4)`
  margin: 0.5rem 0;
  font-size: 1.25rem;

  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

const StyledSelect = styled(Select)`
  width: 100%;
`

function DashboardInvite({ households, mustVerify }) {
  const { t } = useTranslation(['default', 'home'])
  const [selected, setSelected] = useState('')
  const [showModal, setShowModal] = useState(false)
  const allowedHouseholds = households.filter(hasOwnerOrFullAccess)
  const selectHouseholdOptions = mapHouseholdsToOptions(allowedHouseholds)

  useEffect(() => {
    if (allowedHouseholds.length > 0 && !selected) {
      const [firstHousehold] = allowedHouseholds

      setSelected(firstHousehold.id)
    }
  }, [allowedHouseholds, selected])

  function onSelectHousehold(event) {
    event.stopPropagation()

    setSelected(event.target.value)
  }

  function dismissAndCloseModal() {
    toastify.dismiss()
    setShowModal(false)
  }

  function onSuccess() {
    setShowModal(false)
    toast(t('default:TOASTInviteMemberSuccess'), 'success', {
      toastId: 'TOASTInviteMemberSuccess',
    })
  }

  if (mustVerify) {
    return (
      <>
        <Trans i18nKey="home:InviteFamilyMembers">
          <Headline4 data-testid="dashboard-invite-headline" asHTMLTag="h2">
            Möchtest du <Accent>Mitglieder</Accent> einladen?
          </Headline4>
        </Trans>
        <ResponsiveLinkButton
          styling="primary"
          href="/households"
          data-trackingid="home__invite-members__button-press"
        >
          {t('home:ButtonLearnMoreAboutMembers')}
        </ResponsiveLinkButton>
      </>
    )
  }

  return (
    <>
      {selectHouseholdOptions.length > 0 && (
        <>
          <Trans i18nKey="home:InviteFamilyMembers">
            <StyledHeadline
              weight={500}
              data-testid="dashboard-invite-headline"
              asHTMLTag="h2"
            >
              Möchtest du <Accent>Mitglieder</Accent> einladen?
            </StyledHeadline>
          </Trans>
          <StyledSelect
            dataTestId="dashboard-household-select"
            id="puppeteer-household-select"
            label={t('home:SelectHousehold')}
            onChange={onSelectHousehold}
            options={selectHouseholdOptions}
            value={selected}
          />
          <ResponsiveButton
            styling="primary"
            size="default"
            data-trackingid="home__invite-members__button-press"
            onClick={() => {
              setShowModal(true)
            }}
          >
            {t('home:ButtonLetsGo')}
          </ResponsiveButton>
          <Modal
            headline={t('invite-modal:InviteMember')}
            isOpen={showModal}
            onClose={dismissAndCloseModal}
          >
            <InviteModalContent
              onSuccess={onSuccess}
              onClose={dismissAndCloseModal}
              householdId={selected}
            />
          </Modal>
        </>
      )}
      {selectHouseholdOptions.length === 0 && households.length > 0 && (
        <>
          <Trans i18nKey="home:CheckoutYourHouseholds">
            <StyledHeadline
              weight={500}
              data-testid="dashboard-invite-headline"
              asHTMLTag="h2"
            >
              Schau dir an, in welchen Haushalten du <Accent>Mitglied</Accent>{' '}
              bist.
            </StyledHeadline>
          </Trans>
          <ResponsiveLinkButton
            styling="primary"
            size="default"
            href="/households"
            data-trackingid="home__invite-members__button-press"
          >
            {t('home:ButtonLearnMoreAboutMembers')}
          </ResponsiveLinkButton>
        </>
      )}
      {households.length === 0 && (
        <>
          <Trans i18nKey="home:NoHouseholdsYet">
            <StyledHeadline
              weight={500}
              data-testid="dashboard-invite-headline"
              asHTMLTag="h2"
            >
              Zurzeit bist du in keinem Haushalt <Accent>Mitglied</Accent>.
            </StyledHeadline>
          </Trans>
          <ResponsiveLinkButton
            styling="primary"
            size="default"
            href="/households"
            data-trackingid="home__invite-members__button-press"
          >
            {t('home:ButtonLearnMoreAboutMembers')}
          </ResponsiveLinkButton>
        </>
      )}
    </>
  )
}

DashboardInvite.propTypes = {
  households: PropTypes.arrayOf(PropTypes.object),
  mustVerify: PropTypes.bool,
}

DashboardInvite.defaultProps = {
  households: [],
  mustVerify: false,
}

export default DashboardInvite
