import { Button } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function BackToHomepage({ to }) {
  const { t } = useTranslation(['default'])

  return (
    <Button
      as="a"
      variant="secondary"
      href={to}
      title={t('default:ToHomepage')}
    >
      <span id="to-homepage">{t('default:ToHomepage')}</span>
    </Button>
  )
}

BackToHomepage.propTypes = {
  to: PropTypes.string.isRequired,
}

export default BackToHomepage
