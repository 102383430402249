import { createI18nConfig } from '@boxine/tonies-ui'
import * as Sentry from '@sentry/browser'
import de from './translations/de.json'
import en from './translations/en.json'
import enUS from './translations/en-us.json'
import fr from './translations/fr.json'
import { getDomain, isProd } from './utils/functions/functions'

const translations = {
  de,
  en,
  'en-US': enUS,
  fr,
}

function getExpirationDate(days: number) {
  return new Date(Date.now() + days * 864e5)
}

const options = {
  detection: {
    order: ['querystring', 'cookie', 'navigator'],

    // Keys/Params to lookup language from
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'lang',
    lookupCookie: 'lang',

    // Where to cache
    caches: ['cookie'],

    cookieDomain: getDomain(),

    // optional set cookie options
    // Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
    // Implementation: https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/cookie.js
    cookieOptions: {
      path: '/',
      sameSite: 'none',
      secure: true,
      httpOnly: false,
      expires: getExpirationDate(365),
    },
  },
}

function onMissingKey(
  language: string[],
  namespace: string,
  key: string,
  fallbackValue: string
) {
  if (!isProd()) return

  Sentry.withScope(scope => {
    scope.setTag('feature', 'i18n#onMissingKey')

    scope.setExtra('language', language)
    scope.setExtra('namespace', namespace)
    scope.setExtra('key', key)
    scope.setExtra('fallbackValue', fallbackValue)

    scope.setLevel('error')
    scope.setFingerprint(['i18n-failed']) // Group under 'i18n-failed'

    Sentry.captureMessage(`I18n: onMissingKey was triggered for key ${key}`)
  })
}

function onMissingInterpolationVariable(key: string) {
  if (!isProd()) return

  Sentry.withScope(scope => {
    scope.setTag('feature', 'i18n#onMissingInterpolationVariable')

    scope.setExtra('key', key)

    scope.setLevel('error')
    scope.setFingerprint(['i18n-failed']) // Group under 'i18n-failed'

    Sentry.captureMessage(
      `I18n: onMissingInterpolationVariable was triggered for key ${key}`
    )
  })
}

const i18nConfig = createI18nConfig(
  translations,
  onMissingKey,
  onMissingInterpolationVariable,
  isProd(),
  options
)

// TODO: change to named export
export default i18nConfig
