import React, { ComponentType } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useAuth } from '../../providers/auth'
import { useUserProfile } from '../../providers/user-profile'

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType<any>
  fallbackPath?: string
  allowEdu?: boolean
}

/**
 * For this routes the user MUST be logged in.
 */
export const ProtectedRoute = ({
  component: Component,
  fallbackPath = '/login',
  allowEdu = true,
  ...rest
}: ProtectedRouteProps) => {
  const { isChecked, jwt } = useAuth()
  const { user } = useUserProfile()
  const location = useLocation()

  /**
   * To be able to display the pending state of the ProtectedRoutes,
   * we assume that if there is a LocalStorage for authentication,
   * this JWT is valid and return this as True-Value.
   * Therefore, the ProtectedRoutes can still be displayed without
   * routing to the login page.
   */
  const grantAccess = Boolean(
    jwt && (allowEdu || user === null || !user.isEduUser)
  )

  if (!isChecked) {
    return null
  }

  return (
    <Route
      render={props =>
        grantAccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={
              fallbackPath +
              '?redirect=' +
              encodeURIComponent(location.pathname + location.search)
            }
          />
        )
      }
      {...rest}
    />
  )
}
