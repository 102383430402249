import { Button, Accent, Headline4 } from '@boxine/tonies-ui'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ResponsiveLinkButton } from '../visual-elements/responsive-button'
import { Text } from '../Typography'

const StyledHeadline = styled(Headline4)`
  margin: 0.5rem 0;
  font-size: 1.25rem;

  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

const DashboardEdu = ({ mustVerify }) => {
  const { t } = useTranslation(['default', 'home'])

  if (mustVerify) {
    return (
      <>
        <Trans i18nKey="home:InviteFamilyMembers">
          <StyledHeadline
            weight={500}
            data-testid="dashboard-invite-headline"
            asHTMLTag="h2"
          >
            Möchtest du <Accent>Mitglieder</Accent> einladen?
          </StyledHeadline>
        </Trans>
        <ResponsiveLinkButton
          styling="primary"
          href="/households"
          data-trackingid="home__invite-members__button-press"
        >
          {t('home:ButtonLearnMoreAboutMembers')}
        </ResponsiveLinkButton>
      </>
    )
  }

  return (
    <>
      <Trans i18nKey="home:EduHeadline">
        <StyledHeadline weight={500} asHTMLTag="h2">
          Alles rund um das <Accent>Kita</Accent>-Pilotprojekt
        </StyledHeadline>
      </Trans>
      <Text>{t('home:EduBody')}</Text>
      <Button
        as="a"
        href="https://tonies.de/kita-portal"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t('home:ToEduPlatform')}
      </Button>
    </>
  )
}

DashboardEdu.propTypes = {
  households: PropTypes.array,
  mustVerify: PropTypes.bool,
}

DashboardEdu.defaultProps = {
  households: [],
  mustVerify: false,
}

export { DashboardEdu }
