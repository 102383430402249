import { Headline1, Paragraph, media } from '@boxine/tonies-ui'
import styled from 'styled-components'

export const ErrorHeadline = styled(Headline1)`
  font-size: 1.5rem;

  ${media.laptop`
    font-size: 3.5rem;
  `}
`

export const Text = styled(Paragraph)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0.75rem 0;
`
