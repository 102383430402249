import { createClientWithAutoPoll } from 'configcat-js'
import { FeatureFlagAdapter, FeatureFlagUser, Flags } from '../types'
import { newEmitter } from '../utils'

async function loadAllFlags(
  client: ReturnType<typeof createClientWithAutoPoll>,
  user: FeatureFlagUser
) {
  const keyValues = await client.getAllValuesAsync(user)
  return keyValues.reduce((acc, item) => {
    acc[item.settingKey] = item.settingValue
    return acc
  }, {})
}

/**
 * Create an adapter for ConfigCat
 */
export function newConfigCatAdapter(
  apiKey: string,
  initialUser: FeatureFlagUser = { identifier: 'anonymous' }
): FeatureFlagAdapter {
  const emitter = newEmitter()
  let flags: Flags = {}
  let user = initialUser

  const state = { isReady: false }
  const client = createClientWithAutoPoll(apiKey, {
    // poll every 5 minutes; we can decrease this if requests/s are low enough
    // set to something low like 10 for local testing
    pollIntervalSeconds: 300,
    // this is only triggered when the config changed,
    // not on every poll. So we can use it to update flags
    configChanged: () => {
      loadAllFlags(client, user).then(nextFlags => {
        flags = nextFlags
        emitter.trigger('change', flags)
      })
    },
  })

  // Fire init request in the background
  loadAllFlags(client, initialUser).then(nextFlags => {
    flags = nextFlags
    state.isReady = true
    emitter.trigger('ready', null)
  })

  return {
    get isReady() {
      return state.isReady
    },
    allFlags() {
      return flags
    },
    ...emitter,
    setUser(newUser) {
      user = newUser
      return loadAllFlags(client, user).then(nextFlags => {
        flags = nextFlags
        emitter.trigger('change', flags)
      })
    },
  }
}
