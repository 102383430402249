/* eslint-disable react/display-name */
import * as v from '@badrap/valita'
import {
  CoverCardPlaceholder,
  Headline2,
  media,
  Paragraph,
  Spacing,
  toast,
} from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { BLOCKS, MARKS, Document } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { contentfulDocument } from '../../contentful/checkContentfulData'
import { ContentfulHeadline } from '../../contentful/ContentfulRichText'
import { InnerContainer } from '../../layouts/LayoutV2'
import { getPersistentParams } from '../../utils/functions/buildAudioLibraryUrl'
import { Skeleton } from '../Skeleton'
import { TuneCard } from '../TuneCard'
import { FreeContentSectionQuery, query } from './FreeContentSectionQuery'

const Wrapper = styled.div`
  background: white;
  padding-top: 3rem;
  overflow: hidden;
  padding-bottom: 2rem;

  ${media.tabletL`
    padding-top: 5rem;
    padding-bottom: 3rem;
  `}
`

const TunesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
  padding-top: 2rem;

  ${media.mobileL`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}

  ${media.tabletL`
    grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));;
  `}
`

const SectionHeadline = styled(Headline2)<{ mobileCenter?: boolean }>`
  line-height: normal;
  margin-bottom: 0.5rem;
  text-align: ${props => (props.mobileCenter ? 'center' : 'left')};

  ${media.tabletL`
    text-align: left;
  `}
`

const SectionText = styled(Paragraph)`
  font-size: 1rem;
  font-weight: lighter;
  line-height: 1.4;
  text-align: center;
  color: inherit;

  ${media.tabletL`
    font-size: 1.25rem;
    line-height: normal;
    text-align: left;
  `}
`
const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
function LoadingPlaceholderGrid() {
  return (
    <TunesGrid>
      {Array(52)
        .fill(null)
        .map((_, index) => {
          return (
            <PlaceholderWrapper key={index}>
              <CoverCardPlaceholder />
              <Skeleton height={20} width="80%" />
              <Skeleton height={18} width="60%" />
            </PlaceholderWrapper>
          )
        })}
    </TunesGrid>
  )
}

export function FreeContentSectionSkeleton() {
  return (
    <Wrapper>
      <InnerContainer>
        <Spacing mb="spacing-l">
          <Skeleton height={32} width={240} />
        </Spacing>
        <Spacing mb="spacing-s">
          <Skeleton height={20} width="80%" />
        </Spacing>
        <LoadingPlaceholderGrid />
      </InnerContainer>
    </Wrapper>
  )
}

export const freeContentSectionSchema = v.object({
  headline: contentfulDocument.optional(),
  copy: contentfulDocument.optional(),
  selection: v.string(),
  redirectUrl: v.string().optional(),
  redirectMessage: v.string().optional(),
})

interface FreeContentSectionProps {
  contentful: v.Infer<typeof freeContentSectionSchema>
  returnUrl: string
}

export function FreeContentSection({
  contentful,
  returnUrl,
}: FreeContentSectionProps) {
  const location = useLocation()
  const persistent = getPersistentParams(location.search)
  const { t } = useTranslation()

  const [{ data, fetching, error }] = useQuery<FreeContentSectionQuery>({
    query,
    variables: { selection: contentful.selection },
  })

  // We have an outer error boundary which shows a nice error page
  if (error) {
    throw error
  }

  if (
    data?.contentTokens.edges.length === 0 &&
    data?.allContentTokens.totalCount === 0 &&
    contentful.redirectUrl
  ) {
    toast(t('default:InvalidOriginHeadingFreeContentToast'), 'success', {
      toastId: 'ard-landing-page-redirect-region-error',
    })
    return <Redirect to={contentful.redirectUrl} />
  }

  if (contentful.redirectUrl && data?.contentTokens.edges.length === 0) {
    if (contentful.redirectMessage) {
      toast(contentful.redirectMessage, 'success', {
        toastId: 'landing-page-redirect-message',
      })
    }
    return <Redirect to={contentful.redirectUrl} />
  }

  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <strong>{children}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <SectionText>{children}</SectionText>
      ),
    },
  }

  return (
    <Wrapper id="content">
      <InnerContainer>
        {contentful.headline && (
          <SectionHeadline asHTMLTag="h2" mobileCenter>
            <ContentfulHeadline
              content={contentful.headline}
              headlineTag="span"
            />
          </SectionHeadline>
        )}

        {contentful.copy && (
          <Spacing mb="spacing-xs" mt="spacing-s">
            {documentToReactComponents(
              contentful.copy as Document,
              renderOptions
            )}
          </Spacing>
        )}
        <TunesGrid>
          {fetching && <LoadingPlaceholderGrid />}
          {!fetching &&
            data?.contentTokens.edges.map(item => {
              const audioCandy = item.node
              const relatedParams = new URLSearchParams(
                persistent.params.toString()
              )

              relatedParams.append('relatedContent', contentful.selection)
              relatedParams.append('relatedUrl', returnUrl)

              return (
                <TuneCard
                  key={audioCandy.id}
                  tune={audioCandy}
                  data-trackingid={`landingpage__stream-${contentful.selection}__candy-cover-click-${audioCandy.token}`}
                  to={`/audio-candy/${audioCandy.token}?${relatedParams}`}
                />
              )
            })}
        </TunesGrid>
      </InnerContainer>
    </Wrapper>
  )
}
