// Include any polyfills we might want to ship in this file

/**
 * Fall back to standard scroll if browser doesn't support the options
 * argument TOC-3623
 */
if (!('scrollBehavior' in document.documentElement.style)) {
  ;(function () {
    const fn = window.scrollTo
    const hasScrollOptions = 'scrollBehavior' in document.documentElement.style

    window.scrollTo = (optionsOrX, y) => {
      if (typeof optionsOrX === 'object') {
        if (hasScrollOptions) {
          fn(optionsOrX)
        } else {
          fn(optionsOrX.top || 0, optionsOrX.left || 0)
        }
      } else {
        fn(optionsOrX, y)
      }
    }
  })()
}

// Edge <= 18 doesn't support `Element.prototype.scrollBy` TOC-3626
if (!Element.prototype.scrollBy) {
  ;(function () {
    Element.prototype.scrollBy = function (optionsOrX, y) {
      if (typeof optionsOrX === 'object') {
        this.scrollTop += optionsOrX.top || 0
        this.scrollLeft += optionsOrX.left || 0
      } else {
        this.scrollTop += optionsOrX
        this.scrollLeft += y
      }
    }
  })()
}

// The use-media dependency relies on Object.entries, and we use that
// on our outdated browser page
if (!Object.entries) {
  // Polyfill taken from https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj)
    let i = ownProps.length
    const resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}
