import React from 'react'
import { Link } from 'react-router-dom'
import {
  Accent,
  Box,
  Headline4,
  theme,
  useMedia,
  Button,
} from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Spacer } from '../../components/Spacer'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'
import { useShop } from '../../hooks/useShop'

export default function TonieSongBox() {
  const { t, i18n } = useTranslation(['default', 'home'])
  const { canBuyTunes } = useIsAudioLibraryAvailable()
  const isLaptopWidth = useMedia({ minWidth: theme.screenLaptop })
  const songUrl = t('default:AudioCandyTonieSong')
  const howtoUrl = t('default:AudioCandyHowATonieIsMade')
  const contentToken = url => {
    return url.replace('/audio-candy/', '').replace('/assign', '')
  }
  const { createLinkForTunesOverview } = useShop()

  /** For regions that don't sell tunes (!canBuyTunes) and German language settings,
   *  we teaser the "how a tonie is made" content, otherwise the "Toniesong"
   */
  if (!canBuyTunes && i18n.language === 'de') {
    return (
      <Box
        backgroundColor={theme.colors.white}
        p="spacing-s"
        borderRadius={['xs']}
        mx={!isLaptopWidth ? 'spacing-s' : undefined}
      >
        <Headline4 weight={500} asHTMLTag="h2">
          {t('home:TonieHowtoTeaserHeader')}
          <br />
          {t('home:TonieHowtoTeaserSubheaderCandy')}
        </Headline4>
        <Spacer marginTop="1rem">
          <Button
            as={Link}
            variant="primary"
            to={howtoUrl}
            data-trackingid={`home__free-content-assignment__assignment-click-${contentToken(
              howtoUrl
            )}`}
          >
            {t('home:ButtonAssign')}
          </Button>
        </Spacer>
      </Box>
    )
  }

  /** For regions that don't sell tunes (!canBuyTunes) and other than German language settings,
   *  we teaser the the "Toniesong"
   */
  if (!canBuyTunes && i18n.language !== 'de') {
    return (
      <Box
        backgroundColor={theme.colors.white}
        p="spacing-s"
        borderRadius={['xs']}
        mx={!isLaptopWidth ? 'spacing-s' : undefined}
      >
        <Headline4 weight={500} asHTMLTag="h2">
          {t('home:TonieSongTeaserHeader')}
          <br />
          <Trans
            i18nKey="home:TonieSongTeaserSubheaderCandy"
            components={{ 1: <Accent /> }}
          />
        </Headline4>
        <Spacer marginTop="1rem">
          <Button
            as={Link}
            variant="primary"
            to={songUrl}
            data-trackingid={`home__free-content-assignment__assignment-click-${contentToken(
              songUrl
            )}`}
          >
            {t('home:ButtonAssign')}
          </Button>
        </Spacer>
      </Box>
    )
  }

  /** For regions that sell tunes we link customers to https://tonies.com/audio-content
   *  to increase selling of tunes there.
   */
  return (
    <Box
      backgroundColor={theme.colors.white}
      p="spacing-s"
      borderRadius={['xs']}
      mx={!isLaptopWidth ? 'spacing-s' : undefined}
    >
      <Headline4 weight={500} asHTMLTag="h2">
        {t('home:TonieShopAudioContentTeaserHeader')}
        <br />
        <Trans
          i18nKey="home:TonieShopAudioContentTeaserSubheader"
          components={{ em: <Accent /> }}
        />
      </Headline4>
      <Spacer marginTop="1rem">
        <Button
          variant="primary"
          as="a"
          href={createLinkForTunesOverview()}
          data-trackingid="home__audio-content-tonies.com-shop-link__link-click"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('home:TonieShopAudioContentTeaserButtonLabel')}
        </Button>
      </Spacer>
    </Box>
  )
}
