import React, { useEffect, useState } from 'react'
import {
  Paragraph,
  Radio,
  SeparationLine,
  Avatar,
  themeHelpers,
  Box,
  theme,
} from '@boxine/tonies-ui'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isFull, isLimited } from '../../utils/functions/functions'

const { media } = themeHelpers

const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0.5rem;
`

const MetaData = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  ${media.tabletL`
     grid-template-columns: 1fr 1fr 1fr;
  `};
`

const StyledTextLabel = styled.strong`
  font-size: 0.85rem;
  margin-bottom: 0.4rem;
  font-family: ${props => props.theme.fonts.serif};
`

const AccessData = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${media.tabletL`
     grid-template-columns: 1fr 2fr;
  `};
`

const CenteredRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1em;

  .avatar-image {
    margin-right: 1em;
  }
`

const AccessText = styled(Paragraph)`
  font-size: 0.8125rem;
  font-weight: normal;
  margin: 0 0 0.75rem;
`

const AccessRadio = styled(Radio)`
  margin-bottom: 8px;
`

const Line = styled(SeparationLine)`
  margin: 0 1em 1em 1em;
  width: auto;
`

interface DetailsBoxProps {
  model: any
  onChangeAccess: (event: any) => void
}

const DetailsBox = ({ model, onChangeAccess }: DetailsBoxProps) => {
  const [membership, setMembership] = useState(model)
  const { t } = useTranslation(['default'])

  useEffect(() => {
    setMembership(model)
  }, [model])

  return (
    <Box
      backgroundColor={theme.colors.white}
      p="spacing-s"
      borderRadius={['xs']}
    >
      <div>
        <CenteredRow>
          <Avatar
            className="avatar-image"
            src={process.env.PUBLIC_URL + '/images/elefant.svg'}
            width={54}
            height={54}
            type="circle"
          />
          {membership.displayName || membership.email}
        </CenteredRow>
      </div>
      <MetaData>
        {membership.firstName && (
          <div>
            <StyledTextLabel>{t('default:FirstName')}</StyledTextLabel>
            <div>{membership.firstName}</div>
          </div>
        )}
        {membership.lastName && (
          <div>
            <StyledTextLabel>{t('default:LastName')}</StyledTextLabel>
            <div>{membership.lastName}</div>
          </div>
        )}
        <div>
          <StyledTextLabel>{t('default:EMailAddress')}</StyledTextLabel>
          <div>{membership.email}</div>
        </div>
      </MetaData>
      <Line />
      <AccessData>
        <div>
          <StyledTextLabel>{t('default:State')}</StyledTextLabel>
          <div>{t('default:Active')}</div>
        </div>
        <div>
          <AccessText>{t('default:AccessLabel')}</AccessText>
          <RadioContainer>
            <AccessRadio
              name="access"
              value="full-access"
              checked={isFull(membership)}
              onChange={onChangeAccess}
            >
              {t('default:FullAccess')}
            </AccessRadio>
            <AccessRadio
              name="access"
              value="limited-access"
              checked={isLimited(membership)}
              onChange={onChangeAccess}
            >
              {t('default:LimitedAccess')}
            </AccessRadio>
          </RadioContainer>
        </div>
      </AccessData>
    </Box>
  )
}

export default DetailsBox
