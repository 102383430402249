// should also use gql tag, but prettier doesn't grok the custom variable interpolation
export default (householdId, invitationId) => {
  return `
  {
    households(id: "${householdId}") {
      invitations(id: "${invitationId}") {
        id
        email
        itype
      }
    }
  }
  `
}
