import React, { useState, useEffect } from 'react'
import { Redirect, useLocation, useParams } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import { Headline1, theme, themeHelpers } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Head } from '../../components/head'
import { http } from '../../http.service'
import { LayoutV1 } from '../../layouts/LayoutV1'
import { Text } from '../../components/Typography'
import { zendeskLinks } from '../../zendesk'
import ConfusedImage from './images/confused.svg'

const { media } = themeHelpers

const NoInvitationWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`

const StyledAnchor = styled.a`
  color: ${theme.BrandPrimary};
  text-decoration: none;
`

const StyledImage = styled.img`
  display: none;
  min-width: 200px;

  ${media.tabletL`
     display: block;
  `};
`

enum Transition {
  pending = 'pending',
  success = 'success',
  not_found = 'not_found',
  forbidden = 'forbidden',
}

export default function AcceptInvitation() {
  const { t } = useTranslation()
  const location = useLocation()
  const { token } = useParams<{ token: string }>()
  const [transition, setTransition] = useState<Transition>(Transition.pending)

  useEffect(() => {
    http
      .post(`/invitations/${token}/accept`)
      .then(() => setTransition(Transition.success))
      .catch(err => {
        if (err.response) {
          if (err.response.status === 403) {
            setTransition(Transition.forbidden)
            return
          } else if (err.response.status === 404) {
            setTransition(Transition.not_found)
            return
          }
        }

        throw err
      })
  }, [token, location])

  return (
    <LayoutV1>
      {transition === Transition.success && (
        <Redirect to="/?invitation_success" />
      )}
      {transition === Transition.forbidden && (
        <Redirect
          to={`/login?${encodeURIComponent(
            location.pathname + location.search
          )}`}
        />
      )}
      {transition === Transition.not_found && (
        <>
          <Head pageTitle={t('accept-invitation:PageTitle')} />

          <Headline1 asHTMLTag="h2">
            {t('accept-invitation:PageTitle')}
          </Headline1>

          <NoInvitationWrapper>
            <div>
              <Text>{t('accept-invitation:InvitationNotFound')}</Text>

              <Text>
                <Trans i18nKey="accept-invitation:CheckIfAlreadyJoined">
                  Logge dich am besten in dein{' '}
                  <StyledAnchor href="/households">Tonie-Konto</StyledAnchor>{' '}
                  ein und schaue direkt nach.
                </Trans>
              </Text>

              <Text>{t('accept-invitation:AskForResendingInvitation')}</Text>

              <Text>
                <Trans i18nKey="accept-invitation:ContactSupport">
                  Wenn keine der Optionen das Problem löst
                  <StyledAnchor href={zendeskLinks().newTicket}>
                    kontaktiere unser Support Team
                  </StyledAnchor>
                  .
                </Trans>
              </Text>
            </div>
            <StyledImage alt="" src={ConfusedImage} />
          </NoInvitationWrapper>
        </>
      )}
    </LayoutV1>
  )
}
