import React from 'react'
import { Accent, Headline1, Spacing, DoodleLayout } from '@boxine/tonies-ui'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Question } from '../../assets/tonies-question.svg'
import { Head } from '../head'
import BackToHompage from './BackToHomepage'
import { ErrorHeadline, Text } from './styles/errorStyles'

export default function NotFound() {
  const { t } = useTranslation(['status-pages'])

  return (
    <>
      <Head pageTitle={t('status-pages:404PageTitle')} />
      <DoodleLayout doodle={<Question />}>
        <Spacing my="spacing-s">
          <ErrorHeadline>
            <Accent>{t('status-pages:Oops')}</Accent>
          </ErrorHeadline>
        </Spacing>
        <Headline1
          asHTMLTag="h2"
          id="error-code-404"
          data-testid="error-code-404"
        >
          {t('status-pages:ErrorCode404')}
        </Headline1>
        <Text id="page-not-found" data-testid="page-not-found">
          {t('status-pages:PageNotFound')}
        </Text>
        <BackToHompage to="/" />
      </DoodleLayout>
    </>
  )
}
