import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'

export const ColoredSection = styled.div`
  background: ${props => props.theme.GrayLightest};
  padding: 2rem 0;
  position: relative;

  ${media.laptop`
    margin-top: 1.25rem;
    padding: 3.5rem 0;
  `}
`
