import React from 'react'

interface HeadProps {
  pageTitle: React.ReactChild
  description?: string
  htmlLang?: string
  language?: string
}
/**
 * Helper to update meta tags in the head of the
 * document.
 */
function updateTag(
  attributeType: string,
  currentElement: Element | null,
  newElement: Element | void | null,
  attribute?: string
) {
  if (!attribute || (!currentElement && !newElement)) {
    return
  }
  let element: Element | null = null
  if (currentElement) {
    element = currentElement
  }
  if (!currentElement && newElement) {
    element = newElement
  }
  if (!element) {
    return
  }
  return element.setAttribute(attributeType, attribute)
}

export function Head({ pageTitle, description, htmlLang }: HeadProps) {
  // Detect currently available meta/title tags
  const currentHtmlLang = document.documentElement
  const currentTitle = document.head.querySelector('title')
  const currentDescription = document.head.querySelector(
    'meta[name=description]'
  )

  // Create tags that are currently not available if attributes are set
  if (!currentTitle && pageTitle) {
    document.createElement('title')
  }
  if (description && !currentDescription) {
    document.createElement('meta').setAttribute('name', 'description')
  }

  // Select existing or currently created elements for update
  const newTitle = pageTitle && document.createElement('title')
  const newDescription = document.head.querySelector('meta[name=description]')

  // Update elements
  if (currentHtmlLang && htmlLang) {
    document.documentElement.removeAttribute('lang')
  }
  const updatedLanguage =
    htmlLang && document.documentElement.setAttribute('lang', htmlLang)

  if (currentTitle && newTitle && pageTitle) {
    currentTitle.replaceWith(newTitle)
  }
  const titleContent =
    pageTitle && document.createTextNode(`tonies® - ${pageTitle}`)
  const updatedTitle =
    titleContent && document.head.querySelector('title')?.append(titleContent)

  const updatedDescription = updateTag(
    'content',
    currentDescription,
    newDescription || null,
    description
  )

  return (
    <>
      {updatedLanguage}
      {updatedTitle}
      {updatedDescription}
    </>
  )
}
