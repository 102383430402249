import React, { useEffect, useRef, useState } from 'react'
import {
  AvatarHeaderBar,
  ButtonLink,
  FocusButton,
  FocusStyles,
  LoginLink,
  MainNavigation,
  MobileOnlyNavigation,
  MobileOnlyNavigationItem,
  PrimaryNavigation,
  PrimaryNavigationItem,
  SecondaryNavigation,
  SecondaryNavigationItem,
  media,
  useAnimateGreeting,
  useIsMobileNavigation,
  useLocalStorage,
} from '@boxine/tonies-ui'
import tonieAvatarPath from '@boxine/tonies-ui/assets/svgs/tonieAvatar-c3.svg'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { MyToniesLogo } from '../MyToniesLogo'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'
import { useShop } from '../../hooks/useShop'
import { MetaNavHeader } from './MetaNav'

interface MyToniesHeaderProps {
  idLanguageSwitch?: string
  isEduUser: boolean
  isLanguageSwitchOpen: boolean
  isLoggedIn: boolean
  name: string
  onLogin: () => void
  onLogout: () => void
  onToggleLanguageSwitch: (wasTriggeredByKeyboard: boolean) => void
}

type StickyHeaderProps = {
  heightMetaNavInRem: number
  isMobile: boolean
  isRefreshOrResetScreen: boolean
}

const StickyHeader = styled.header<StickyHeaderProps>`
  top: -${props => (!props.isMobile ? props.heightMetaNavInRem : 0)}rem;
  z-index: 1000;

  position: sticky;
  background-color: ${props =>
    props.isRefreshOrResetScreen
      ? props.theme.colors['lightblue-20']
      : 'transparent'};
`
type ShiftedMainNavigationProps = {
  heightMetaNavInRem: number
}

const ShiftedMainNavigation = styled(
  MainNavigation
)<ShiftedMainNavigationProps>`
  top: ${props => props.heightMetaNavInRem}rem;
`

const ShopLink = styled.a`
  color: ${props => props.theme.colors['primary']};
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-left: 1.5rem;

  ${FocusStyles}

  ${media.laptop`
    font-size: 1.25rem;
    line-height: normal;
  `}

  &:hover {
    color: ${props => props.theme.colors['primary-hover']};
  }
`

const MobileShopLink = styled.a`
  color: ${props => props.theme.colors['darkergrey']};
  text-decoration: none;
  font-size: 1.125rem;
  line-height: 1.33rem;
  font-weight: 700;

  ${FocusStyles}

  &:hover {
    color: ${props => props.theme.colors['primary-hover']};
  }
`

const StyledFocusLoginOrOutButton = styled(FocusButton)`
  color: ${props => props.theme.colors.primary};
`

function MyToniesHeader({
  idLanguageSwitch,
  isEduUser,
  isLanguageSwitchOpen,
  isLoggedIn,
  name,
  onLogin,
  onLogout,
  onToggleLanguageSwitch,
}: MyToniesHeaderProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { t } = useTranslation('default')
  const isMobile = useIsMobileNavigation()
  const { isAudioLibraryAvailable } = useIsAudioLibraryAvailable()
  const [shouldShowGreetingBubble, setShouldShowGreetingBubble] =
    useLocalStorage('shouldShowGreetingBubble', true)
  const location = useLocation()
  const { shopLink } = useShop()

  const isLogoOnlyMode = useRouteMatch([
    '/setup-toniebox',
    '/wifi',
    '/legals/:page',
  ])
  const isRefreshOrResetScreen =
    location.pathname.includes('refresh') || location.pathname.includes('reset')

  const metaNavigationRef = useRef<HTMLDivElement>(null)
  const [heightMetaNavInRem, setHeightMetaNavInRem] = useState(0)

  useEffect(() => {
    if (metaNavigationRef.current) {
      setHeightMetaNavInRem(metaNavigationRef.current.scrollHeight / 16)
    }
  }, [metaNavigationRef])

  const { hideGreeting, isGreetingShown, showGreeting } =
    useAnimateGreeting(isMobile)

  useEffect(() => {
    if (isLoggedIn) {
      if (shouldShowGreetingBubble) {
        showGreeting(() => setShouldShowGreetingBubble(false))
      }
    } else {
      hideGreeting()
    }
  }, [
    hideGreeting,
    isLoggedIn,
    showGreeting,
    shouldShowGreetingBubble,
    setShouldShowGreetingBubble,
  ])

  if (isLogoOnlyMode) {
    return (
      <StickyHeader
        isMobile={isMobile}
        heightMetaNavInRem={heightMetaNavInRem}
        isRefreshOrResetScreen={isRefreshOrResetScreen}
      >
        <ShiftedMainNavigation
          hasHamburgerMenu={false}
          heightMetaNavInRem={0}
          isLogoAnimated={false}
          logo={<MyToniesLogo />}
        />
      </StickyHeader>
    )
  }

  return (
    <StickyHeader
      isMobile={isMobile}
      heightMetaNavInRem={heightMetaNavInRem}
      isRefreshOrResetScreen={isRefreshOrResetScreen}
    >
      <MetaNavHeader
        idLanguageSwitch={idLanguageSwitch}
        isLanguageSwitchOpen={isLanguageSwitchOpen}
        onToggleLanguageSwitch={onToggleLanguageSwitch}
        ref={metaNavigationRef}
      />
      <ShiftedMainNavigation
        hamburgerMenuLoginOut={
          isLoggedIn ? (
            <StyledFocusLoginOrOutButton onClick={onLogout} isMobile>
              {t('Logout')}
            </StyledFocusLoginOrOutButton>
          ) : (
            <StyledFocusLoginOrOutButton onClick={onLogin} isMobile>
              {t('home:Login')}
            </StyledFocusLoginOrOutButton>
          )
        }
        mobileTrackingId="navigation__menu__click-mobile-menu"
        hasHamburgerMenu={isLoggedIn && isMobile}
        heightMetaNavInRem={heightMetaNavInRem}
        isLoggedIn={isLoggedIn}
        isLogoAnimated
        isMobileMenuOpen={isMobileMenuOpen}
        logo={<MyToniesLogo />}
        onChangeIsMobileMenuOpen={setIsMobileMenuOpen}
        primary={
          isLoggedIn && (
            <PrimaryNavigation>
              {isAudioLibraryAvailable && (
                <PrimaryNavigationItem to="/audio-library">
                  {t('AudioLibrary')}
                </PrimaryNavigationItem>
              )}
              <PrimaryNavigationItem to="/content-tonies">
                {t('ContentTonies')}
              </PrimaryNavigationItem>
              <PrimaryNavigationItem to="/creative-tonies">
                {t('CreativeTonies')}
              </PrimaryNavigationItem>
              <PrimaryNavigationItem to="/tonieboxes">
                {t('TonieBoxes')}
              </PrimaryNavigationItem>
              {!isEduUser && (
                <PrimaryNavigationItem to="/households">
                  {t('Members')}
                </PrimaryNavigationItem>
              )}
            </PrimaryNavigation>
          )
        }
        secondary={
          <SecondaryNavigation>
            {isLoggedIn ? (
              <>
                {isMobile ? (
                  <SecondaryNavigationItem>
                    <MobileShopLink
                      data-trackingid="navigation__shop_link_click"
                      href={shopLink}
                    >
                      {t('ToniesOnlineShop')}
                    </MobileShopLink>
                  </SecondaryNavigationItem>
                ) : (
                  <>
                    <SecondaryNavigationItem>
                      <ShopLink
                        data-trackingid="navigation__shop_link_click"
                        href={shopLink}
                      >
                        {t('Shop')}
                      </ShopLink>
                    </SecondaryNavigationItem>
                    <SecondaryNavigationItem>
                      <AvatarHeaderBar
                        alt={t('YourProfile')}
                        showName={!isMobile && isGreetingShown && name}
                        src={tonieAvatarPath}
                        to="/accounts/details"
                        data-trackingid="navigation__profile__click-avatar"
                      />
                    </SecondaryNavigationItem>
                  </>
                )}
              </>
            ) : (
              <>
                <SecondaryNavigationItem>
                  <ShopLink
                    data-trackingid="navigation__shop_link_click"
                    href={shopLink}
                  >
                    {t('Shop')}
                  </ShopLink>
                </SecondaryNavigationItem>
                <SecondaryNavigationItem>
                  <ButtonLink onClick={onLogin}>
                    <LoginLink showLabel={!isMobile} />
                  </ButtonLink>
                </SecondaryNavigationItem>
              </>
            )}
          </SecondaryNavigation>
        }
        mobile={
          <MobileOnlyNavigation>
            {isLoggedIn ? (
              <>
                <MobileOnlyNavigationItem>
                  <AvatarHeaderBar
                    alt={t('YourProfile')}
                    showName={false}
                    src={tonieAvatarPath}
                    to="/accounts/details"
                    data-trackingid="navigation__profile__click-avatar"
                  />
                </MobileOnlyNavigationItem>

                <MobileOnlyNavigationItem>
                  <ShopLink
                    data-trackingid="navigation__shop_link_click"
                    href={shopLink}
                  >
                    {t('Shop')}
                  </ShopLink>
                </MobileOnlyNavigationItem>
              </>
            ) : (
              <>
                <MobileOnlyNavigationItem>
                  <ShopLink
                    data-trackingid="navigation__shop_link_click"
                    href={shopLink}
                  >
                    {t('Shop')}
                  </ShopLink>
                </MobileOnlyNavigationItem>
                <MobileOnlyNavigationItem>
                  <ButtonLink onClick={onLogin}>
                    <LoginLink showLabel={!isMobile} />
                  </ButtonLink>
                </MobileOnlyNavigationItem>
              </>
            )}
          </MobileOnlyNavigation>
        }
      />
    </StickyHeader>
  )
}

export default MyToniesHeader
