import { Button, Box, Spacing, theme } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { zendeskLinks } from '../../zendesk'
import { Text } from '../Typography'

const StyledImage = styled.img`
  display: block;
  margin: 1rem auto;
  width: 50%;
`

export default function BoxContactSupport() {
  const { t } = useTranslation()

  return (
    <Box
      backgroundColor={theme.colors.white}
      p="spacing-s"
      mb="spacing-m"
      borderRadius={['xs']}
    >
      <StyledImage
        src={process.env.PUBLIC_URL + '/images/support.svg'}
        alt={t('default:Support')}
      />
      <Text>{t('default:AnyQuestions')}</Text>
      <Spacing mt="spacing-s">
        <Button
          as="a"
          href={zendeskLinks().home}
          target="_blank"
          backgroundColor="yellow-100"
          rel="noopener noreferrer"
        >
          {t('default:ToSupport')}
        </Button>
      </Spacing>
    </Box>
  )
}
