import styled from 'styled-components'
import { Paragraph, Accent } from '@boxine/tonies-ui'

// needed to stay aligned with finished and QA'd layouts
export const Text = styled(Paragraph)`
  font-size: 1rem;
  font-weight: normal;
  margin: 0.75rem 0;
`

// Accent text color needs to be set manually outside of Headline1 and Headline2
// This also violates the Styleguide: Accent is only allowed in Headline1 and Headline2
// See: https://tonies.frontify.com/document/277501#/typography/webfont-styles/
// TODO: Remove this component when legacy views are removed
export const StyledAccent = styled(Accent)`
  color: ${props => props.theme.BrandPrimary};
`
