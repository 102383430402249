import './hijackEffects'
import React from 'react'
import ReactDOM from 'react-dom'
import { polyfill } from 'seamless-scroll-polyfill'
import { CookiesDisabledPage } from './components/error-pages/GenericErrorPage'
import Maintenance from './components/error-pages/maintenance'
import { addResponseInterceptor, http } from './http.service'
import i18nConfig from './i18n'
import './polyfills'
import MainProviders from './providers/main'
import Shell from './Shell'
import { initSentry } from './utils/sentry'

initSentry()

// kicks off polyfill for smooth scrolling in browsers like
// safari and windows which do not support it natively
// and refuse CSS properties
polyfill()

function hasLocalStorageSupport() {
  const test = 'test'

  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const redirectErrorStatus = status => {
  if (status === 503) {
    // Note: This is a hack to avoid rendering the normal page layout
    ReactDOM.render(
      <MainProviders>
        <Maintenance />
      </MainProviders>,
      document.getElementById('root')
    )
  }
}

// Register interceptors here in order to avoid circular dependencies
// As more interceptors are used this code should be refactored
;(async () => {
  await addResponseInterceptor(
    response => response,
    error => {
      const status = error.response?.status

      if (i18nConfig.isInitialized) {
        redirectErrorStatus(status)
      } else {
        // TODO first check for status 503, then register the callback when its really needed
        i18nConfig.on('initialized', () => {
          redirectErrorStatus(status)
        })
      }
      return Promise.reject(error)
    }
  )

  if (!hasLocalStorageSupport()) {
    // Note: This is a hack to avoid rendering the normal page layout
    ReactDOM.render(
      <MainProviders>
        <CookiesDisabledPage code={602} />
      </MainProviders>,
      document.getElementById('root')
    )
    return
  }

  if (hasLocalStorageSupport()) {
    let jwt

    try {
      jwt = localStorage.getItem('authorization')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }

    if (jwt) {
      const headers = { Authorization: `Bearer ${jwt}` }
      Object.assign(http.defaults, { headers })
    }
  }

  // Monkey patch XHR Object to use it when analyzing network errors
  const xhrProto = XMLHttpRequest.prototype,
    origOpen = xhrProto.open

  xhrProto.open = function (method, url) {
    this.originalRequestURL = url
    return origOpen.apply(this, arguments)
  }

  ReactDOM.render(
    <MainProviders>
      <Shell />
    </MainProviders>,
    document.getElementById('root')
  )
})()
