import React from 'react'
import styled from 'styled-components'
import { LinkProps, useLocation } from 'react-router-dom'
import { CoverCard, FocusStyles, Paragraph, Link } from '@boxine/tonies-ui'
import {
  AudioLibraryUrlState,
  createDetailPageLink,
  getPersistentParams,
} from '../../utils/functions/buildAudioLibraryUrl'
import Price, { PriceType } from '../Price'
import {
  LanguageLabel,
  PriceTitleWrapper,
  SliderSubTitle,
  TunesGridTitle,
} from '../SharedAudioLibraryStyles'
import { useShop } from '../../hooks/useShop'

export type TuneStatus =
  | 'available'
  | 'currentlyAssigned'
  | 'purchasable'
  | 'defaultAvailable'

const StyledLink = styled(Link)`
  display: block;
  border-radius: 1rem;
  text-decoration: none;
  color: ${props => props.theme.colors.darkergrey};
  ${FocusStyles}
`

const Square = styled.span`
  display: block;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  position: relative;
`

const SquareInner = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
  background: ${props => props.theme.CardShadow};
  overflow: hidden;
`

function getDetailPageLink(
  to: LinkProps['to'] | undefined,
  tuneId: string,
  urlParams: AudioLibraryUrlState | undefined,
  params: {
    params: URLSearchParams
    formattedSegment: string
    formatted: string
  },
  relatedUrl: string | undefined
): LinkProps['to'] {
  if (to !== undefined) {
    return to
  }

  let url: string

  if (urlParams) {
    url = createDetailPageLink(tuneId, urlParams)
  } else {
    url = `/audio-library/${tuneId}${params.formatted}`
  }

  const pageUrl = new URL(url, window.location.origin)

  if (relatedUrl) {
    pageUrl.searchParams.append('relatedUrl', relatedUrl)
  }

  return pageUrl.pathname + pageUrl.search
}

export interface TuneCardItem {
  id: string
  title: string
  salesId?: string
  contentInfo?: {
    chapters: Array<{
      title: string
      seconds: number
    }>
    seconds: number
  }
  thumbnail?: string
  series?: {
    name: string
  }
  languageUnicode?: string
  price?: PriceType
  subtitle?: string
  chapters?: Array<{
    seconds: number
  }>
  token?: string
  myTune?: {
    id: string
    assignCountCurrent: number
  }
  allCreativeTonies?: boolean
  minAge?: number
  genre?: {
    key: string
    title: string
  }
}

export function TuneCard({
  alt,
  className,
  tune,
  status = 'available',
  urlParams,
  to,
  shouldShowOnlyCoverImage = false,
  'data-testid': dataTestId = 'tune-card-link',
  'data-trackingid': dataTrackingId,
  relatedUrl,
  'data-tracking-variant': dataTrackingVariant,
  'data-tracking-variant-type': dataTrackingVariantType,
}: {
  tune: TuneCardItem
  status?:
    | 'available'
    | 'currentlyAssigned'
    | 'purchasable'
    | 'defaultAvailable'
  urlParams?: AudioLibraryUrlState
  to?: LinkProps['to']
  className?: string
  shouldShowOnlyCoverImage?: boolean
  'data-testid'?: string
  'data-trackingid'?: string
  alt?: string | any
  relatedUrl?: string
  'data-tracking-variant'?: string
  'data-tracking-variant-type'?: string
}) {
  const { createLinkByTune } = useShop()
  // Redirect to shop link if available
  const shopLink = createLinkByTune(tune)
  // Redirect to detail page if no shop link is available (e.g. for owned tunes)
  const params = getPersistentParams(useLocation().search)
  const detailPageLink = getDetailPageLink(
    to,
    tune.id,
    urlParams,
    params,
    relatedUrl
  )

  return (
    <span>
      <StyledLink
        data-trackingid={dataTrackingId}
        data-tracking-variant={dataTrackingVariant}
        data-tracking-variant-type={dataTrackingVariantType}
        to={shopLink || detailPageLink}
        className={className}
        data-testid={dataTestId ? dataTestId : 'tune-card-link'}
      >
        {tune.thumbnail ? (
          <CoverCard
            src={tune.thumbnail}
            alt={alt ? alt : tune.title}
            status={status}
          />
        ) : (
          <Square>
            <SquareInner />
          </Square>
        )}
        {!shouldShowOnlyCoverImage && (
          <PriceTitleWrapper>
            <TunesGridTitle>
              <LanguageLabel audioItem={tune} />
              {tune.title}
            </TunesGridTitle>

            <Paragraph size={3} weight={300} align="left" asHTMLTag="div">
              {tune.price && (
                <Price price={tune.price} isTunesGridPrice align="left" />
              )}
              {tune.subtitle && (
                <SliderSubTitle>{tune.subtitle}</SliderSubTitle>
              )}
            </Paragraph>
          </PriceTitleWrapper>
        )}
      </StyledLink>
    </span>
  )
}
