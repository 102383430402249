export function zendeskLinks() {
  const baseUrl = `https://tonies.support`

  return {
    home: baseUrl,
    newTicket: `${baseUrl}/requests/new`,
    articleEditCreativeTonie: `${baseUrl}/articles/360017267879`,
    articleFactoryReset: `${baseUrl}/articles/210381385`,
    articleFreeContent: `${baseUrl}/articles/360010948173`,
    articleRedLed: `${baseUrl}/articles/210281285`,
    articleSetupCable: `${baseUrl}/articles/4405102967442`,
    articleSetupNetworks: `${baseUrl}/articles/360021606040`,
    articleSetupTonieboxAnotherHousehold: `${baseUrl}/articles/212168069`,
    categoryTonieboxOperation: `${baseUrl}/categories/201157769`,
    canOnlyBeUsedOnCreativeTonie: `${baseUrl}/articles/360015988820`,
    creativeToniesCategory: `${baseUrl}/categories/201156785`,
    codeWordHedgehog: `${baseUrl}/articles/210689369`,
    offlineMode: `${baseUrl}/articles/115004355809`,
  }
}
