import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@boxine/tonies-ui'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { InvitationType } from '../../../prop-types'
import { Text } from '../../../components/Typography'

const RevokeInvitationModalContent = ({ onClose, onSubmit, invitation }) => {
  const { t } = useTranslation(['default', 'revoke-invitation-modal'])

  return (
    <>
      <Text>
        {t('revoke-invitation-modal:SureToRevokeInvitation', {
          email: invitation.email,
        })}
      </Text>

      <ModalFooter>
        <Button hasOutline={false} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button styling="primary" onClick={onSubmit}>
          {t('revoke-invitation-modal:RevokeInvitation')}
        </Button>
      </ModalFooter>
    </>
  )
}

RevokeInvitationModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invitation: InvitationType.isRequired,
}

export default RevokeInvitationModalContent
