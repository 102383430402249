import * as Sentry from '@sentry/browser'
import {
  createContext,
  createElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { isSameOrigin } from '../../utils/functions/functions'

type PositionsByUrl = Map<string, number>
type BackLink = {
  hash: string
  pathname: string
  search: string
  state: {
    restoreScrollPosition: boolean
  }
}
interface ScrollManagerContext {
  buildBackLink: (url: URL, timeout?: number) => BackLink
  restoreScrollPosition: () => void
  lazyScrollToY: (position: number, timeout?: number) => void
}

const Context = createContext<ScrollManagerContext | null>(null)

const originalHistoryPushState = window.history.pushState

function lazyScrollToY(position: number, timeout = 200) {
  setTimeout(() => {
    window.scrollTo(0, position)
  }, timeout)
}

function getCurrentUrl(): string {
  return window.location.pathname
}

function buildBackLink(url: URL, timeout?: number) {
  return {
    hash: url.hash,
    pathname: url.pathname,
    search: url.search,
    state: { restoreScrollPosition: true, timeout },
  }
}

export function useScrollManager() {
  const context = useContext(Context)

  if (!context) {
    throw new Error(
      'Provider was not found. Please add <ScrollManagerProvider> component to your App.'
    )
  }

  return context
}

export function ScrollManagerProvider({ children }: { children: ReactNode }) {
  const positionsByUrl = useRef<PositionsByUrl>(new Map())

  function restoreScrollPosition(url?: string, timeout?: number) {
    // If a url is set the search params will be cut off for comparison
    const nextUrl = new URL(url || getCurrentUrl(), window.location.origin)
    const position = positionsByUrl.current.get(nextUrl.pathname)

    if (position) lazyScrollToY(position, timeout)
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    function handlePushStateChange(
      data: any,
      title: string,
      url?: string | URL | null | undefined
    ) {
      positionsByUrl.current.set(getCurrentUrl(), window.scrollY)

      const nextUrl = url instanceof URL ? url.toString() : url

      // As we are getting `NS_ERROR_FAILURE` errors from Sentry that `.pushState` is called
      // with a non-origin URL (mostly coming from setup) we check this URL before calling.
      // This should cover the issue: https://sentry.boxine.de/boxine/my-tonies/issues/74288
      if (nextUrl && isSameOrigin(nextUrl)) {
        try {
          originalHistoryPushState.call(window.history, data, title, nextUrl)
        } catch (error) {
          Sentry.withScope(scope => {
            scope.setTag('feature', 'Provider#Scrollmanager')

            scope.setExtra('url', url)
            scope.setExtra('nextUrl', nextUrl)
            scope.setExtra('data', data)

            // This is very verbose but maybe helpful to examine the exception
            scope.setExtra('window.origin', window.origin)
            scope.setExtra('window.location.href', window.location.href)

            scope.setLevel('warning')
            Sentry.captureException(error)
          })
        }
      }

      // Check if buildBackLink() was used to restore scroll position
      if (nextUrl && data?.state?.restoreScrollPosition === true) {
        restoreScrollPosition(nextUrl, data.state.timeout)
      } else {
        // otherwise we just want to reset scroll position to 0
        lazyScrollToY(0, 0)
      }
    }

    function handlePopStateEvent() {
      restoreScrollPosition()
    }

    // we only intercept pushState; resetting scroll on replaceState is not useful
    window.history.pushState = handlePushStateChange
    window.addEventListener('popstate', handlePopStateEvent)

    return () => {
      window.history.pushState = originalHistoryPushState
      window.removeEventListener('popstate', handlePopStateEvent)
    }
  }, [])

  return createElement(
    Context.Provider,
    {
      value: {
        buildBackLink,
        restoreScrollPosition,
        lazyScrollToY,
      },
    },
    children
  )
}
