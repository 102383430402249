/* eslint-disable jsx-a11y/no-autofocus */
import {
  toast,
  OmniButton,
  Checkbox,
  Input,
  Radio,
  Paragraph,
  Spacing,
  media,
} from '@boxine/tonies-ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ModalFooter from '../ModalFooter/ModalFooter'
import { MEMBER_ACCESS_RIGHTS } from '../../globals'
import { http } from '../../http.service'
import { isMissing, isValidEmail } from '../../utils/validation/validation'

const StyledParagraph = styled(Paragraph)`
  line-height: 1.375rem;
`

const CheckboxWrapper = styled.div`
  max-width: 100%;
  margin-top: 1.25rem;
  padding-bottom: 0.5rem;

  label,
  div {
    align-items: flex-start;
    max-width: 100%;
    white-space: pre-wrap;
    border-radius: none;

    span {
      margin-left: 0;
      padding-top: 0;
    }

    input {
      margin-top: -0.25rem;
      margin-right: 1rem;
    }

    svg {
      margin-top: -0.25rem;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    margin-bottom: 0.75rem;

    ${media.tabletL`
      margin-left: 1rem;
    `}
  }

  ${media.tabletL`
    display: flex;
    flex-direction: row;
    justify-content:flex-end;`}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  label.email {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

interface InviteModalContentProps {
  householdId: string
  onClose: () => void
  onSuccess: (householdId: string) => void
}

function InviteModalContent({
  onClose,
  householdId,
  onSuccess,
}: InviteModalContentProps) {
  const { t } = useTranslation(['default', 'invite-modal'])
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [itype, setItype] = useState(MEMBER_ACCESS_RIGHTS.full)
  const [confirmed, setConfirmed] = useState(false)
  const [confirmationError, setConfirmationError] = useState(false)

  useEffect(() => {
    if (isSubmitted) {
      if (!isValidEmail(email)) {
        setError(t('default:InvalidEmail'))
      } else if (isMissing(email)) {
        setError(t('default:FieldMissing'))
      } else if (isValidEmail(email) && !isMissing(email)) {
        setError(null)
      }
    }
  }, [email, isSubmitted, t])

  function onChangeEmail(event) {
    setEmail(event.target.value)
  }

  async function onSubmit(event) {
    event.preventDefault()

    setIsSubmitted(true) // if touched the field is no longer pristine
    if (!confirmed) {
      setConfirmationError(true)

      return
    }
    // validate email address before sending invitation
    if (!isValidEmail(email)) {
      setError(t('default:InvalidEmail'))

      return
    } else if (isMissing(email)) {
      setError(t('default:FieldMissing'))

      return
    } else if (isValidEmail(email)) {
      setError(null)
    }

    try {
      await http.post(`/households/${householdId}/invitations`, {
        email,
        itype,
      })

      onSuccess(householdId)
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const {
          status,
          data: { reason },
        } = error.response

        switch (status) {
          case 403:
            if (reason === 'inviteeIsEduUser') {
              setError(t(`invite-modal:InviteeIsEduUser`, { email }))
            } else {
              setError(t(`invite-modal:NoPermission`))
            }
            break

          case 404:
            setError(t('invite-modal:NoHousehold', { email }))
            break

          case 409:
            setError(t('invite-modal:ConflictType', { email }))
            break

          case 400:
            toast(t('default:TOASTSomethingWentWrong'), 'error', {
              toastId: 'TOASTSomethingWentWrong',
            })
            break

          default:
            onClose()
            toast(t('default:TOASTSomethingWentWrong'), 'error', {
              toastId: 'TOASTSomethingWentWrong',
            })
            break
        }
      }
    }
  }

  function onFullMemberRadioChange() {
    setItype(MEMBER_ACCESS_RIGHTS.full)
  }

  function onLimitedMemberRadioChange() {
    setItype(MEMBER_ACCESS_RIGHTS.limited)
  }

  function onConfirmationChange() {
    setConfirmed(!confirmed)
    if (confirmationError) {
      setConfirmationError(false)
    }
  }

  return (
    <>
      <form noValidate onSubmit={onSubmit}>
        <Wrapper>
          <Input
            autoFocus={true}
            className="email"
            isInvalid={!!error}
            label={t('default:EMailAddress')}
            message={error ? { type: 'error', message: error } : undefined}
            onChange={onChangeEmail}
            type="email"
            value={email}
            dataTestId="invite-modal-input-email"
          />
          <Spacing pb="spacing-s" pt="spacing-s">
            <StyledParagraph size={2} weight={300}>
              {t('invite-modal:InviteTypeDescription')}
            </StyledParagraph>
          </Spacing>
          <Radio
            value={MEMBER_ACCESS_RIGHTS.full}
            checked={itype === MEMBER_ACCESS_RIGHTS.full}
            onChange={onFullMemberRadioChange}
            name="tonie-member-type"
          >
            <StyledParagraph size={3} weight={500} asHTMLTag="span">
              {t('invite-modal:LabelMemberTypeFull')}
            </StyledParagraph>
          </Radio>
          <br />
          <Radio
            value={MEMBER_ACCESS_RIGHTS.limited}
            checked={itype === MEMBER_ACCESS_RIGHTS.limited}
            onChange={onLimitedMemberRadioChange}
            name="tonie-member-type"
          >
            <span>
              <StyledParagraph size={3} weight={500} asHTMLTag="span">
                {t('invite-modal:LabelMemberTypeLimited')}
              </StyledParagraph>
              <Spacing mt="spacing-xs">
                <StyledParagraph
                  size={3}
                  weight={500}
                  asHTMLTag="span"
                  color="normalgrey"
                >
                  {t('invite-modal:SubLabelMemberTypeLimited')}
                </StyledParagraph>
              </Spacing>
            </span>
          </Radio>
          <CheckboxWrapper>
            <Checkbox
              status={confirmationError ? 'error' : 'ok'}
              name="invite-modal-consent"
              dataTestIdCheckbox="invite-modal-consent"
              isChecked={confirmed}
              onChange={onConfirmationChange}
              label={
                <StyledParagraph size={3} weight={500}>
                  {t('invite-modal:LabelConsentConfirmation')}
                </StyledParagraph>
              }
              message={
                confirmationError
                  ? { text: t('invite-modal:PleaseConfirm') }
                  : undefined
              }
            />
          </CheckboxWrapper>
        </Wrapper>

        <ModalFooter>
          <ButtonWrapper>
            <OmniButton onClick={onClose}>{t('default:Cancel')}</OmniButton>
            <OmniButton
              type="submit"
              styling="primary"
              dataTestId="invite-modal-submit"
            >
              {t('invite-modal:SendInvitation')}
            </OmniButton>
          </ButtonWrapper>
        </ModalFooter>
      </form>
    </>
  )
}

export default InviteModalContent
