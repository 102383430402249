import styled from 'styled-components'
import { media, Paragraph } from '@boxine/tonies-ui'
import { InnerContainer } from '../../layouts/LayoutV2'

export const FAQList = styled.ol`
  list-style-type: decimal;
  padding-left: 2rem;
  margin-bottom: 1rem;
`

export const FAQListUnordered = styled.ul`
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 1rem;
`

export const FAQText = styled(Paragraph)`
  font-size: 1rem;
  margin: 0.75rem 0;
  font-weight: normal;

  /* Contentful wraps text in list items with a paragraph */
  li > & {
    margin: 0;
  }
`

export const LI = styled.li`
  font-family: ${props => props.theme.fonts.serif};
  padding-bottom: 0.5rem;
`

export const FAQWrapper = styled.div<{ background: string }>`
  background: ${props => props.background};
  padding-top: 3rem;
  overflow: hidden;

  ${media.tabletL`
    padding-top: 5rem;
  `}
`

export const FAQInner = styled(InnerContainer)`
  flex-grow: 1;
  position: relative;
  left: -1rem;

  /* This is needed because our LayoutV2 container surrounding all sections comes with a
  predefined margin and adds to the margin the Accordion inside the Frequently questions
  comes with. The larger margin does not fit the design requirements. */
  > div {
    margin: 0;
  }

  > div > div > div > div > div:first-child {
    position: relative;
    right: -1rem;

    > div {
      margin: 0;
    }

    ${media.tabletL`
      margin-left: initial;
    `}
  }

  :link,
  a {
    color: inherit;
    text-decoration: underline;
  }
`

export const FAQTargetElement = styled.div`
  top: -4rem;
  ${media.tabletL`
    top: -6rem;
  `}
  ${media.laptop`
    top: -8rem;
  `}
  position: absolute;
`
