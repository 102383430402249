import { useUserProfile } from '../providers/user-profile'

export function useIsAudioLibraryAvailable() {
  const { user, flags } = useUserProfile()
  const { canBuyTunes } = flags

  if (user) {
    const { ownsTunes, anyPublicContentTokens } = user

    return {
      canBuyTunes,
      isAudioLibraryAvailable:
        canBuyTunes || ownsTunes || anyPublicContentTokens,
      canOwnTunes: canBuyTunes || ownsTunes,
      hasOwnTunes: ownsTunes,
      canGetAudioCandy: anyPublicContentTokens,
    }
  }

  return {
    canBuyTunes,
    isAudioLibraryAvailable: false,
    canOwnTunes: false,
    canGetAudioCandy: false,
    hasOwnTunes: false,
  }
}
