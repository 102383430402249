import { toast } from '@boxine/tonies-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { TonieReset } from '../../components/TonieReset'
import { TuneCardItem } from '../../components/TuneCard'
import { http } from '../../http.service'
import { generateTrackingEvent } from '../../utils/dataTrackingHelperFunctions/dataTrackingHelpers'
import {
  contentTonieDefaultData,
  ContentTonieDefaultData,
} from './TonieResetQuery'

export default function TonieResetPage() {
  const history = useHistory()
  const { t } = useTranslation()
  const { householdId, contentTonieId, creativeTonieId } = useParams<{
    householdId: string
    contentTonieId?: string
    creativeTonieId?: string
  }>()
  const isCreativeTonieContent = !!creativeTonieId
  // TODO: Reset page is currently related to a route on the Top-Level.
  // Therefore we cannot pass data into the component and need the query
  // because we need the default content data for data-tracking.
  // This should be optimized in the future.
  const [{ data }] = useQuery<ContentTonieDefaultData>({
    query: contentTonieDefaultData,
    requestPolicy: 'network-only',
    variables: {
      householdId,
      contentTonieId,
    },
    pause: isCreativeTonieContent,
  })

  const handleReset = async () => {
    try {
      await http.delete(
        isCreativeTonieContent
          ? `/households/${householdId}/tonie/${creativeTonieId}/tune`
          : `/households/${householdId}/tonie/${contentTonieId}/tune`
      )
      history.push(
        isCreativeTonieContent
          ? `/creative-tonies/${householdId}/${creativeTonieId}/refresh`
          : `/content-tonies/${householdId}/${contentTonieId}/refresh`
      )

      if (!isCreativeTonieContent && data) {
        const defaultTuneData = data && data.households[0].contentTonies[0]
        const defaultTune: TuneCardItem = {
          id: defaultTuneData.id,
          title: defaultTuneData?.title,
          series: defaultTuneData.series,
          languageUnicode: defaultTuneData.languageUnicode,
          genre: defaultTuneData.genre,
          minAge: defaultTuneData.minAge,
        }

        generateTrackingEvent({
          actionContext: 'content-detail',
          content: defaultTune,
          event: 'assignment_complete',
          itemCount: 1,
          itemPlaytime: defaultTuneData.secondsPresent,
          assignedPlaytime: defaultTuneData.secondsPresent,
          message: 'Restore original playlist',
          tonieType: 'content',
          selectionMode: 'single',
          isDefaultContentAssignment: true,
        })
      }
    } catch (error) {
      // TODO: Better error message
      toast(t('default:TOASTSomethingWentWrong'), 'error', {
        toastId: `error`,
      })
    }
  }

  return (
    <TonieReset
      headline={
        isCreativeTonieContent
          ? t('tonie-reset:CreativeTonieHeadline')
          : t('tonie-reset:Headline')
      }
      text={
        isCreativeTonieContent
          ? t('tonie-reset:CreativeTonieInstructions')
          : t('tonie-reset:Instructions')
      }
      resetCallback={handleReset}
      trackingIdPrefix={
        isCreativeTonieContent
          ? 'creative-tonie-edit__load-previous-playlist'
          : `content-tonie-detail__load-original-content`
      }
      backLink={
        isCreativeTonieContent
          ? `/creative-tonies/${householdId}/${creativeTonieId}`
          : `/content-tonies/${householdId}/${contentTonieId}`
      }
    />
  )
}
