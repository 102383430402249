import styled, { css } from 'styled-components'
import { OmniButton, themeHelpers } from '@boxine/tonies-ui'
const { media } = themeHelpers

const style = css`
  align-self: center;
  margin-top: 1rem;

  ${media.tabletL`
    align-self: start;
  `};
`
export const ResponsiveButton = styled(OmniButton)`
  ${style};
`
export const ResponsiveLinkButton = styled(OmniButton)`
  ${style};
`
