import React from 'react'
import * as Sentry from '@sentry/react'
import GenericErrorPage from '../../components/error-pages/GenericErrorPage'
import { CLIENT_ERROR_CODE } from '../../globals'

function parseErrorMessage({
  message = '',
  code = '',
}: {
  message: string
  code: number | string | undefined
}) {
  if (code === 'i18n-failed') {
    return { message: 'i18n-failed', code: CLIENT_ERROR_CODE.i18nFailed }
  }

  // TOC-580 Checks if the error happens because a dynamic chunk cannot be loaded
  const isChunkLoadingError = new RegExp(/Loading chunk/).exec(message)
  if (isChunkLoadingError) {
    return {
      message: 'Loading chunk failed',
      code: CLIENT_ERROR_CODE.chunkLoading,
    }
  }

  return { message: 'Something went wrong', code: CLIENT_ERROR_CODE.unknown }
}

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    code: CLIENT_ERROR_CODE.unknown,
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      code: parseErrorMessage({ message: error.message, code: error.code }),
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Sentry.ErrorBoundary
          fallback={
            <GenericErrorPage
              code={this.state.code}
              shouldDisableLink
              shouldIncludeHeader
            />
          }
        />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
