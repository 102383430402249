/* eslint-disable react/display-name */
import React from 'react'
import { BLOCKS, MARKS, INLINES, Document } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import {
  Accent,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  List,
  ListItem,
  Paragraph,
  Spacing,
} from '@boxine/tonies-ui'
import styled from 'styled-components'

const headlineType = {
  h1: Headline1,
  h2: Headline2,
  h3: Headline3,
  h4: Headline4,
  span: 'span',
}

const PARAGRAPH_SIZE = 2

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.BrandPrimary};
  &:hover {
    text-decoration: underline;
  }
`

type ContentfulHeadlineProps = {
  content: Pick<Document, 'content' | 'data'> & { nodeType: 'document' }
  headlineTag?: keyof typeof headlineType
}

type ContentfulRichTextProps = {
  content: Pick<Document, 'content' | 'data'> & { nodeType: 'document' }
  renderers: RenderNode
}

export function ContentfulHeadline({
  content,
  headlineTag = 'h2',
}: ContentfulHeadlineProps) {
  const HeadlineTag = headlineType[headlineTag]
  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <strong>{children}</strong>,
      [MARKS.ITALIC]: children => <Accent>{children}</Accent>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <HeadlineTag>{children}</HeadlineTag>
      ),
    },
  }
  // we can't infer the document enum from valita, so we'll break it here
  //@ts-ignore
  return <>{documentToReactComponents(content, renderOptions)}</>
}

export function ContentfulRichText({
  content,
  renderers,
}: ContentfulRichTextProps) {
  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <strong>{children}</strong>,
      [MARKS.ITALIC]: children => <em>{children}</em>,
      [MARKS.CODE]: children => <code>{children}</code>,
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Headline2>{children}</Headline2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Headline3>{children}</Headline3>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Headline4>{children}</Headline4>
      ),
      [BLOCKS.OL_LIST]: (_node, children) => (
        <Spacing mb="spacing-s" mt="spacing-s" applyToChildren>
          <List size={PARAGRAPH_SIZE}>{children}</List>
        </Spacing>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => (
        <Spacing mb="spacing-s" mt="spacing-s" applyToChildren>
          <List size={PARAGRAPH_SIZE} type="unordered">
            {children}
          </List>
        </Spacing>
      ),
      [BLOCKS.LIST_ITEM]: (_node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Spacing mb="spacing-s" mt="spacing-s" applyToChildren>
          <Paragraph size={PARAGRAPH_SIZE} whiteSpace="pre-line">
            {children}
          </Paragraph>
        </Spacing>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <StyledLink
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </StyledLink>
      ),
      ...renderers,
    },
  }

  return <>{documentToReactComponents(content as Document, renderOptions)}</>
}
