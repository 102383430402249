import React from 'react'
import { Redirect, useParams } from 'react-router'

export function RedirectLucidDream() {
  const creativeTonieId = useParams<{ creativeTonieId: string }>()
    .creativeTonieId

  return (
    <Redirect
      to={{
        pathname: '/tell-a-story',
        search: `creativeTonieId=${creativeTonieId}&webview=app`,
      }}
    />
  )
}
