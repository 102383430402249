import { gql } from 'graphql-tag'

export const query = gql`
  query ($selection: String) {
    contentTokens(selection: $selection) {
      edges {
        node {
          id
          thumbnail
          title
          subtitle
          token
          languageUnicode
        }
      }
    }
    allContentTokens: contentTokens(
      selection: $selection
      includeExpired: true
      first: 0
    ) {
      totalCount
    }
  }
`

interface AudioCandy {
  id: string
  thumbnail: string
  title: string
  subtitle?: string
  languageUnicode?: string
  token: string
}

export interface FreeContentSectionQuery {
  contentTokens: {
    edges: Array<{
      node: AudioCandy
    }>
  }
  allContentTokens: {
    totalCount: number
  }
}
