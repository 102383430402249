import * as v from '@badrap/valita'
import { OmniButton, Stage, StageContent } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import React, { useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { elementScrollIntoView } from 'seamless-scroll-polyfill'
import styled, { ThemeContext } from 'styled-components'
import {
  cooperationSchema,
  CooperationSection,
  CooperationSectionPlaceholder,
} from '../../components/CooperationSection'
import NotFound from '../../components/error-pages/NotFound'
import { FAQSection, faqSectionSchema } from '../../components/FAQSection'
import {
  FreeContentSection,
  freeContentSectionSchema,
  FreeContentSectionSkeleton,
} from '../../components/FreeContentSection'
import { Head } from '../../components/head'
import { Skeleton } from '../../components/Skeleton'
import { useContentful } from '../../contentful'
import {
  contentfulDocument,
  contentfulFile,
} from '../../contentful/checkContentfulData'
import { ContentfulHeadline } from '../../contentful/ContentfulRichText'
import { LayoutV2 } from '../../layouts/LayoutV2'
import { FAQPlaceholder } from './FAQPlaceholder'

const StyledLayoutV2 = styled(LayoutV2)<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || props.theme.White};
  display: flex;
  flex-direction: column;
  flex: 1;

  ::before {
    background-color: ${props => props.backgroundColor || props.theme.White};
  }
`

// We'll get data for every defined landing page instead of only the one
// the user is currently viewing. Validation errors in unrelated landing
// pages should not break the current one and thus the schema validation
// needs to take the current url into account, see TOC-5144.
const freeContentSchema = (url: string) =>
  v
    .object({
      landingPages: v.array(
        // If the landing page is a draft the API returns
        // `undefined`
        v
          .union(
            // Skip validating page data if it's for a different
            // landing page compared to the one we're currently on.
            v.object({
              url: v
                .string()
                .assert(s => s !== url)
                .optional(),
            }),

            // We found the dataset for the current landing page.
            // Proceed validation as usual.
            v.object({
              url: v.string(),
              stage: v.object({
                headline: contentfulDocument,
                backgroundColor: v.string(),
                textColor: v.string().optional(),
                imageDesktop: contentfulFile,
                imageMobile: contentfulFile,
                text: v.string(),
                linkLabel: v.string().optional(),
                linkTarget: v.string().optional(),
                linkTrackingId: v.string().optional(),
              }),
              sectionFaq: faqSectionSchema.optional(),
              sectionCooperation: cooperationSchema.optional(),
              sectionFreeContent: freeContentSectionSchema.optional(),
              pageTitle: v.string(),
            })
          )
          .optional()
      ),
    })
    .optional()

export function FreeContentLandingPage() {
  const { landingPageUrl } = useParams<{ landingPageUrl: string }>()
  const FAQRef = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const { t } = useTranslation(['default'])

  // Ensure a stable schema reference to avoid re-fetching from
  // contentful whenever we re-render the page.
  const schema = useMemo(() => {
    return freeContentSchema(landingPageUrl)
  }, [landingPageUrl])

  const { data, error, isFetching } = useContentful({
    contentId: '4tZ2YYccvykqDGMxMAN7g8',
    schema,
  })

  if (error) {
    throw error
  }

  if (!isFetching && !data) {
    return <NotFound />
  }

  if (isFetching) {
    const backgroundColor = theme.colors['anthracite-10']
    const itemColor = theme.colors['anthracite-20']
    return (
      <StyledLayoutV2 backgroundColor={backgroundColor}>
        <Stage
          backgroundColor={backgroundColor}
          imageDesktop=""
          imageMobile=""
          curveLayout="bottomRight"
          curveColor={theme.White}
        >
          <StageContent
            headline={
              <Skeleton width="60%" height={32} baseColor={itemColor} />
            }
            text={
              <>
                <Skeleton height={20} baseColor={itemColor} />
                <Skeleton height={20} baseColor={itemColor} />
                <Skeleton height={20} width="50%" baseColor={itemColor} />
              </>
            }
          >
            <Skeleton height={26} width={100} baseColor={itemColor} />
          </StageContent>
        </Stage>
        <FreeContentSectionSkeleton />
        <FAQPlaceholder />
        <CooperationSectionPlaceholder />
      </StyledLayoutV2>
    )
  }

  const onFAQClick = () => {
    if (FAQRef?.current) {
      elementScrollIntoView(FAQRef.current, { behavior: 'smooth' })
      FAQRef.current.focus()
    }
  }

  const currentLandingPage = data?.landingPages.find(landingPage => {
    return (
      landingPage !== undefined &&
      landingPage.url?.toLowerCase() === landingPageUrl.toLowerCase()
    )
  })

  // Use type guards to narrow down TS type to valid landing page
  // schema
  if (!currentLandingPage || !('stage' in currentLandingPage)) {
    return <NotFound />
  }

  const { stage, url } = currentLandingPage

  const stageBackgroundColor = theme.colors[stage.backgroundColor]

  const textColor = stage.textColor === 'white' ? 'white' : 'darkergrey'

  return (
    <StyledLayoutV2 backgroundColor={stageBackgroundColor}>
      {currentLandingPage.pageTitle && (
        <Head pageTitle={currentLandingPage.pageTitle} />
      )}
      <Stage
        backgroundColor={stageBackgroundColor}
        imageDesktop={stage.imageDesktop.file.url}
        imageMobile={stage.imageMobile.file.url}
        curveLayout="bottomRight"
        curveColor={theme.White}
      >
        <StageContent
          headlineColor={textColor}
          textColor={textColor}
          headline={
            <ContentfulHeadline content={stage.headline!} headlineTag="span" />
          }
          text={stage.text}
        >
          {stage.linkTarget && stage.linkTrackingId && stage.linkLabel ? (
            <OmniButton
              iconEnd={icons.arrowRightFill}
              href={
                stage.linkTarget.startsWith('https')
                  ? stage.linkTarget
                  : `/${stage.linkTarget}`
              }
              target={
                stage.linkTarget.startsWith('https') ? '_blank' : undefined
              }
              rel={
                stage.linkTarget.startsWith('https')
                  ? 'noopener noreferrer'
                  : undefined
              }
              data-trackingid={`landingpage-${url}__stage__${stage.linkTrackingId}`}
              isWhiteTextLink={stage.textColor === 'white'}
            >
              {stage.linkLabel}
            </OmniButton>
          ) : (
            <OmniButton
              iconEnd={icons.arrowDownFill}
              onClick={onFAQClick}
              data-trackingid={`landingpage-${url}__stage__faq-click`}
              isWhiteTextLink={stage.textColor === 'white'}
            >
              {t('entry-page:ToFAQ')}
            </OmniButton>
          )}
        </StageContent>
      </Stage>

      {currentLandingPage.sectionFreeContent && (
        <FreeContentSection
          returnUrl={`/lp/${landingPageUrl}`}
          contentful={currentLandingPage.sectionFreeContent}
        />
      )}

      {currentLandingPage.sectionFaq && (
        <FAQSection
          data={currentLandingPage.sectionFaq}
          faqRef={FAQRef}
          trackingPrefix={`landingpage-${landingPageUrl}__faqs__`}
        />
      )}

      {currentLandingPage.sectionCooperation && (
        <CooperationSection
          landingPageUrl={landingPageUrl}
          data={currentLandingPage.sectionCooperation}
        />
      )}
    </StyledLayoutV2>
  )
}
