import {
  ToniesFontStyles,
  ToniesGlobalStyles,
  ToniesTypographyStyles,
} from '@boxine/tonies-ui'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../base-styles'
import i18nConfig from '../../i18n'
import theme from '../../theme'
import VersionCheckProvider from '../VersionCheckProvider'
import {
  GraphqlProvider,
  createGraphqlClient,
  defaultOptions,
} from '../../graphql'
import { ScrollManagerProvider } from '../scrollmanager'

// Setup the GraphQL client with defaults
const graphqlClient = createGraphqlClient(defaultOptions)

const MainProviders = ({ children }) => {
  // TOC-3486: The CSS value 100vh is unusable in mobile browsers, due to
  // the address bar. Some browsers exclude it by default, others don't
  // and some browsers have buggy behaviour. This leads to the lower
  // area of the viewport being cut off by the same amount as the address
  // bar. Weirdly this is intentional by browser vendors, see:
  // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  //
  // So instead measuring `window.innerHeight` is a much more reliable
  // way to get the actual viewport height. We'll store it in a CSS
  // variable so that we can access it directly from within CSS.
  useEffect(() => {
    const setVhSize = () => {
      document
        .querySelector(':root')
        .style.setProperty('--vh', window.innerHeight / 100 + 'px')
    }
    setVhSize()
    window.addEventListener('resize', setVhSize)
    return () => {
      window.removeEventListener('resize', setVhSize)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GraphqlProvider value={graphqlClient}>
        <I18nextProvider i18n={i18nConfig}>
          <VersionCheckProvider>
            <ScrollManagerProvider>
              <ToniesFontStyles />
              <ToniesGlobalStyles />
              <GlobalStyle />
              <ToniesTypographyStyles />
              {children}
            </ScrollManagerProvider>
          </VersionCheckProvider>
        </I18nextProvider>
      </GraphqlProvider>
    </ThemeProvider>
  )
}

MainProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainProviders
