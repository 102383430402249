/* eslint-disable react/display-name */
import React from 'react'
import * as v from '@badrap/valita'
import { FrequentlyAskedQuestions, theme, useMedia } from '@boxine/tonies-ui'
import { BLOCKS, MARKS, Document, INLINES } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { ContentfulHeadline } from '../contentful/ContentfulRichText'
import {
  contentfulDocument,
  contentfulFile,
} from '../contentful/checkContentfulData'
import { useContentfulColor } from '../contentful/useContentfulColor'
import {
  FAQInner,
  FAQList,
  FAQListUnordered,
  FAQText,
  LI,
  FAQTargetElement,
  FAQWrapper,
} from './FAQLayoutElements'

export const faqSectionSchema = v.object({
  copy: v.string(),
  faqItems: v.array(
    v.object({
      answer: contentfulDocument,
      question: v.string(),
      trackingId: v.string().optional(),
    })
  ),
  headline: contentfulDocument,
  backgroundColor: v.string(),
  imageDesktop: contentfulFile,
  imageMobile: contentfulFile,
})

export function FAQSection({
  data,
  faqRef,
  trackingPrefix = '',
}: {
  data: v.Infer<typeof faqSectionSchema>
  faqRef: React.RefObject<HTMLDivElement>
  trackingPrefix?: string
}) {
  const isLaptopWidth = useMedia({ minWidth: theme.screenTabletL })
  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => <strong>{children}</strong>,
      [MARKS.ITALIC]: children => <em>{children}</em>,
    },
    renderNode: {
      [BLOCKS.OL_LIST]: (_node, children) => <FAQList>{children}</FAQList>,
      [BLOCKS.UL_LIST]: (_node, children) => (
        <FAQListUnordered>{children}</FAQListUnordered>
      ),
      [BLOCKS.LIST_ITEM]: (_node, children) => <LI>{children}</LI>,
      [BLOCKS.PARAGRAPH]: (_node, children) => <FAQText>{children}</FAQText>,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  }
  const faqItems = data.faqItems.map(field => {
    return {
      question: field.question,
      answer: documentToReactComponents(
        field.answer as Document,
        renderOptions
      ),
      trackingId: `${trackingPrefix}${field.trackingId}-`,
    }
  })

  const backgroundColor = useContentfulColor(data.backgroundColor)

  return (
    <FAQWrapper background={backgroundColor}>
      <FAQInner>
        <FAQTargetElement ref={faqRef} tabIndex={-1} />
        <FrequentlyAskedQuestions
          backgroundColor={backgroundColor}
          headline={
            <ContentfulHeadline headlineTag="span" content={data.headline} />
          }
          subheadline={data.copy}
          entries={faqItems}
          imageDesktop={`${window.location.protocol}${data.imageDesktop.file.url}`}
          imageMobile={`${window.location.protocol}${data.imageMobile.file.url}`}
          layout={!isLaptopWidth ? 'half-screen' : 'half-grid'}
          scaling={100}
        />
      </FAQInner>
    </FAQWrapper>
  )
}
