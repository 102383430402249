import {
  Accent,
  media,
  AddCard,
  InfoCard,
  PlaceholderCard,
  ImageV2,
  CardShared,
  Headline2,
  toast,
  useLocalStorage,
} from '@boxine/tonies-ui'
import React, { Suspense, useEffect, useState, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { HouseholdFilter } from '../../components/HouseholdFilter'
import { http } from '../../http.service'
import { InnerContainer, LayoutV2 } from '../../layouts/LayoutV2'
import { hasOwnerAccess } from '../../utils/functions/functions'
import { PageTitleSection } from '../../components/PageTitleSection'
import { Head } from '../../components/head'
import { reduceContentTonies, sortContentTonies } from '../../utils/tonie-utils'
import { AddContentTonieModal } from '../../components/AddContentTonieModal'
import { ColoredSection } from '../../components/ColoredSection'
import { buildUrl } from '../../utils/functions/buildAudioLibraryUrl'
import { DiscoverMoreSlider } from '../../components/DiscoverMoreSlider/DiscoverMoreSlider'
import { TuneCard } from '../../components/TuneCard'
import { useUserProfile } from '../../providers/user-profile'
import { selectTunesSeriesName } from '../../utils/tunes-utils'
import ContentTonieCard from './ContentTonieCard'
import {
  ContentToniePageQuery,
  contentToniePageQuery,
} from './contentToniePageQuery'
import { ContentTonieTeaserCard } from './ContentTonieTeaserCard'

const CardWrapper = styled.div`
  width: calc(100% + 0.3rem);
`

const EmptyImage = styled.div`
  width: calc(100% + 3.9rem);
  height: auto;
  position: relative;
  left: -1rem;

  @media (min-width: 25rem) {
    left: -2rem;
  }

  ${media.tabletL`
    left: -3.5rem;
  `}

  @media (min-width: 40rem) {
    left: -2rem;
  }
`

const GridSpan = styled.div`
  grid-column: 1 / span 1;

  ${media.mobileSM`
    grid-column: 1 / span 2;
  `}

  @media (min-width: 34.625rem) {
    grid-column: 1 / span 3;
  }

  ${media.tabletL`
    grid-column: 1 / span 2;
  `}
`

const InfoCardImage = React.lazy(() => import('./InfoCardImage'))

const StyledCardGrid = styled(CardShared.CardGrid)`
  grid-row-gap: 2.5rem;

  @media (min-width: 34.625rem) {
    grid-row-gap: 2.5rem;
  }

  ${media.laptop`
    grid-row-gap: 3rem;
  `}
`

/**
 * @param {{ households: any[]; }} data
 */
function getTonieCollection(data) {
  if (!data || !Array.isArray(data.households)) return

  return data.households.reduce((tonieCollection, currentHousehold) => {
    // The check is for users who have full access to the household, but
    // the owner of the household already locked at least one of the Tonie
    // of this household. If is this the case, the user won't see the info
    // if the check does not exist.
    if (!hasOwnerAccess(currentHousehold)) {
      return tonieCollection
    }

    return [...tonieCollection, ...currentHousehold.contentTonies]
  }, [])
}

/**
 * @param {any[]} tonieCollection
 */
function hasLockedTonies(tonieCollection) {
  if (!tonieCollection || !Array.isArray(tonieCollection)) return false

  return tonieCollection.some(({ lock }) => lock === true)
}

export function ContentToniePage() {
  const { t } = useTranslation(['content-tonies'])
  const [activeTag, setActiveTag] = useState('')
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEmptyInfo, setShowEmptyInfo] = useLocalStorage(
    'showEmptyInfo',
    true
  )
  const [hasClosedLockingInfo, setHasClosedLockingInfo] = useLocalStorage(
    'hasClosedLockingInfo',
    false
  )

  const { flags } = useUserProfile()
  const canBuyTunes = flags?.canBuyTunes

  const [{ data, error, fetching }, refresh] = useQuery<ContentToniePageQuery>({
    query: contentToniePageQuery,
    requestPolicy: 'network-only',
    variables: {
      canBuyTunes,
    },
  })

  useEffect(() => {
    if (error && error.response) {
      toast(t('default:TOASTSomethingWentWrong'), 'error', {
        toastId: 'reload-creative-tonies-failure',
      })
    }
  }, [t, error])

  const columns = {
    columnAmountDefault: 2.5,
    columnAmountMobileL: 3.5,
    columnAmountTablet: 5.5,
    columnAmountLaptop: 6,
  }

  const showInfoCard = useMemo(() => {
    const hasLockedContentTonies = hasLockedTonies(getTonieCollection(data))

    return !hasLockedContentTonies && !hasClosedLockingInfo
  }, [data, hasClosedLockingInfo])

  function onLockTonie(householdId, contentTonieId) {
    return http
      .patch(`/households/${householdId}/contenttonies/${contentTonieId}`, {
        lock: true,
      })
      .catch(error => {
        if (error && error.response) {
          toast(t('default:TOASTSomethingWentWrong'), 'error', {
            toastId: 'lock-content-tonie-failure',
          })
        }

        throw error
      })
  }

  const contentTonies =
    data && data.households.length > 0
      ? data.households
          .filter(household => {
            return activeTag === household.id || !activeTag
          })
          .reduce(reduceContentTonies, [])
          .sort(sortContentTonies)
      : []

  return (
    <LayoutV2>
      <Head pageTitle={t('content-tonies:PageTitle')} />
      <InnerContainer hasHouseholdFilter>
        <PageTitleSection
          title={
            <Headline2 asHTMLTag="h1">{t('default:ContentTonies')}</Headline2>
          }
          hasHouseholdFilter
        >
          {data && data.households.length > 1 && (
            <HouseholdFilter
              data-trackingid="content-tonie-overview__filter__household"
              households={data.households}
              onChange={setActiveTag}
              value={activeTag}
            />
          )}
        </PageTitleSection>
      </InnerContainer>
      <ColoredSection>
        <InnerContainer>
          <StyledCardGrid>
            {fetching && (
              <>
                <PlaceholderCard />
                <PlaceholderCard />
                <PlaceholderCard />
                <PlaceholderCard />
              </>
            )}
            {!fetching &&
              (data?.households?.length === 0 || contentTonies.length === 0) &&
              showEmptyInfo && (
                <GridSpan>
                  <div data-testid="no-content-tonies">
                    <InfoCard
                      headline={
                        <Trans i18nKey="content-tonies:InfoCardHeadline">
                          Your Tonie <Accent>collection</Accent>
                        </Trans>
                      }
                      data-trackingid="content-tonie-overview__infocard__dismiss-button"
                      content={t('content-tonies:InfoCardEmptyContent')}
                      media={
                        <EmptyImage>
                          <ImageV2
                            src="https://cdn.tonies.de/doodles/content-tonies-info-card-empty-doodle.png"
                            alt=""
                          />
                        </EmptyImage>
                      }
                      onClose={() => setShowEmptyInfo(false)}
                    />
                  </div>
                </GridSpan>
              )}
            {!fetching &&
              data &&
              data.households.length > 0 &&
              contentTonies.length > 0 &&
              showInfoCard && (
                <GridSpan>
                  <InfoCard
                    headline={
                      <Trans i18nKey="content-tonies:InfoCardHeadline">
                        Your Tonie <Accent>collection</Accent>
                      </Trans>
                    }
                    content={t('content-tonies:InfoCardContent')}
                    media={
                      <Suspense fallback={<></>}>
                        <InfoCardImage />
                      </Suspense>
                    }
                    onClose={() => setHasClosedLockingInfo(true)}
                  />
                </GridSpan>
              )}
            {!fetching && flags.canBuyTunesForContentTonies && (
              <ContentTonieTeaserCard />
            )}
            {!fetching &&
              data &&
              data.households.length > 0 &&
              contentTonies.map(({ tonie, household }) => {
                return (
                  <ContentTonieCard
                    key={tonie.id}
                    household={household}
                    showHousehold={data.households.length > 1}
                    tonie={tonie}
                    onLock={() => onLockTonie(household.id, tonie.id)}
                  />
                )
              })}
            {!fetching && (
              <CardWrapper>
                <AddCard
                  data-trackingid="content-tonie-overview__add-tonie-card__modal"
                  onClick={() => setShowAddModal(true)}
                  title={t('AddContentTonie')}
                />
              </CardWrapper>
            )}
          </StyledCardGrid>
        </InnerContainer>
      </ColoredSection>
      {canBuyTunes &&
        !fetching &&
        data &&
        data.tunesItems &&
        data.tunesItems.edges.length > 0 && (
          <DiscoverMoreSlider
            hasNoBackgroundColor
            definedColumns={columns}
            shouldShowPrices
            findMoreHref={buildUrl({
              activeTab: 'shop',
              isForMyToniesActive: true,
            })}
            data-trackingid="content-tonie-overview__cover-slider-more-content__find-more-click"
            headline={t('audio-library-detail:AdditionalContent')}
            padding="1.5rem 0"
          >
            {data?.tunesItems.edges.map(({ node: tune }) => {
              const seriesName = selectTunesSeriesName(tune)
              const trackingId = `content-tonie-overview__cover-slider-more-content__cover-click-${
                seriesName ? seriesName.value : 'unknown-series'
              }-${tune.title}`

              return (
                <TuneCard
                  data-trackingid={trackingId}
                  key={tune.id}
                  tune={tune}
                  relatedUrl="/content-tonies"
                />
              )
            })}
          </DiscoverMoreSlider>
        )}
      <AddContentTonieModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onSubmit={() => {
          refresh()
          setShowAddModal(false)
        }}
      />
    </LayoutV2>
  )
}
