import { Accent, Headline4 } from '@boxine/tonies-ui'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Tonie from '../Tonie'
import { ResponsiveLinkButton } from '../visual-elements/responsive-button'
import { Text } from '../Typography'
import { selectContentTonieSeriesName } from '../../utils/tonie-utils'
import { TonieWrapper, ToniesWrapper } from '.'

const StyledHeadline = styled(Headline4)`
  margin: 0.5em 0;
  font-size: 1.25rem;

  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

interface ContentTonieDashboard {
  id: string
  householdId: string
  imageUrl: string
  title: string
  series?: {
    name: string
    group?: {
      name: string
    }
  }
}

export const DashboardContentTonies = ({
  contentTonies = [],
}: {
  contentTonies: ContentTonieDashboard[]
}) => {
  const { t } = useTranslation(['home', 'default'])
  const tonies = useMemo(
    () =>
      sortBy(contentTonies, [
        'series.group.name',
        'series.name',
        'title',
      ]).slice(0, 4),
    [contentTonies]
  )

  return (
    <>
      <StyledHeadline
        asHTMLTag="h2"
        weight={500}
        data-testid="dashboard-content-tonies-headline"
      >
        <Trans
          i18nKey="home:YourContentTonies"
          components={{ bello: <Accent /> }}
        />
      </StyledHeadline>
      {tonies.length > 0 ? (
        <>
          <ToniesWrapper>
            {tonies.map(tonie => {
              const series = selectContentTonieSeriesName(tonie)

              return (
                <TonieWrapper
                  key={tonie.id}
                  to={`/content-tonies/${tonie.householdId}/${tonie.id}`}
                  data-testid="content-tonie-link"
                  data-testid-series-key={series.key}
                >
                  <Tonie
                    src={tonie.imageUrl}
                    name={series.value}
                    direction="row"
                    maxLength={50}
                    alt={series.value}
                  />
                </TonieWrapper>
              )
            })}
          </ToniesWrapper>
          <ResponsiveLinkButton
            styling="primary"
            href="/content-tonies"
            dataTestId="see-all"
            data-trackingid="home__to-content-tonies__button-press"
          >
            {t('home:AllContentTonies')}
          </ResponsiveLinkButton>
        </>
      ) : (
        <>
          <Text>{t('home:MessageNoContentTonies')}</Text>
          <ResponsiveLinkButton
            styling="primary"
            href="/content-tonies"
            dataTestId="learn-more"
          >
            {t('home:LearnMoreAboutContentTonies')}
          </ResponsiveLinkButton>
        </>
      )}
    </>
  )
}
