import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import {
  variables,
  media,
  PlaceholderCard,
  FocusStyles,
  HorizontalScrollList,
  ImageV2,
} from '@boxine/tonies-ui'
import { Link } from 'react-router-dom'
import * as v from '@badrap/valita'
import { contentfulFile } from '../../contentful/checkContentfulData'
import { useContentful } from '../../contentful'
import { useFlag } from '../../providers/feature-flags'

export interface Tonieboxes {
  id: string
  name: string
  imageUrl: string
}

interface TeaserProps {
  tonieboxes: Tonieboxes[]
  showAddTonieboxModal: () => void
}

const registerTonieboxEN = {
  referenceTitle: 'Register Toniebox EN',
  alt: 'Register Your Toniebox',
  src: 'https://cdn.tonies.de/o/images/registerTonieboxTeaserEN.png',
  link: '',
  dataTrackingId: 'home-teaserslider__teaser-press__toniebox-setup',
}

// these two are used even when contentful is active
const registerTonieboxDE = {
  referenceTitle: 'Register Toniebox DE',
  alt: 'Registriere Deine Toniebox',
  src: 'https://cdn.tonies.de/o/images/registerTonieboxTeaserDE.png',
  link: '',
  dataTrackingId: 'home-teaserslider__teaser-press__toniebox-setup',
}

const Wrapper = styled.div`
  margin: 1rem 0 0;
`

const TeaserCardItemCss = css`
  width: 17.4375rem;
  height: 14.8125rem;

  ${media.mobileM`
    width: 19.375rem;
    height: 16.5rem;
  `}

  ${media.mobileL`
    width: 21.3125rem;
    height: 18.125rem;
  `}

  ${media.tabletL`
    width: 25.625rem;
    height: 21.75rem;
  `}

  ${media.laptop`
    width: 28.6875rem;
    height: 24.4375rem;
  `}
`

const StyledHorizontalScrollList = styled(HorizontalScrollList)<{
  isPlaceholderTeaser?: boolean
}>`
  [data-scroll-prev] {
    left: 5.25rem;
  }
  [data-scroll-next] {
    right: 5.25rem;
  }

  ul {
    margin-left: 1.25rem;
    padding: 0.5rem 1rem 0.5rem 0;

    ${props => {
      const childrenCount = React.Children.count(props.children)

      return css`
        grid-template-columns: repeat(${childrenCount}, 17.4375rem);

        ${media.mobileM`
          grid-template-columns: repeat(${childrenCount}, 19.375rem);
        `}

        ${media.mobileL`
          grid-template-columns: repeat(${childrenCount}, 21.3125rem);
        `}

        ${media.tabletL`
          grid-template-columns: repeat(${childrenCount}, 25.625rem);
          grid-gap: 2rem;
          padding: 0.25rem 1rem 0.375rem 0.25rem;
        `}

        ${media.laptop`
          grid-template-columns: repeat(${childrenCount}, 28.6875rem);
          padding: 0.25rem 1rem 0.5rem 0.25rem;
        `}
        
        @media (min-width: 1200px) {
          margin-left: 0;
        }
      `
    }}

    li {
      ${TeaserCardItemCss}
    }

    ${props =>
      props.isPlaceholderTeaser &&
      css`
        li span {
          height: 100%;
          span {
            padding-top: 0;
          }
        }
      `};
  }
`

const ScrollListWrapper = styled.div`
  margin-bottom: 3rem;
`

const cardStyles = css`
  display: block;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  box-shadow: 0.25rem 0.25rem 0 0 ${props => props.theme.DirtyWhiteDarker};

  ${media.tabletL`
    border-radius: 2rem;
    box-shadow: 0.375rem 0.375rem 0 0 ${props => props.theme.DirtyWhiteDarker};
  `}

  ${media.laptop`
    box-shadow: 0.5rem 0.5rem 0 0 ${props => props.theme.DirtyWhiteDarker};
  `}
`

const TeaserCard = styled.div<{ backgroundImage?: string }>`
  cursor: pointer;
  background: ${props =>
    props.backgroundImage
      ? `url(${props.backgroundImage}), ${props.theme.MetaNavBackground}`
      : props.theme.MetaNavBackground};
  ${cardStyles}

  img {
    border-radius: 1.5rem;
  }

  ${media.tabletL`
    img {
      border-radius: 2rem;
    }
  `}
`

const StyledPlaceholderCard = styled(PlaceholderCard)`
  cursor: auto;
  background: ${props => props.theme.MetaNavBackground};
  ${cardStyles}
`

const linkStyles = css`
  border-radius: 1.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${media.tabletL`
    border-radius: 2rem;
  `}
  ${FocusStyles};
`

const StyledLink = styled(Link)`
  ${linkStyles};
`

const StyledAnchor = styled.a`
  ${linkStyles};
`

const teaserSchema = v
  .object({
    teaserSlider: v
      .object({
        entries: v.array(
          v.object({
            referenceTitle: v.string().optional(),
            title: v.string(),
            image: contentfulFile,
            link: v.string(),
            trackingId: v.string(),
          })
        ),
      })
      .optional(),
  })
  .optional()

export function Teaser({ tonieboxes, showAddTonieboxModal }: TeaserProps) {
  const isTunesPurchaseInShopEnabled = useFlag('isTunesPurchaseInShopEnabled')
  const [columns, setColumns] = useState(3)
  const { i18n, t } = useTranslation(['home', 'default'])

  const { data, error, isFetching } = useContentful({
    contentId: '5xYSSdKLt64gnhh3jyqBQq',
    schema: teaserSchema,
  })

  const allTeasers = data?.teaserSlider?.entries.map(
    ({ referenceTitle, image, title, link, trackingId }) => {
      return {
        referenceTitle,
        dataTrackingId: `home-teaserslider__teaser-press__${trackingId}`,
        link,
        src: image.file.url,
        alt: title,
        isTonieboxesLink: link === '/tonieboxes',
      }
    }
  )

  const tunesTeasers = isTunesPurchaseInShopEnabled
    ? allTeasers
    : allTeasers?.filter(
        teaser =>
          !teaser?.referenceTitle?.includes('linkToTunesPurchaseInShopTeaser')
      )

  useEffect(() => {
    function update() {
      const matchTablet = window.matchMedia(
        `(min-width: ${variables.screenTabletL}px)`
      ).matches
      const matchScreenMobileLarge = window.matchMedia(
        `(min-width: ${variables.screenMobileL}px)`
      ).matches

      setColumns(matchTablet ? 2.75 : matchScreenMobileLarge ? 2 : 1.35)
    }

    update()

    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  }, [])

  if (isFetching) {
    return (
      <Wrapper data-testid="tunes-home-teaser">
        <ScrollListWrapper aria-label={t('default:Loading')}>
          <StyledHorizontalScrollList
            isPlaceholderTeaser
            scrollByOne
            columns={columns}
          >
            <StyledPlaceholderCard />
            <StyledPlaceholderCard />
            <StyledPlaceholderCard />
            <StyledPlaceholderCard />
          </StyledHorizontalScrollList>
        </ScrollListWrapper>
      </Wrapper>
    )
  }

  if (error || !tunesTeasers || tunesTeasers.length === 0) {
    return null
  }

  return (
    <>
      <Wrapper data-testid="tunes-home-teaser">
        <ScrollListWrapper>
          <StyledHorizontalScrollList scrollByOne columns={columns}>
            {tunesTeasers.map(teaser => {
              function TeaserImage() {
                return (
                  <ImageV2
                    src={teaser.src}
                    alt={teaser.alt}
                    ratio={[27, 23]}
                    responsive={[279, 310, 341, 410, 459]}
                  />
                )
              }
              if (teaser.isTonieboxesLink) {
                // contentful doesn't know about the toniebox-setup teaser
                // render it in place of the toniebox teaser when there are no tonieboxes
                if (tonieboxes.length === 0) {
                  const registerTonieboxTeaser =
                    i18n.language === 'de'
                      ? registerTonieboxDE
                      : registerTonieboxEN
                  return (
                    <TeaserCard
                      data-trackingid={registerTonieboxTeaser.dataTrackingId}
                      onClick={showAddTonieboxModal}
                      key={registerTonieboxTeaser.alt}
                    >
                      <ImageV2
                        src={registerTonieboxTeaser.src}
                        alt={registerTonieboxTeaser.alt}
                        ratio={[27, 23]}
                        responsive={[279, 310, 341, 410, 459]}
                      />
                    </TeaserCard>
                  )
                }
                return (
                  <StyledLink
                    key={teaser.alt}
                    to={teaser.link}
                    data-trackingid={teaser.dataTrackingId}
                  >
                    <TeaserCard backgroundImage={teaser.src}>
                      <ImageV2
                        src={tonieboxes[0].imageUrl}
                        alt={teaser.alt}
                        ratio={[27, 23]}
                        responsive={[279, 310, 341, 410, 459]}
                      />
                    </TeaserCard>
                  </StyledLink>
                )
              }
              if (teaser.link.startsWith('http')) {
                return (
                  <StyledAnchor
                    key={teaser.link}
                    href={teaser.link}
                    data-trackingid={teaser.dataTrackingId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TeaserCard>
                      <TeaserImage />
                    </TeaserCard>
                  </StyledAnchor>
                )
              }
              return (
                <StyledLink
                  key={teaser.alt}
                  to={teaser.link}
                  data-trackingid={teaser.dataTrackingId}
                >
                  <TeaserCard>
                    <TeaserImage />
                  </TeaserCard>
                </StyledLink>
              )
            })}
          </StyledHorizontalScrollList>
        </ScrollListWrapper>
      </Wrapper>
    </>
  )
}
