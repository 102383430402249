import React from 'react'
import styled from 'styled-components'
import {
  Button,
  Brand,
  ContactLink,
  FooterMetaNavigation,
  FooterMetaNavigationItem,
  Footer,
  LegalNavigation,
  LegalNavigationLink,
  OpenLanguageSwitchButton,
  useEnvLink,
  variables,
  TwitterIcon,
  YouTubeIcon,
  PinterestIcon,
  InstagramIcon,
  FacebookIcon,
  GeoIpRegions,
} from '@boxine/tonies-ui'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import * as v from '@badrap/valita'
import CopyrightVersionInfo from '../CopyrightVersionInfo'
import { useSupportedLanguages } from '../../hooks/useSupportedLanguages'
import { useContentful } from '../../contentful'
import { zendeskLinks } from '../../zendesk'
import { useUserProfile } from '../../providers/user-profile'
import { useFlag } from '../../providers/feature-flags'

const StyledButton = styled(Button)`
  margin-top: -0.4rem;
  margin-left: 1rem;
  padding: 0.3rem 0.875rem !important;

  @media (max-width: ${variables.screenTabletL}px) {
    margin-left: 0;
  }
`

const footerSchema = v.object({
  socialMediaChannels: v.array(
    v.object({
      type: v.union(
        v.literal('pinterest'),
        v.literal('facebook'),
        v.literal('instagram'),
        v.literal('twitter'),
        v.literal('youtube')
      ),
      link: v.string(),
    })
  ),
})

const socialMediaIcons = {
  pinterest: PinterestIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
  youtube: YouTubeIcon,
}

// used to be in POEditor, but nothing gets translated, to we inlined it
const socialMediaTitles = {
  pinterest: 'Pinterest',
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  youtube: 'YouTube',
}

interface MyToniesFooterProps {
  idLanguageSwitch?: string
  isLanguageSwitchOpen: boolean
  onToggleLanguageSwitch: (wasTriggeredByKeyboard: boolean) => void
}

export const MyToniesFooter = ({
  idLanguageSwitch,
  isLanguageSwitchOpen,
  onToggleLanguageSwitch,
}: MyToniesFooterProps) => {
  const { t } = useTranslation()
  const { region } = useUserProfile()
  const isLegalPage = Boolean(useRouteMatch('/legals/:page'))

  const vulnerabilityDisclosurePolicyPageLink =
    'https://tonies.com/en-gb/vulnerability-disclosure-policy/'

  const europeanDigitalServicesActLink = `https://tonies.com/en-gb/european-digital-services-act/`

  const showFeedbackButton = /^(dev|stage|qa)$/.test(
    process.env.REACT_APP_ENVIRONMENT || ''
  )

  const { data, error, isFetching } = useContentful({
    contentId: '63CNczMt0Evzh0Hi2azyzZ',
    schema: footerSchema,
  })

  const isShowCookiePageSettingsOnFooter = useFlag(
    'showCookiePageSettingsOnFooter'
  )

  const websiteLink = useEnvLink(
    'websiteLink',
    process.env.REACT_APP_ENVIRONMENT,
    // TODO: refactor `useEnvlink`
    region.toUpperCase() as GeoIpRegions
  )

  const retailerLink = useEnvLink(
    'retailerLink',
    process.env.REACT_APP_ENVIRONMENT,
    // TODO: refactor `useEnvlink`
    region.toUpperCase() as GeoIpRegions
  )

  const supportedLanguages = useSupportedLanguages()
  const currentLanguageObject = supportedLanguages.find(
    language => language.isActive
  )
  const currentLanguageText = currentLanguageObject
    ? currentLanguageObject.text
    : t('en')

  if (isLegalPage) {
    return null
  }

  if (isFetching) {
    return null
  }

  const isRegionWithRetailerPortal = region === 'dach' || region === 'uki'

  const socialMediaLinks =
    isFetching || error || !data
      ? []
      : data.socialMediaChannels.map(channel => ({
          component: socialMediaIcons[channel.type],
          to: channel.link,
          title: socialMediaTitles[channel.type],
          dataTrackingId: `footer__social-icons__${channel.type}`,
        }))

  return (
    <Footer
      dataTestId="footer"
      topLinks={
        <>
          <ContactLink to={zendeskLinks().newTicket}>
            {t('default:FooterContact')}
          </ContactLink>
          <OpenLanguageSwitchButton
            currentLanguage={currentLanguageText}
            dataTestId="footer-current-language"
            idLanguageSwitch={idLanguageSwitch}
            isOpen={isLanguageSwitchOpen}
            isInverted={true}
            onClick={onToggleLanguageSwitch}
          />
        </>
      }
      copyright={<CopyrightVersionInfo />}
      metaNavigation={
        <FooterMetaNavigation>
          <FooterMetaNavigationItem to={websiteLink}>
            {t('default:FooterTonies')}
          </FooterMetaNavigationItem>
          <FooterMetaNavigationItem to="/" isActive>
            <Brand />
          </FooterMetaNavigationItem>
          <FooterMetaNavigationItem to={zendeskLinks().home}>
            {t('default:FooterSupport')}
          </FooterMetaNavigationItem>

          {isRegionWithRetailerPortal && (
            <FooterMetaNavigationItem to={retailerLink}>
              {t('default:FooterRetailer')}
            </FooterMetaNavigationItem>
          )}
        </FooterMetaNavigation>
      }
      legalNavigation={
        <LegalNavigation>
          <LegalNavigationLink to="/legals/imprint">
            {t('default:FooterImprint')}
          </LegalNavigationLink>
          <LegalNavigationLink to="/legals/terms-of-service">
            {t('default:FooterTerms')}
          </LegalNavigationLink>
          <LegalNavigationLink to="/legals/privacy-policy">
            {t('default:FooterDataProtection')}
          </LegalNavigationLink>
          {isShowCookiePageSettingsOnFooter && (
            <LegalNavigationLink to="/cookie-settings">
              {t('default:FooterCookieSettings')}
            </LegalNavigationLink>
          )}
          <LegalNavigationLink to={vulnerabilityDisclosurePolicyPageLink}>
            {t('default:FooterVulnerabilityDisclosurePolicy')}
          </LegalNavigationLink>
          <LegalNavigationLink to={europeanDigitalServicesActLink}>
            {t('default:FooterEuropeanDigitalServicesAct')}
          </LegalNavigationLink>
        </LegalNavigation>
      }
      socialMediaLinks={socialMediaLinks}
      feedback={
        showFeedbackButton && (
          <StyledButton
            forwardedAs="a"
            title="This button is not visible on production."
            target="_blank"
            rel="noopener noreferrer"
            href="/jira.html"
          >
            Feedback
          </StyledButton>
        )
      }
    />
  )
}
