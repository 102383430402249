import { Spacing, media } from '@boxine/tonies-ui'
import React from 'react'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Skeleton } from '../../components/Skeleton'
import { InnerContainer } from '../../layouts/LayoutV2'

const ColumnLayout = styled.div`
  ${media.tabletL`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
  `}
`

export function FAQPlaceholder() {
  const theme = useContext(ThemeContext)
  return (
    <Spacing pt="spacing-xxl" pb="spacing-xxl">
      <InnerContainer>
        <ColumnLayout>
          <div />
          <div>
            <Spacing mb="spacing-s">
              <Skeleton
                height={32}
                width="60%"
                baseColor={theme.colors['anthracite-20']}
              />
            </Spacing>
            <Spacing mb="spacing-l">
              <Skeleton height={20} baseColor={theme.colors['anthracite-20']} />
            </Spacing>

            <Skeleton height={400} baseColor={theme.colors['anthracite-20']} />
          </div>
        </ColumnLayout>
      </InnerContainer>
    </Spacing>
  )
}
