import React, { forwardRef } from 'react'
import {
  GeoIpRegions,
  MetaNavigation,
  MetaNavigationLanguageWrapper,
  MetaNavigationLinkExternal,
  MetaNavigationLinkInternal,
  OpenLanguageSwitchButton,
  useEnvLink,
} from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { useSupportedLanguages } from '../../hooks/useSupportedLanguages'
import { zendeskLinks } from '../../zendesk'
import { useUserProfile } from '../../providers/user-profile'

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
`

interface MetaNavHeaderProps {
  idLanguageSwitch?: string
  isLanguageSwitchOpen: boolean
  onToggleLanguageSwitch: (wasTriggeredByKeyboard: boolean) => void
}

export const MetaNavHeader = forwardRef<HTMLDivElement, MetaNavHeaderProps>(
  function InnerMetaNavHeader(
    { idLanguageSwitch, isLanguageSwitchOpen, onToggleLanguageSwitch },
    ref
  ) {
    const { t } = useTranslation()
    const { region } = useUserProfile()
    const isChooseLanguagePageActive = Boolean(
      useRouteMatch('/choose-language')
    )
    const supportedLanguages = useSupportedLanguages()
    const currentLanguageObject = supportedLanguages.find(
      language => language.isActive
    )
    const currentLanguageText = currentLanguageObject
      ? currentLanguageObject.text
      : t('en')

    const websiteLink = useEnvLink(
      'websiteLink',
      process.env.REACT_APP_ENVIRONMENT,
      region.toUpperCase() as GeoIpRegions
    )

    const retailerLink = useEnvLink(
      'retailerLink',
      process.env.REACT_APP_ENVIRONMENT,
      region.toUpperCase() as GeoIpRegions
    )

    const isRegionWithRetailerPortal = region === 'dach' || region === 'uki'

    return (
      <Wrapper>
        <MetaNavigation dataTestId="meta-navigation" ref={ref}>
          <MetaNavigationLinkExternal href={websiteLink}>
            {t('default:FooterTonies')}
          </MetaNavigationLinkExternal>
          <MetaNavigationLinkInternal
            to="/"
            isActive={!isChooseLanguagePageActive}
          >
            <Trans i18nKey="default:mytonies">
              <strong>my</strong>tonies
            </Trans>
          </MetaNavigationLinkInternal>
          <MetaNavigationLinkExternal href={zendeskLinks().home}>
            {t('default:FooterSupport')}
          </MetaNavigationLinkExternal>

          {isRegionWithRetailerPortal && (
            <MetaNavigationLinkExternal href={retailerLink}>
              {t('default:FooterRetailer')}
            </MetaNavigationLinkExternal>
          )}

          <MetaNavigationLanguageWrapper>
            <OpenLanguageSwitchButton
              currentLanguage={currentLanguageText}
              dataTestId="meta-navigation-current-language"
              idLanguageSwitch={idLanguageSwitch}
              isOpen={isLanguageSwitchOpen}
              onClick={onToggleLanguageSwitch}
            />
          </MetaNavigationLanguageWrapper>
        </MetaNavigation>
      </Wrapper>
    )
  }
)
