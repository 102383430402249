import { theme, themeHelpers } from '@boxine/tonies-ui'

export const { media } = themeHelpers
export default {
  ...theme,
  GreenLight: '#eff0dc',
  Pink10: '#fbebf1',
  application: {
    ...theme.application,
    backgroundColor: '#ECEDEE',
    color: '#333333',
    white: '#fff',
    // in Zeplin: #gruen-40
    lockedShadowColor: '#dfe1b9',
    loadingSpinnerHeight: 60,
  },
  navigation: {
    maxWidth: theme.application.maxContentWidth,
  },
  footer: {
    maxWidth: theme.application.maxContentWidth,
  },
}
