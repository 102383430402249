import { themeHelpers } from '@boxine/tonies-ui'
import styled from 'styled-components'

const { media } = themeHelpers

/**
 * @deprecated use 'footer' prop of Modal
 */
const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 8px;
  align-items: center;

  button {
    margin-right: 1rem;
  }

  button:last-child {
    margin-right: 0;
  }

  ${media.tabletL`
    flex-direction: row;
  `}
`

export default ModalFooter
