import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { ConfigContext } from '../providers/config'

function isActive(currentLanguageCode, locale) {
  let normalizedCurrentLanguageCode = currentLanguageCode

  if (currentLanguageCode === 'de-DE') {
    normalizedCurrentLanguageCode = 'de'
  } else if (currentLanguageCode === 'en-GB') {
    normalizedCurrentLanguageCode = 'en'
  } else if (currentLanguageCode === 'fr-FR') {
    normalizedCurrentLanguageCode = 'fr'
  }

  return normalizedCurrentLanguageCode === locale
}

export function useSupportedLanguages() {
  const { i18n, t } = useTranslation()

  const { unicodeLocales } = useContext(ConfigContext)

  // Result from GET /config request might not be there yet
  if (unicodeLocales) {
    const translations = {
      de: t('default:de'),
      en: t('default:en'),
      'en-US': t('default:en-US'),
      fr: t('default:fr'),
    }
    return unicodeLocales.map(locale => ({
      isActive: isActive(i18n.language, locale),
      languageCode: locale,
      text: translations[locale],
    }))
  }

  return []
}
