import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { cloudinaryUrl, ImageV2Ctx } from '@boxine/tonies-ui'
import App from './App'
import ErrorBoundary from './features/ErrorBoundary'
import { AuthProvider } from './providers/auth'
import { FeatureFlagProvider, useFlag } from './providers/feature-flags'
import { newConfigCatAdapter } from './providers/feature-flags/adapter/configcat'
import { newNoopAdapter } from './providers/feature-flags/adapter/noop'
import { ConfigProvider } from './providers/config'
import { HeaderFooterSwitchProvider } from './providers/HeaderFooterSwitch'
import { UserProfileProvider } from './providers/user-profile'
import { UtmProvider } from './providers/utm/UtmProvider'

const isTestEnv = process.env.NODE_ENV === 'test'
const configCat = isTestEnv
  ? newNoopAdapter()
  : newConfigCatAdapter(
      process.env.REACT_APP_CONFIGCAT_ID ||
        // default to local/dev env
        'a1XYCMubQ0SGnRqJotF5DQ/0HQ8EZ-IP0ipYCebolnTKg'
    )

const fallbackBackend = ({ src }) => src

// eslint-disable-next-line react/prop-types
function ImageCtxProvider({ children }) {
  const isCloudinaryImagesEnabled = useFlag('cloudinaryImages')
  return (
    <ImageV2Ctx.Provider
      value={{
        backend: isCloudinaryImagesEnabled ? cloudinaryUrl : fallbackBackend,
      }}
    >
      {children}
    </ImageV2Ctx.Provider>
  )
}

const baseName = {}
/**
 * App Shell Component
 */
const Shell = () => {
  return (
    <Router {...baseName}>
      <ConfigProvider>
        <FeatureFlagProvider
          adapter={configCat}
          defaultValues={{
            contentfulIntegration: true,
          }}
        >
          <AuthProvider>
            <ErrorBoundary>
              <ImageCtxProvider>
                <HeaderFooterSwitchProvider>
                  <UserProfileProvider>
                    <UtmProvider>
                      <App />
                    </UtmProvider>
                  </UserProfileProvider>
                </HeaderFooterSwitchProvider>
              </ImageCtxProvider>
            </ErrorBoundary>
          </AuthProvider>
        </FeatureFlagProvider>
      </ConfigProvider>
    </Router>
  )
}

export default Shell
