import { Headline3, Headline4, Box } from '@boxine/tonies-ui'
import React from 'react'
import styled from 'styled-components'
import { themeHelpers } from '@boxine/tonies-ui'
import { Skeleton } from '../Skeleton'
import theme from '../../theme'
const { media } = themeHelpers

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin-left: 1rem;
  margin-right: 1rem;

  ${media.tabletL`
    margin-left: 0;
    margin-right: 0;
  `}
`

const FullStyledBox = styled(StyledBox)`
  ${media.laptop`
    grid-column: span 2;
  `}
`

const FakeCTWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme.Gray};
  text-decoration: none;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 0.1875rem;
`

const FakeCToniesWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  ${media.tabletL`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${media.laptop`
    grid-template-columns: repeat(4, 1fr);
  `};
`

const FakeTonieboxesWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  ${media.tabletL`
    grid-template-columns: repeat(2, 1fr);
  `};
`

export const HomeCreativeTonieBoxPreview = function () {
  return (
    <FullStyledBox>
      <Headline4>
        <Skeleton width={200} />
      </Headline4>
      <FakeCToniesWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
      </FakeCToniesWrapper>
      <Headline4>
        <Skeleton height={45} width={200} />
      </Headline4>
    </FullStyledBox>
  )
}

export const HomeTonieboxesPreview = function () {
  return (
    <StyledBox>
      <Headline4>
        <Skeleton width={200} />
      </Headline4>
      <FakeTonieboxesWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
        <FakeCTWrapper>
          <Skeleton circle={true} height={50} width={50} />
        </FakeCTWrapper>
      </FakeTonieboxesWrapper>
      <Headline4>
        <Skeleton height={45} width={200} />
      </Headline4>
    </StyledBox>
  )
}

export const HomeBoxPreview = function () {
  return (
    <StyledBox>
      <Headline4>
        <Skeleton height={40} width={200} />
      </Headline4>
      <Headline3>
        <Skeleton width={130} />
      </Headline3>
      <Headline4 style={{ marginBottom: 0, marginTop: '.5em' }}>
        <Skeleton height={45} width={200} />
      </Headline4>
    </StyledBox>
  )
}
