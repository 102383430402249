import * as v from '@badrap/valita'
import { TopLevelBlock } from '@contentful/rich-text-types'

export const contentfulFile = v.object({
  title: v.string(),
  file: v.object({
    url: v.string(),
    contentType: v.string().optional(),
  }),
})

export const contentfulDocument = v.object({
  nodeType: v.literal('document'),
  // We could be more specific here, but it's good enough
  content: v
    .unknown()
    .assert((value): value is TopLevelBlock[] => Array.isArray(value)),
  data: v.object({}),
})
