import { useMemo, useRef } from 'react'

/**
 * Check if two objects are shallowly equal
 */
function shallowEqual<Type extends object>(a: Type, b: Type) {
  for (const i in a) if (!(i in b)) return false
  for (const i in b) if (a[i] !== b[i]) return false
  return true
}

/**
 * Memoize an object and only return a new reference when the new object
 * is not shallow equal to the previous one
 */
export function useShallowMemo<T extends object>(obj: T): T {
  const last = useRef(obj)

  return useMemo(() => {
    const shouldUpdate = !shallowEqual(last.current, obj)
    if (shouldUpdate) last.current = obj
    return last.current
  }, [obj])
}

/**
 * Simple event emitter to dispatch events to various listeners
 */
export function newEmitter<T = any>() {
  const handlers = new Map<string, Set<(value: T) => void>>()

  return {
    trigger(event: string, value: T) {
      const fns = handlers.get(event)
      if (fns) {
        fns.forEach(fn => fn(value))
      }
    },
    on: (event: string, fn: (value: T) => void) => {
      if (!handlers.has(event)) {
        handlers.set(event, new Set())
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      handlers.get(event)!.add(fn)
    },
    off: (event: string, fn: (value: T) => void) => {
      const fns = handlers.get(event)
      if (fns) fns.delete(fn)
    },
  }
}
