import React from 'react'
import { Headline1, media, Paragraph, Spacing } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'

const Wrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 18rem;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.serif};
  line-height: 1.38rem;

  ${media.mobileL`
      width: 21rem;
    `}

  ${media.tabletL`
      width: 28rem;
      font-size: 1.25rem;
      line-height: 1.57rem;
    `}

    ${media.laptop`
      width: 38rem;
    `}
`

const StyledHeadline = styled(Headline1)`
  font-size: 1.5rem;

  ${media.laptop`
    font-size: 2rem;
  `}
`

const StyledText = styled(Paragraph)`
  text-align: center;
  font-size: 1rem;

  ${media.laptop`
    font-size: 1.25rem;
  `}
`
interface DashboardGreetingProps {
  firstName?: string
}

export function DashboardGreeting({ firstName }: DashboardGreetingProps) {
  const { canBuyTunes, canGetAudioCandy } = useIsAudioLibraryAvailable()
  const { t } = useTranslation(['home'])

  return (
    <Wrapper data-testid="welcome-message">
      <Spacing mb="spacing-xxs">
        <StyledHeadline>
          {firstName
            ? t('WelcomeMessageHeadline', { firstName })
            : t('WelcomeMessageHeadlineNoName')}
        </StyledHeadline>
      </Spacing>

      <Spacing my="spacing-xs">
        <StyledText>
          {canBuyTunes || canGetAudioCandy ? (
            t('home:WelcomeMessageText')
          ) : (
            <Trans
              i18nKey="home:UserGreetingHeadline"
              components={{
                strong: <strong />,
              }}
            />
          )}
        </StyledText>
      </Spacing>
    </Wrapper>
  )
}
