import at from 'lodash/at'
import has from 'lodash/has'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import indexOf from 'lodash/indexOf'
import { MEMBER_ACCESS_RIGHTS, CREATIVE_TONIE_PERMISSIONS } from '../../globals'

// Only used in here
// TODO: Remove this function safely
export function getErrorType(error: { response?: any; request?: any }) {
  let type = 'unknown'
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (!has(error, 'response.data.errors')) {
      type = 'plain'
      return type
    }
    if (Array.isArray(at(error, 'response.data.errors'))) {
      type = 'list'
      return type
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log({ message: 'SPA Network Error' })
    type = 'network'
    return type
  } else {
    // Something happened in setting up the request that triggered an Error
    return type
  }
}

/**
 * Returns the errors of a response error if they exists
 * Only used in src/providers/config/index.tsx
 * TODO: Remove this function safely
 * @param {Object} error - Error object of server response
 * @returns {Array} - Response errors
 */
export function getErrors({ error }) {
  const errorType = getErrorType(error)
  switch (errorType) {
    case 'list':
      return head(at(error, 'response.data.errors'))
    case 'plain':
      const empty = isEmpty(head(at(error, 'response.data')))
      return empty ? undefined : at(error, 'response.data')
    case 'network':
      return [{ code: 'networkError' }]
    default:
    case 'unknown':
      return [{ code: 'unknownClientError' }]
  }
}

/**
 * Maps an Array of households to an array of name, value pairs.
 * Use this function if you want to display a list of all households in a select field.
 * @param {Array<{ displayName?: string name?: string id: string }>} households - Array of households
 * @returns {Array<{ label: string | undefined; value: string; key: string }>} - Array of name, value pairs
 */
export function mapHouseholdsToOptions(
  households: Array<{
    displayName?: string
    name?: string
    id: string
  }>
): Array<{ label: string | undefined; value: string; key: string }> {
  return households.map(({ displayName, name, id }) => ({
    label: displayName || name,
    value: id,
    key: id,
  }))
}

/**
 * Returns true if the user has owner access to a household
 * @param household
 * @returns {boolean}
 */
export function hasOwnerAccess(household: { access: any }): boolean {
  return household.access === MEMBER_ACCESS_RIGHTS.owner
}
/**
 * Returns true if the user has full access to a household
 * @param household
 * @returns {boolean}
 */
export function hasFullAccess(household: { access: any }): boolean {
  return household.access === MEMBER_ACCESS_RIGHTS.full
}
/**
 * Returns true if the user has limited access to a household
 * @param household
 * @returns {boolean}
 */
export function hasLimitedAccess(household): boolean {
  return household.access === MEMBER_ACCESS_RIGHTS.limited
}
/**
 * Returns true if the user is owner or has full access for the household
 * @param {object} household
 * @returns {boolean}
 */
export function hasOwnerOrFullAccess(household: {
  access: string | undefined
}): boolean {
  return hasOwnerAccess(household) || hasFullAccess(household)
}

/**
 * Returns true if the user is the owner of a household
 * @param member {{ mtype?: string }}
 * @returns {boolean}
 */
export function isOwner(member: { mtype?: string }): boolean {
  return member.mtype === MEMBER_ACCESS_RIGHTS.owner
}

/**
 * Returns true if the user is a superuser (full access) in a household
 * @param model {{ mtype?: string; itype?: string }} Membership or invitation
 * @returns {boolean}
 */
export function isFull(model: { mtype?: string; itype?: string }) {
  return (
    model.mtype === MEMBER_ACCESS_RIGHTS.full ||
    model.itype === MEMBER_ACCESS_RIGHTS.full
  )
}

/**
 * Returns true if the user is a member (limited access) in a household
 * @param model {{ mtype?: string; itype?: string }} Membership or invitation
 * @returns {boolean}
 */
export function isLimited(model: { mtype?: string; itype?: string }): boolean {
  return (
    model.mtype === MEMBER_ACCESS_RIGHTS.limited ||
    model.itype === MEMBER_ACCESS_RIGHTS.limited
  )
}

/**
 * Returns true if the user will be a member(limited access) in a household
 * @param permission
 * @returns {boolean}
 */
export function canEditCreativeTonie(permission: {
  permission: string
}): boolean {
  return permission.permission !== CREATIVE_TONIE_PERMISSIONS.none
}

/**
 * Returns true if the user is a full member or the owner
 * @param permission {{ permission: string}} Permission object
 * @returns {boolean}
 */
export function isImplicitPermission(permission: {
  permission: string
}): boolean {
  return permission.permission === CREATIVE_TONIE_PERMISSIONS.implicit
}

/**
 * Parses seconds into hh:mm:ss
 * @param seconds {number}
 */
export function humanReadableTime(seconds: number) {
  if (!seconds) {
    return ''
  }
  //@ts-ignore - Date constructor does not accept null
  const date = new Date(null)
  date.setSeconds(seconds)
  return date.toISOString().substr(11, 8)
}

/**
 * Returns select options
 * @param keys {Array<string}
 * @param labels {Array<string}
 * @returns {}
 */
export function createSelectOptions(
  keys: Array<string>,
  labels: Array<string>
): Array<{
  label: string
  value: string
  key: string
}> {
  return keys.map((key, index) => {
    return {
      label: labels[index],
      value: key,
      key: key,
    }
  })
}

// Only used in src/features/setup/steps/setup-wifi.tsx
// TODO: Remove this function safely
export function getConnectWifiUrl({ apiUrl }) {
  const m = /^https:\/\/api(\.[a-z0-9]+\.tonie\.cloud)/.exec(apiUrl)
  if (m) {
    return `http://setup${m[1]}/connect-wifi`
  }

  return 'http://www.toniebox-setup.com/connect-wifi'
}

/**
 * Checks if touch device.
 * https://stackoverflow.com/a/4819886/3116810
 * Only used in src/features/creative-tonie-detail-page/chapter.js
 * TODO: Remove this function safely
 * @param {Array<unknown>} exceptions
 * @returns {boolean}
 */
export function isTouchDevice(exceptions: Array<unknown> = []): boolean {
  if (Array.isArray(exceptions) && exceptions.length > 0) {
    const filter = new RegExp(exceptions.join('|'))
    //@ts-ignore - MSStream is not defined in all browsers
    if (filter.test(navigator.userAgent) && !window.MSStream) {
      return false
    }
  }

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

// Only used in src/components/toniebox-id-input/index.js
// TODO: Remove this function safely
export function isIOSDevice(ua: string) {
  //@ts-ignore - MSStream is not defined in all browsers
  return /iPad|iPhone|iPod/.test(ua) && !window.MSStream
}

/**
 * Inserts or updates an array item
 * Only used in src/components/upload-zone/components/upload-zone.js
 * TODO: Remove this function safely
 * @param arr {Array<unknown>} - The Array to mutate
 * @param key {Record<string, unknown>} - The key to search for
 * @param newVal {Record<string, unknown>} - The updated or new value
 */
export function insertOrUpdate(
  arr: Array<unknown>,
  key: Record<string, unknown>,
  newVal: Record<string, unknown>
) {
  const match = find(arr, key)
  if (match) {
    const index = indexOf(arr, find(arr, key))
    arr.splice(index, 1, newVal)
  } else {
    arr.push(newVal)
  }
}

/**
 * Returns shortened text concatenated with `...` if the text is too long.
 * @param {String} text
 * @param {Number} maxLength
 * @returns {String}
 */
export function ellipsify(text: string, maxLength: number) {
  if (text.trim().length > maxLength)
    return text.substr(0, maxLength - 1).trim() + '…'
  return text
}

export function generateHouseholdsDisplayName<
  T extends { name: string; id: string; ownerName: string }
>(households: Array<T>) {
  // Remap households to add displayName
  return households.reduce(
    (result: Array<T & { displayName: string }>, current: T) => {
      const hasDuplicate = result.every(
        other => current.name === other.name && current.id !== other.id
      )

      // Add the owner if household name is a duplicate
      if (hasDuplicate && current.ownerName) {
        return [
          ...result,
          { ...current, displayName: `${current.name} (${current.ownerName})` },
        ]
      }
      return [...result, { ...current, displayName: current.name }]
    },
    []
  )
}

/**
 * Get a "super" unique id to track every unique add to cart event
 * @returns string
 */
export function generateUniqueId() {
  return (
    Date.now() +
    '_' +
    Math.random().toString(36).substring(2, 11) +
    Math.random().toString(36).substring(2, 11)
  )
}

/**
 * Checks if an URL string of same origin
 * @param {string} url URL to check
 * @returns boolean
 */
export function isSameOrigin(url: string) {
  return !/^https?:\/\//.test(url) || url.includes(window.origin)
}

/**
 * Returns true if production
 * @returns {boolean} true if production
 */
export function isProd(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === 'prod'
}

/**
 * Returns true if development environment
 * @returns {boolean} true if 'dev' is the environment
 */
export function isDev(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === 'dev'
}

/**
 * Returns `tonies.com` if production or tonie.cloud as fallback
 * @returns {'tonies.com' | 'tonie.cloud'} Domain tonies.com or tonie.cloud
 */
export function getDomain(): 'tonies.com' | 'tonie.cloud' {
  return process.env.REACT_APP_ENVIRONMENT === 'prod'
    ? 'tonies.com'
    : 'tonie.cloud'
}

/**
 * Returns true if it is a testing environment
 * @returns {boolean} true if it is a testing environment
 */
export function isAutomatedTest(): boolean {
  return window.navigator.webdriver || process.env.NODE_ENV === 'test' // Puppeteer || Jest
}

/**
 * Returns true if local environment
 * @returns {boolean} true if local environment
 */
export function isLocalEnvironment(): boolean {
  const { hostname } = window.location

  return ['my.local.tonie.cloud', 'localhost', '127.0.0.1', '::1', ''].includes(
    hostname
  )
}
