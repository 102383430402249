import React from 'react'
import { Input } from '@boxine/tonies-ui'
import { isIOSDevice } from '../../utils/functions/functions'

function countChar(s, c, from, to) {
  let res = 0
  for (let i = from; i < to; i++) {
    if (s[i] === c) res++
  }
  return res
}

export default class TonieboxIdInput extends React.Component {
  constructor(props) {
    super(props)
    const raw = props.value
    const formatted = this.format(raw)

    this.state = {
      raw,
      formatted,
      selectionStart: formatted.length,
      selectionEnd: formatted.length,
    }
    this.inputRef = React.createRef()
  }

  setSelectionRange(selectionStart, selectionEnd) {
    const input = this.inputRef.current
    if (/SamsungBrowser/.test(navigator.userAgent)) {
      setTimeout(() => input.setSelectionRange(selectionStart, selectionEnd))
    } else {
      input.setSelectionRange(selectionStart, selectionEnd)
    }
  }

  format(value) {
    if (!value) return ''

    const v = value.toUpperCase().replace(/[^A-Z0-9]/g, '')
    return (
      v.slice(0, 3) +
      (v.length >= 3 ? '-' : '') +
      v.slice(3, 5) +
      (v.length >= 5 ? '-' : '') +
      v.slice(5, 8)
    )
  }

  onChange(ev) {
    const input = ev.target
    let raw = input.value

    if (
      /android/i.test(navigator.userAgent) &&
      raw + '-' === this.state.formatted
    ) {
      // Special behavior on Android soft keyboards: Backspace is not reported, so detect it
      // https://bugs.chromium.org/p/chromium/issues/detail?id=118639
      raw = raw.slice(0, -1)
    }

    const formatted = this.format(raw)
    let { selectionStart, selectionEnd } = input
    if (/SamsungBrowser/.test(navigator.userAgent)) {
      selectionStart = selectionEnd
    }

    if (
      selectionStart === selectionEnd &&
      (selectionStart === raw.length ||
        (selectionStart === raw.length - 1 && raw[raw.length - 1] === '-'))
    ) {
      // Added a dash (or two) and cursor at the end: move cursor to the new end
      selectionStart = selectionEnd = formatted.length
    } else if (
      selectionStart === selectionEnd &&
      countChar(formatted, '-', 0, selectionStart) ===
        countChar(raw, '-', 0, selectionStart) + 1
    ) {
      // Added a dash to the left of the cursor: skip that dash
      selectionStart = selectionEnd = selectionStart + 1
    }

    // Cursor on a dash and changed content: Skip that dash
    if (
      raw !== formatted &&
      selectionStart === selectionEnd &&
      formatted[selectionStart] === '-'
    ) {
      selectionStart = selectionEnd = selectionStart + 1
    }

    this.setState(
      {
        raw,
        formatted,
        selectionStart,
        selectionEnd,
      },
      () => {
        this.setSelectionRange(selectionStart, selectionEnd)
      }
    )

    ev.target.value = formatted

    this.props.onChange(ev)
  }

  onKeyDown(ev) {
    if (ev.ctrlKey || ev.altKey || ev.metaKey) return

    const input = ev.target
    // Only handle plain Backspace and Del, not if something is selected
    if (input.selectionEnd !== input.selectionStart) return

    const alterState = (raw, selectionStart, selectionEnd) =>
      this.setState(
        {
          raw,
          formatted: this.format(raw),
          selectionStart,
          selectionEnd,
        },
        () => {
          this.setSelectionRange(selectionStart, selectionEnd)
        }
      )

    if (ev.key === 'Backspace' || ev.keyCode === 8) {
      const v = input.value

      // was Backspace pressed and the deleted value was a dash ('-')
      if (v.slice(input.selectionStart - 1, input.selectionStart) === '-') {
        // then also delete the character behind of the dash
        const raw =
          v.slice(0, input.selectionStart - 2) + v.slice(input.selectionEnd)
        const selectionStart = input.selectionStart - 2
        const selectionEnd = input.selectionEnd - 2
        alterState(raw, selectionStart, selectionEnd)
        ev.preventDefault()
      }
    } else if (ev.key === 'Delete' || ev.keyCode === 46) {
      const v = input.value

      // was Delete pressed and the deleted value was a dash ('-')
      if (v.slice(input.selectionStart, input.selectionStart + 1) === '-') {
        // then also delete the character in front of the dash
        const raw =
          v.slice(0, input.selectionStart) + v.slice(input.selectionEnd + 2)
        const selectionStart = input.selectionStart + 1
        const selectionEnd = input.selectionEnd + 1
        alterState(raw, selectionStart, selectionEnd)
        ev.preventDefault()
      }
    }

    // On iOS, pressing the "-" twice generates – (en dash).
    // This can lead to all kinds of problems, so don't allow it here.
    // Dashes are inserted automatically anyways.
    if (isIOSDevice(navigator.userAgent) && ev.key === '-') {
      ev.preventDefault()
    }
  }

  render() {
    return (
      <Input
        className={this.props.className}
        dataTestId={this.props.dataTestId}
        isInvalid={this.props.invalid || this.props.isInvalid}
        label={this.props.label}
        message={this.props.message}
        name={this.props.name}
        onChange={ev => this.onChange(ev)}
        onKeyDown={ev => this.onKeyDown(ev)}
        placeholder={this.props.placeholder}
        ref={this.inputRef}
        value={this.state.formatted}
      />
    )
  }
}

TonieboxIdInput.propTypes = Input.propTypes
