/**
 * Selects the series name for a given tune. Prefers series.group.name over series.name.
 *
 * @param tune - The tune object containing series and title information.
 * @returns An object with the key and value representing the selected series name.
 *          Returns null if no series name is found.
 */
export function selectTunesSeriesName(tune: {
  series?: {
    name: string
    group?: {
      name: string
    } | null
  }
}) {
  if (tune.series && tune.series.group) {
    return {
      key: 'series-group-name',
      value: tune.series.group.name,
    }
  }

  if (tune.series && tune.series.name) {
    return {
      key: 'series-name',
      value: tune.series.name,
    }
  }

  return null
}
