import React from 'react'
import styled from 'styled-components'

const AppContainerV1 = styled.div`
  margin: 1em auto 1em auto;
  padding: 0 1rem;
  width: 100%;
  max-width: ${props => props.theme.application.maxContentWidth};
  flex: 1;
  main {
    margin-top: 1em;
  }
`
interface Props {
  children: React.ReactNode
  className?: string
}

/**
 * @deprecated Please use LayoutV2 instead.
 */
export function LayoutV1({ children, className }: Props) {
  return (
    <AppContainerV1 id="app-container" className={className}>
      {children}
    </AppContainerV1>
  )
}
