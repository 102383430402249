import isEmail from 'validator/lib/isEmail'
/**
 * Checks if a value is missing
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isMissing = value => {
  return !(typeof value === 'string'
    ? value.trim()
    : typeof value === 'number'
    ? true
    : value)
}

/**
 * Checks if two values are the same
 * @param {String} value        Value to test
 * @param {String} test         Test value
 * @returns {boolean}
 */
export const validateEqual = (value, test) => {
  /**
   * Checks if value is a primitive value
   * @param test
   * @returns {boolean}
   */
  function isPrimitive(test) {
    return test !== Object(test)
  }

  function throwNoPrimitiveError() {
    throw new Error(`provided value is not a primitive value`)
  }

  if (!isPrimitive(value)) {
    throwNoPrimitiveError()
  }

  if (!isPrimitive(test)) {
    throwNoPrimitiveError()
  }

  if (value === null && test === null) {
    return false
  }
  return value !== test
}

export const validateMinLength = (value, length) => {
  const isEmpty = typeof value !== 'string' || value.length === 0

  return isEmpty ? false : value.length <= length - 1
}

/**
 * Checks for a valid email address
 * @param {String} value        Email
 * @returns {boolean}
 */
export const validateEmail = value => {
  const isEmpty = typeof value !== 'string' || value.length === 0

  return !isEmpty && value && !isEmail(value)
}

/**
 * Returns true if value is a valid email address
 * @param value
 * @returns {boolean}
 */
export const isValidEmail = value => !validateEmail(value)

/**
 * Validates multiple times with the same validation
 * @param {[{key, values}]} fields
 * @param {Function} validateFn
 * @returns {[{key, invalid}]}
 */
export const validateMultiple = (fields, validateFn) => {
  return fields.reduce((validation, field) => {
    const { key, values } = field
    validation.push({ key: key, invalid: validateFn(...values) })

    return validation
  }, [])
}

/**
 * Validates multiple times with the same validation
 * @param {[{values, message, validateFn, propertyName, condition}]} validations
 * @returns {[{key, invalid, message}]}
 */
export const validateChain = validations => {
  const [current, ...rest] = validations
  const { values, message, validateFn, propertyName, condition } = current
  const invalid = validateFn(...values)

  if (rest.length > 0 && invalid === condition) {
    return [{ key: propertyName, invalid, message }, ...validateChain(rest)]
  } else {
    return [{ key: propertyName, invalid, message }]
  }
}
