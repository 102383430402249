import { Location } from 'history'

export type AudioLibraryTab = 'shop' | 'my-purchases' | 'free-for-you'

export interface AudioLibraryUrlState {
  isForMyToniesActive: boolean
  ageFilter?: Set<string>
  characterFilter?: Set<string>
  search?: string
  activeTab: AudioLibraryTab
  webview?: string | null | undefined
  sortBy: string
  genreFilter?: Set<string>
  classificationFilter?: Set<string>
  languageFilter?: Set<string>
  tonieTypeFilter?: Set<string>
}

function urlHelper({
  isForMyToniesActive,
  ageFilter,
  characterFilter,
  activeTab,
  search,
  params,
  webview,
  sortBy,
  genreFilter,
  classificationFilter,
  languageFilter,
  tonieTypeFilter,
}: Partial<AudioLibraryUrlState> & { params: URLSearchParams }) {
  const tabSegment =
    activeTab === 'my-purchases'
      ? '/my-purchases'
      : activeTab === 'shop'
      ? '/shop'
      : '/free-for-you'

  if (search) {
    params.append('search', search)
  }
  if (webview) {
    params.append('webview', webview)
  }
  if (characterFilter && characterFilter.size > 0) {
    params.append('filter', Array.from(characterFilter).join(','))
  }
  if (ageFilter && ageFilter.size > 0) {
    params.append('ageFilter', Array.from(ageFilter).join(','))
  }
  if (isForMyToniesActive) {
    params.append('forMyTonies', 'true')
  }
  if (tonieTypeFilter && tonieTypeFilter.size > 0) {
    params.append('tonieType', Array.from(tonieTypeFilter).join(','))
  }
  if (sortBy && sortBy !== 'bestseller') {
    params.append('sortBy', sortBy)
  }
  if (genreFilter && genreFilter.size > 0) {
    params.append('genre', Array.from(genreFilter).join(','))
  }
  if (classificationFilter && classificationFilter.size > 0) {
    params.append('classification', Array.from(classificationFilter).join(','))
  }
  if (languageFilter && languageFilter.size > 0) {
    params.append('language', Array.from(languageFilter).join(','))
  }
  const searchSegment = params
    .toString()
    .replace(/%2C/g, ',')
    .replace(/%20/g, '+')

  return { tabSegment, searchSegment }
}

/**
 * Copy params from the current search string that should persist
 * across page navigation
 */
export function getPersistentParams(search: string) {
  // Name of params that we want to keep around
  const names = ['webview', 'relatedUrl']
  const current = new URLSearchParams(search)
  const params = new URLSearchParams()
  let count = 0
  names.forEach(name => {
    if (current.has(name)) {
      count++
      // TODO: Fix linting error
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      params.set(name, current.get(name)!)
    }
  })

  return {
    params,
    formattedSegment: count > 0 ? `&${params.toString()}` : '',
    formatted: count > 0 ? `?${params.toString()}` : '',
  }
}

export function buildUrl({
  isForMyToniesActive,
  tonieTypeFilter,
  ageFilter,
  characterFilter,
  activeTab,
  search,
  webview,
  sortBy,
  genreFilter,
  classificationFilter,
  languageFilter,
}: Partial<AudioLibraryUrlState>) {
  const params = new URLSearchParams()

  const { tabSegment, searchSegment } = urlHelper({
    isForMyToniesActive,
    tonieTypeFilter,
    ageFilter,
    characterFilter,
    activeTab,
    search,
    params,
    webview,
    sortBy,
    genreFilter,
    classificationFilter,
    languageFilter,
  })

  return `/audio-library${tabSegment}${
    searchSegment ? '?' + searchSegment : ''
  }`
}

export function createDetailPageLink(
  itemId: string,
  urlParams: AudioLibraryUrlState,
  collectionType = 'audio-library'
) {
  const params = new URLSearchParams()

  if (collectionType === 'audio-candy') {
    params.append(
      'relatedUrl',
      window.location.pathname + window.location.search
    )
  }

  const { tabSegment, searchSegment } = urlHelper({
    ...urlParams,
    params,
  })

  return `/${collectionType}/${itemId}${tabSegment}${
    searchSegment ? '?' + searchSegment : ''
  }`
}

export function detectAudioLibraryParams(
  location: Location
): AudioLibraryUrlState {
  const searchParams = new URLSearchParams(location.search)
  const search = searchParams.get('search') || ''

  const characterFilterString = searchParams.get('filter') || ''
  const characterFilter = new Set(
    characterFilterString.split(',').filter(Boolean)
  )

  const genreFilterString = searchParams.get('genre') || ''
  const genreFilter = new Set(genreFilterString.split(',').filter(Boolean))

  const tonieTypeFilterString = searchParams.get('tonieType') || ''
  const tonieTypeFilter = new Set(
    tonieTypeFilterString.split(',').filter(Boolean)
  )

  const classificationFilterString = searchParams.get('classification') || ''
  const classificationFilter = new Set(
    classificationFilterString.split(',').filter(Boolean)
  )

  const languageFilterString = searchParams.get('language') || ''
  const languageFilter = new Set(
    languageFilterString.split(',').filter(Boolean)
  )

  const sortBy = searchParams.get('sortBy') || ''

  // Age Filter
  const urlAges = searchParams.get('ageFilter')

  const ageFilter = new Set(
    (urlAges || '').split(',').filter(value => value !== '')
  )

  const isForMyToniesActive = !!searchParams.get('forMyTonies')

  let activeTab: AudioLibraryTab = 'shop'

  if (/\/(discover|shop)$/.test(location.pathname)) {
    activeTab = 'shop'
  } else if (/\/free-for-you$/.test(location.pathname)) {
    activeTab = 'free-for-you'
  } else if (/\/(my-collection|my-purchases)$/.test(location.pathname)) {
    activeTab = 'my-purchases'
  }

  const webview = searchParams.get('webview')

  return {
    activeTab,
    characterFilter,
    search,
    ageFilter,
    webview,
    sortBy,
    genreFilter,
    classificationFilter,
    languageFilter,
    isForMyToniesActive,
    tonieTypeFilter,
  }
}
