import React from 'react'
import { useTranslation } from 'react-i18next'
import { TagV2 as Tag, TagListV2 as TagList, media } from '@boxine/tonies-ui'
import styled from 'styled-components'

const FilterWrapper = styled.div`
  position: absolute;
  top: 3.25rem;
  left: 0;
  width: 100%;
  overflow: visible;

  > div {
    margin-bottom: 2rem;
  }

  ${media.tabletL`
    top: 3.75rem;
  `}

  ${media.laptop`
  all: unset;
  position: static;
  margin: 0 0 0 0.75rem;

  > div {
    margin-bottom: 0;
  }
  `}
`

interface householdFilterProps {
  households: Array<{
    id: string
    name: string
  }>
  value: string
  onChange: (value: string, event: Event) => void
  'data-trackingid': string
}

export function HouseholdFilter({
  households,
  value,
  onChange,
  'data-trackingid': dataTrackingId,
}: householdFilterProps) {
  const { t } = useTranslation(['default'])

  if (households.length <= 1) {
    return null
  }

  const tagContent = [{ id: '', name: t('default:All') }, ...households]

  return (
    <FilterWrapper>
      <TagList
        value={value}
        onChange={onChange}
        name="household-filter"
        spacingLeft="1rem"
      >
        {tagContent.map(({ id, name }) => (
          <Tag
            key={id}
            value={id}
            data-trackingid={dataTrackingId}
            data-testid={`household_filter_tag_${name}`}
          >
            {name}
          </Tag>
        ))}
      </TagList>
    </FilterWrapper>
  )
}
