import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { FlashMessage } from '@boxine/tonies-ui'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { http } from '../../http.service'
import { Text } from '../Typography'

const NoMarginText = styled(Text)`
  margin: 0;
`

export const SystemNotifications = () => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    ;(async () => {
      try {
        const { data } = await http.get('/system-notifications', {
          cancelToken: cancelToken.token,
        })
        setNotifications(data)
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }

        Sentry.addBreadcrumb({
          level: 'error',
          category: 'system-notification',
          message: e.message || '',
          data: e,
        })

        Sentry.captureException(e)
      }
    })()
    return () => {
      cancelToken.cancel('Cancel GET /system-notifications')
    }
  }, [])

  async function dismiss(id) {
    try {
      await http.delete(`/system-notifications/${id}`)
    } catch (e) {
      Sentry.addBreadcrumb({
        level: 'error',
        category: 'system-notification',
        message: e.message || '',
        data: e,
      })

      Sentry.captureException(e)
    }
  }

  return (
    <>
      {notifications.map(notification => {
        return (
          <FlashMessage
            type="info"
            key={notification.id}
            onWasClosed={dismiss.bind(this, notification.id)}
          >
            <NoMarginText>
              <span
                dangerouslySetInnerHTML={{
                  __html: notification.translatedText,
                }}
              />
            </NoMarginText>
          </FlashMessage>
        )
      })}
    </>
  )
}
