import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  media,
  CardHeaderLink,
  PreviewCardContainer,
  CardHeaderButton,
  useMedia,
  CardShared,
} from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'
import theme from '../../theme'
import {
  selectContentTonieDescription,
  selectContentTonieSeriesName,
} from '../../utils/tonie-utils'

// copied from @boxine/tonies-ui; can't import it directly,
// and we need to nest two elements inside of it
const CardMediaWrapper = styled.span`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-shrink: 1;
  height: 100%;
  color: ${props => props.theme.BrandPrimary};
  margin-bottom: 16%;
`

const CardImage = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`

const BubbleImage = styled.img`
  position: absolute;
  top: 10%;
  right: 4%;
  width: 30%;
  height: auto;
  max-width: 3.75rem;
`

const TuneBadgeContainer = styled.span`
  position: relative;
`

const TuneBadge = styled(Icon)`
  position: absolute;
  top: -1.5rem;
  left: 50%;
  margin-left: -0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${media.tabletL`
    top: -2.25rem;
    margin-left: -1rem;
    width: 2rem;
    height: 2rem;
  `}
`

interface ContentTonieCardProps {
  household: {
    access: string
    name: string
    id: string
  }
  showHousehold: boolean
  tonie: {
    id: string
    title: string
    lock: boolean
    series: {
      id: string
      name: string
      group?: {
        id: string
        name: string
      }
    }
    imageUrl: string
    tune?: {
      id: string
      item: {
        title: string
      }
    }
  }
  onLock: () => void
}

export default function ContentTonieCard({
  household,
  showHousehold,
  tonie,
  onLock,
}: ContentTonieCardProps) {
  const { t, i18n } = useTranslation(['content-tonies'])
  const [isLocked, setLocked] = useState(tonie.lock)
  const { isAudioLibraryAvailable } = useIsAudioLibraryAvailable()
  const isLaptopWidth = useMedia({ minWidth: theme.screenTabletL })
  const title = selectContentTonieSeriesName(tonie)
  const description = selectContentTonieDescription(tonie)
  const editLink = `/content-tonies/${household.id}/${tonie.id}/edit`
  const language = i18n.language === 'de' ? 'de' : 'en'
  const helloBubbleSvgUrl = `${process.env.PUBLIC_URL}/images/bubble-hello-${language}.svg`
  const dataTestIdPrefix = `content-tonie-card`

  return (
    <div
      data-testid={`${dataTestIdPrefix}-${tonie.id}`}
      data-testid-title-key={`${dataTestIdPrefix}-${title.key}`}
    >
      <PreviewCardContainer
        to={`/content-tonies/${household.id}/${tonie.id}`}
        shadowColor={
          tonie.lock ? theme.application.lockedShadowColor : undefined
        }
        onActionClick={() => {
          setLocked(true)
          onLock()
        }}
        data-trackingid="content-tonie-overview__tonie-card__card-click"
        isLocked={tonie.lock}
        header={
          <CardShared.CardHeaderWrapper>
            {/* if only one household, skip this and render placeholder, to not break the flexbox layout */}
            {showHousehold ? (
              <CardHeaderLink
                to="/households"
                data-trackingid="content-tonie-overview__tonie-card__household-button"
                icon={
                  household.access === 'owner' ? (
                    <Icon
                      type={icons.myHousehold}
                      fill={theme.colors.darkergrey}
                    />
                  ) : (
                    <Icon
                      type={icons.household}
                      fill={theme.colors.darkergrey}
                    />
                  )
                }
                title={t('GoToHousehold')}
              >
                {household.name}
              </CardHeaderLink>
            ) : (
              <div />
            )}
            <CardHeaderButton
              to={editLink}
              title={t('EditContentTonie')}
              icon={
                <Icon
                  type={icons.edit}
                  width={isLaptopWidth ? 24 : 16}
                  fill={theme.colors.darkergrey}
                />
              }
              data-trackingid="content-tonie-overview__tonie-card__pen-button"
              data-testid={`card-edit-link-${tonie.id}`}
            />
          </CardShared.CardHeaderWrapper>
        }
        media={
          <>
            <CardMediaWrapper>
              <CardImage src={tonie.imageUrl} alt="" />
              {!isLocked ? (
                <BubbleImage
                  width="60"
                  height="53"
                  src={helloBubbleSvgUrl}
                  alt=""
                />
              ) : null}
            </CardMediaWrapper>
            {isAudioLibraryAvailable && tonie.tune && (
              <TuneBadgeContainer data-testid="tunes-icon">
                <TuneBadge type={icons.tuneFill} fill={theme.Green} />
              </TuneBadgeContainer>
            )}
          </>
        }
        title={title.value}
        description={description}
      />
    </div>
  )
}
