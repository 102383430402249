import {
  Accent,
  FocusStyles,
  Headline4,
  theme,
  themeHelpers,
} from '@boxine/tonies-ui'
import sortBy from 'lodash/sortBy'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Tonie from '../Tonie'
import { Text } from '../Typography'
import { ResponsiveLinkButton } from '../visual-elements/responsive-button'
const { media } = themeHelpers

const StyledHeadline = styled(Headline4)`
  margin: 0.5rem 0;
  font-size: 1.25rem;

  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

export const ToniesWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  ${media.tabletL`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${media.laptop`
    grid-template-columns: repeat(4, 1fr);
  `};
`
export const TonieWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  color: ${theme.Gray};
  text-decoration: none;
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-radius: 0.1875rem;
  img {
    transition: 0.2s transform ease-in-out;
  }
  &:hover {
    color: ${theme.BrandPrimary};
    img {
      transform: scale(1.12);
    }
  }
  ${FocusStyles};
`

interface CreativeTonieDashboard {
  id: string
  householdId: string
  imageUrl: string
  live: boolean
  name: string
}

// For testing purposes we can not wrap this element inside our "Box"
export const DashboardCreativeTonies = ({
  creativeTonies = [],
}: {
  creativeTonies: CreativeTonieDashboard[]
}) => {
  const { t } = useTranslation(['home', 'default'])
  const tonies = useMemo(
    () => sortBy(creativeTonies, ['name']).slice(0, 4),
    [creativeTonies]
  )
  return (
    <>
      <Trans i18nKey="home:YourCreativeTonies">
        <StyledHeadline
          weight={500}
          id="puppeteer-home-headline"
          data-testid="dashboard-creative-tonies-headline"
          asHTMLTag="h2"
        >
          Deine <Accent>Kreativ-Tonies</Accent>
        </StyledHeadline>
      </Trans>
      {tonies.length > 0 ? (
        <>
          <ToniesWrapper>
            {tonies.map(c => {
              return (
                <TonieWrapper
                  key={c.id}
                  to={`/creative-tonies/${c.householdId}/${c.id}`}
                  data-testid="creative-tonie-link"
                >
                  <Tonie
                    src={c.imageUrl}
                    live={c.live}
                    name={c.name}
                    alt={c.name}
                    direction="row"
                    maxLength={50}
                    ratio={[1, 1.2]}
                  />
                </TonieWrapper>
              )
            })}
          </ToniesWrapper>
          <ResponsiveLinkButton
            styling="primary"
            href="/creative-tonies"
            data-trackingid="home__to-creative-tonies__button-press"
          >
            {t('home:AllCreativeTonies')}
          </ResponsiveLinkButton>
        </>
      ) : (
        <>
          <Text>{t('home:MessageNoCreativeTonies')}</Text>
          <ResponsiveLinkButton styling="primary" href="/creative-tonies">
            {t('home:LearnMoreAboutCreativeTonies')}
          </ResponsiveLinkButton>
        </>
      )}
    </>
  )
}
