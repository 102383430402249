import TagManager from 'react-gtm-module'
import { generateUniqueId } from '../functions/functions'

/**
 * GTM dataLayer helper
 * See https://boxine.atlassian.net/wiki/spaces/DI/pages/3693543684/Parameters+Dimensions+Metrics for documentation
 */
type GenericParameters = {
  event: string
  event_id?: string
  event_version?: number
  message: string | null
  action_context: string
  selection_mode: 'multi' | 'single' | null
  media_source: string
}

type ItemDetails =
  | {
      item_count: number
      item_id: string | null
      item_name: string | null
      item_brand: string | null
      item_licensor: string | null
      item_category: 'audio-content'
      item_category2: 'default-content' | 'non-default-content'
      item_category3: 'paid' | 'free' | 'own'
      item_category4:
        | 'paid-for-creative-tonies'
        | 'paid-for-content-tonies'
        | 'free-other'
        | null
      item_genre: string | null
      item_language: string | null
      item_playtime: number | null
      item_age_min: number | null
    }
  | undefined

type TonieDetail = {
  tonie_id: string | null
  tonie_name: string | null
  tonie_type: 'creative' | 'content'
}

type Others = {
  assigned_playtime: number
}

function mapGenericParameters(
  event: string,
  event_id: string,
  eventVersion = 1,
  message: string | null,
  actionContext: string,
  selectionMode: GenericParameters['selection_mode'] | null
): GenericParameters {
  return {
    event,
    event_id: event_id,
    event_version: eventVersion,
    message: message,
    action_context: actionContext,
    selection_mode: selectionMode,
    media_source: 'web',
  }
}

type Content = {
  id: string
  title: string

  series?: {
    name: string
    group?: {
      name: string
    }
  } | null
  languageUnicode?: string
  genre?: {
    key: string
  } | null
  minAge?: number
}

type FreeContent = Content & { token: string; subtitle: string | null }
type PaidContent = Content & { allCreativeTonies: boolean }

function isPaidCreativeTonieContent(
  content: FreeContent | PaidContent | Content
): content is PaidContent {
  return 'allCreativeTonies' in content
}

function isFreeContent(
  content: FreeContent | PaidContent | Content
): content is FreeContent {
  return 'token' in content
}

function mapItemDetails(
  content: FreeContent | PaidContent | Content | null,
  itemCount: number,
  itemPlaytime: number | null,
  isDefaultContentAssignment: boolean,
  tonieType: TonieDetail['tonie_type']
): ItemDetails {
  if (!content) {
    return undefined
  }

  const itemDetails: ItemDetails = {
    item_count: itemCount,
    item_id: content.id || null,
    item_name: content.title || null,
    item_brand: null,
    item_licensor: null,
    item_category: 'audio-content',
    // Set this property if Coop content is assigned to a coop Creative-Tonie
    // or if Content-Tonie is reset to its default content
    item_category2: isDefaultContentAssignment
      ? 'default-content'
      : 'non-default-content',
    item_category3: 'own',
    item_category4: null, // we cannot distinguish between 'own-upload' or 'own-recording' like the App so we set this to `null`
    item_genre: content.genre?.key || null,
    item_language: content?.languageUnicode || null,
    item_playtime: itemPlaytime ? Math.round(itemPlaytime) : null,
    item_age_min: content.minAge || null,
  }

  if (content.series) {
    itemDetails.item_brand =
      content.series.group?.name !== undefined
        ? content.series.group.name
        : content.series.name
  }

  if (isPaidCreativeTonieContent(content) && tonieType === 'creative') {
    itemDetails.item_category3 = 'paid'
    itemDetails.item_category4 = 'paid-for-creative-tonies'
  }

  if (!isFreeContent(content) && tonieType === 'content') {
    itemDetails.item_category3 = 'paid'
    itemDetails.item_category4 = 'paid-for-content-tonies'
  }

  if (isFreeContent(content)) {
    itemDetails.item_category3 = 'free'
    itemDetails.item_category4 = 'free-other'
    itemDetails.item_brand = content.subtitle
  }

  return itemDetails
}

function mapTonieDetail(tonieType: TonieDetail['tonie_type']): TonieDetail {
  return {
    tonie_id: null,
    tonie_name: null,
    tonie_type: tonieType,
  }
}

function mapOthers(assignedPlaytime: number): Others {
  return {
    assigned_playtime: Math.round(assignedPlaytime),
  }
}

export function generateTrackingEvent({
  event,
  actionContext,
  itemCount,
  tonieType,
  /* optional parameter */
  assignedPlaytime = null,
  content = null,
  eventId = generateUniqueId(),
  eventVersion = 1,
  isDefaultContentAssignment = false,
  itemPlaytime = null,
  message = null,
  selectionMode = null,
}: {
  event: string
  actionContext: string
  itemCount: number
  tonieType: TonieDetail['tonie_type']
  /* optional parameter */
  assignedPlaytime?: number | null
  content?: FreeContent | PaidContent | Content | null
  eventId?: GenericParameters['event_id']
  eventVersion?: number
  isDefaultContentAssignment?: boolean
  itemPlaytime?: number | null
  message?: string | null
  selectionMode?: GenericParameters['selection_mode'] | null
}) {
  let data = {
    ...mapGenericParameters(
      event,
      eventId,
      eventVersion,
      message,
      actionContext,
      selectionMode
    ),
    ...mapTonieDetail(tonieType),
    ...(assignedPlaytime === null ? '' : mapOthers(assignedPlaytime)),
  }

  if (content) {
    data = {
      ...data,
      ...mapItemDetails(
        content,
        itemCount,
        itemPlaytime,
        isDefaultContentAssignment,
        tonieType
      ),
    }
  }

  return TagManager.dataLayer({ dataLayer: data })
}

/**
 * Pushes an event to the GTM dataLayer
 * This function is an abstraction of the TagManager.dataLayer function to make it easier to adjust the GTM integration
 * @param event {string} - The name of the event to be pushed to the dataLayer}
 * @param data {Record<string, string | number | boolean | null>} - Optional data to be pushed to the dataLayer
 */
export function pushEvent(
  event: string,
  data?: Record<string, string | number | boolean | null>
) {
  TagManager.dataLayer({ dataLayer: { event, ...data } })
}
