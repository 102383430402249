import React from 'react'
import { ImageV2 } from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Text } from '../Typography'

const TonieboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  align-self: center;
  p {
    margin-left: 24px;
  }
`

interface TonieboxProps {
  src: string
  name: string
  width?: number
  alt?: string
  ratio?: [number, number]
}

const Toniebox = ({
  alt = 'Toniebox',
  name,
  src,
  width = 50,
  ratio = [1, 1],
}: TonieboxProps) => {
  return (
    <TonieboxWrapper className="puppeteer-toniebox-wrapper">
      <ImageV2 src={src} alt={alt} responsive={[width]} ratio={ratio} />
      <Text>{name}</Text>
    </TonieboxWrapper>
  )
}

export default Toniebox
