import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import bundledMetadata from '../../assets/meta.json'

export const MetaContext = React.createContext<{
  bundledGitCommit?: string
  remoteGitCommit?: string
}>({
  bundledGitCommit: undefined,
  remoteGitCommit: undefined,
})

interface Props {
  children: React.ReactNode
}

// time after which fresh remote metadata should be fetched
// set this to a few seconds when testing locally, and switch tabs to trigger the check
const REMOTE_VERSION_INFORMATION_CACHE_INTERVAL_IN_MS = 30 * 60 * 1000 // 30 minutes
function VersionCheckProvider({ children }: Props) {
  const bundledGitCommit = bundledMetadata.commit
  const [remoteGitCommit, setRemoteGitCommit] = useState<string>()
  const [remoteVersionCheckedAt, setRemoteVersionCheckedAt] = useState<number>()

  useEffect(() => {
    const isFetchedRemoteVersionStillFresh = () => {
      if (!remoteVersionCheckedAt) return false

      const remoteVersionLastCheckedDiffInMs =
        Date.now() - remoteVersionCheckedAt

      return (
        remoteVersionLastCheckedDiffInMs <=
        REMOTE_VERSION_INFORMATION_CACHE_INTERVAL_IN_MS
      )
    }

    const checkForNewRemoteVersion = async () => {
      if (isFetchedRemoteVersionStillFresh()) return

      try {
        const response = await axios.get('/meta.json')
        // update this immediately, so that updating remoteGitCommit won't trigger another fetch
        setRemoteVersionCheckedAt(Date.now())
        const remoteMetadata = response.data
        if (
          remoteMetadata?.commit &&
          remoteGitCommit !== remoteMetadata.commit
        ) {
          setRemoteGitCommit(remoteMetadata.commit)
        }
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setTag('feature', 'version-check-provider')
          scope.setExtra(
            'detail',
            'Error occurred when fetching the App Meta Data'
          )
          Sentry.captureException(error)
        })
      }
    }

    checkForNewRemoteVersion()

    // listen to browser tab visibility
    window.addEventListener('visibilitychange', checkForNewRemoteVersion, false)
    return () => {
      window.removeEventListener(
        'visibilitychange',
        checkForNewRemoteVersion,
        false
      )
    }
  }, [remoteGitCommit, remoteVersionCheckedAt])

  return (
    <MetaContext.Provider value={{ bundledGitCommit, remoteGitCommit }}>
      {children}
    </MetaContext.Provider>
  )
}

export default VersionCheckProvider
