import { gql } from 'graphql-tag'
import { TuneCardItem } from '../../components/TuneCard'
import { Household } from '../../utils/tonie-utils'
export const contentToniePageQuery = gql`
  query ContentTonies($canBuyTunes: Boolean!) {
    households {
      id
      name
      access
      contentTonies {
        id
        title
        lock
        series {
          id
          name
          group {
            id
            name
          }
        }
        imageUrl
        tune {
          id
          item {
            title
          }
        }
      }
    }
    tunesItems(
      first: 10
      compatibleTonies: "require"
      myTuneStatus: "purchasable"
    ) @include(if: $canBuyTunes) {
      edges {
        node {
          id
          title
          thumbnail
          description
          salesId
          series {
            name
          }
          price {
            status
            currency
            centAmount
            strikeCentAmount
          }
        }
      }
    }
  }
`

export interface ContentToniePageQuery {
  households: Household[]
  tunesItems: {
    edges: Array<{
      node: TuneCardItem
    }>
  }
}
