import { Headline3, media, OmniButton, Paragraph } from '@boxine/tonies-ui'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InnerContainer, LayoutV2 } from '../../layouts/LayoutV2'

const StyledLayout = styled(LayoutV2)`
  background: ${props => props.theme.LightBlue};

  ::before {
    background-color: ${props => props.theme.LightBlue};
  }
`

const StyledContainer = styled(InnerContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.25rem;
  max-width: 24rem;

  ${media.mobileM`
    margin-top: 4rem;
  `}

  ${media.tabletL`
    margin-top: 4rem;
    max-width: 42rem;
  `}
`

const ImageWrapper = styled.div`
  margin-top: 1.25rem;
  background: ${props => props.theme.White};
  border-radius: 100%;
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  ${media.mobileM`
    margin-top: 4.25rem;
    width: 13rem;
    height: 13rem;
    padding: 0;
  `}
`

const ButtonsWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 4.25rem;
  display: flex;

  ${media.mobileM`
    margin-top: 5rem;
  `}
`

const SubmitButton = styled(OmniButton)`
  margin-left: 2.5rem;
`

const StyledText = styled(Paragraph)`
  font-size: 1rem;
  text-align: center;
  margin: 0.75rem 0;
`

const StyledImg = styled.img`
  height: auto;
  max-width: 80%;
`

interface TonieResetProps {
  headline: string
  text: string
  backLink: string
  trackingIdPrefix: string
  resetCallback: () => void
}

export function TonieReset({
  headline,
  text,
  backLink,
  trackingIdPrefix,
  resetCallback = () => {},
}: TonieResetProps) {
  const { t } = useTranslation()

  const handleOnClick = useCallback(() => {
    resetCallback()
  }, [resetCallback])

  return (
    <StyledLayout>
      <StyledContainer>
        <Headline3>{headline}</Headline3>
        <StyledText>{text}</StyledText>
        <ImageWrapper>
          <StyledImg
            src={process.env.PUBLIC_URL + '/images/tonie-reset.svg'}
            alt=""
          />
        </ImageWrapper>
        <ButtonsWrapper>
          <OmniButton
            href={backLink}
            data-trackingid={`${trackingIdPrefix}-cancel__click`}
          >
            {t('default:Cancel')}
          </OmniButton>
          {/* TODO: Implement pending state (needs update in consumer components) */}
          <SubmitButton
            onClick={handleOnClick}
            styling="primary"
            data-trackingid={`${trackingIdPrefix}-reset__click`}
          >
            {t('tonie-reset:ResetAction')}
          </SubmitButton>
        </ButtonsWrapper>
      </StyledContainer>
    </StyledLayout>
  )
}
