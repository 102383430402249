import {
  ClientOptions,
  CombinedError,
  createClient,
  defaultExchanges,
  Exchange,
  Provider,
} from 'urql'
import { onPush, pipe } from 'wonka'

// List of all urql plugins
// urql calls them "exchanges"
const mergedExchanges: Exchange[] = []

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { devtoolsExchange } = require('@urql/devtools')

  mergedExchanges.push(devtoolsExchange)
}

/**
 * @description Exchange for urql to handle errors
 * @param onError
 */
const errorExchange =
  (onError: (error: CombinedError) => void): Exchange =>
  ({ forward }) => {
    return operations$ =>
      pipe(
        forward(operations$),
        onPush(result => {
          // This could also be more specific and check for `!result.data`, etc
          if (result.error) {
            onError(result.error)
          }
        })
      )
  }

// Add error handling to plugin list
mergedExchanges.push(errorExchange(handleError))

// Add default exchanges
mergedExchanges.push(...defaultExchanges)

/**
 * Extracts a authorization token (JWT) from local storage if possible
 * @returns {string} Empty string or Bearer with JWT as string
 */
function getToken() {
  try {
    const jwt = window.localStorage.getItem('authorization')

    return jwt ? `Bearer ${jwt}` : ''
  } catch (error) {
    return ''
  }
}

/**
 * @returns Returns fetch options
 */
function getFetchOptions(): RequestInit {
  const token = getToken()

  if (token === '') {
    return {}
  }

  return {
    headers: {
      Authorization: token,
    },
  }
}

/**
 * Handles errors from urql
 * @param error {CombinedError} Error object from urql
 * @returns void
 */
function handleError(error: CombinedError) {
  if (!error.response?.status) {
    return
  }

  const currentPath = window.location.pathname + window.location.search

  if (error.response.status === 401) {
    window.localStorage.removeItem('authorization')
    window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`
  }

  if (
    error.response.status >= 500 &&
    window.location.pathname !== `/error/${error.response.status}`
  ) {
    window.location.href = `/error/${error.response.status}?prev=${currentPath}`
  }
}

// Options for urql
export const defaultOptions: ClientOptions = {
  url: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  fetchOptions: getFetchOptions,
  exchanges: mergedExchanges,
}

// In case you need to modify the client's options you can import this
// Example usage in ./src/utils/test-utils/index.tsx (disabled caching)
export const createGraphqlClient = createClient

// Provider for urql
export const GraphqlProvider = Provider
