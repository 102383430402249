/* eslint-disable react/display-name */
import React, { useContext, useEffect } from 'react'
import {
  useStorage,
  PromotionBanner as Banner,
  PromotionBannerAccent,
  PromotionBannerBoldText,
  PromotionBannerText,
} from '@boxine/tonies-ui'
import { BLOCKS, MARKS, INLINES, Document } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import styled, { css, ThemeContext } from 'styled-components'
import * as v from '@badrap/valita'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'
import { useContentful } from '../../contentful'
import { contentfulDocument } from '../../contentful/checkContentfulData'
import { isSameOrigin } from '../../utils/functions/functions'
import { useAuth } from '../../providers/auth'

const linkStyles = css`
  padding: 0;
  text-decoration: underline;
  color: inherit;
`

const StyledRouterLink = styled(Link)`
  ${linkStyles}
`

const StyledLink = styled.a`
  ${linkStyles}
`

const promotionBannerSchema = v
  .object({
    trackingId: v.string().optional(),
    text: contentfulDocument,
  })
  .optional()

export function PromotionBanner() {
  const { data, error, isFetching } = useContentful({
    contentId: '3y5n2A3Ih8mH0hcdy5VB7x',
    schema: promotionBannerSchema,
  })

  const theme = useContext(ThemeContext)

  const { isAuthenticated } = useAuth()
  const { canBuyTunes } = useIsAudioLibraryAvailable()
  const isBannerEnabled = isAuthenticated && canBuyTunes && data

  // State for when the user manually closes the banner
  const [isShown, setIsShown] = useStorage(
    () => window.sessionStorage,
    'promotion-banner-visible',
    true
  )
  const location = useLocation()
  const isSetup = location.pathname.startsWith('/setup')
  const isWifi = location.pathname.startsWith('/wifi')
  const isLegals = location.pathname.startsWith('/legals')

  const isWebview =
    new URLSearchParams(location.search).get('webview') === 'app'

  const hasBanner =
    !isSetup &&
    !isWifi &&
    !isLegals &&
    isBannerEnabled &&
    !isWebview &&
    !isFetching &&
    data &&
    !error

  useEffect(() => {
    if (hasBanner && isShown) {
      // Starting with iOS 15 we can colorize the status bar at the
      // top of the screen.
      document
        .querySelector('meta[name="theme-color"]')!
        .setAttribute('content', theme.colors['lightblue-20'])
    } else {
      // Default status bar color
      document
        .querySelector('meta[name="theme-color"]')!
        .setAttribute('content', theme.colors.white)
    }
  }, [hasBanner, isShown, theme])

  if (!hasBanner) {
    return null
  }

  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: children => {
        return <PromotionBannerBoldText>{children}</PromotionBannerBoldText>
      },
      [MARKS.ITALIC]: children => (
        <PromotionBannerAccent>{children}</PromotionBannerAccent>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <PromotionBannerText>{children}</PromotionBannerText>
      ),
      [INLINES.HYPERLINK]: (node, content) => {
        const props = {
          'data-trackingid': `promotion-banner__banner-link__${
            data!.trackingId
          }`,
          'data-testid': 'promotion-banner-link',
        }

        if (!isSameOrigin(node.data.uri)) {
          return (
            <StyledLink
              href={node.data.uri}
              rel="noreferrer noopener"
              target="_blank"
              {...props}
            >
              {content}
            </StyledLink>
          )
        }

        return (
          <StyledRouterLink to={node.data.uri} {...props}>
            {content}
          </StyledRouterLink>
        )
      },
    },
  }

  return (
    <Banner
      onClose={() => {
        setIsShown(false)

        // Reset the status bar color when closing the banner.
        document
          .querySelector('meta[name="theme-color"]')!
          .setAttribute('content', theme.colors.white)
      }}
      isOpen={isShown}
    >
      {documentToReactComponents(data!.text as Document, renderOptions)}
    </Banner>
  )
}
