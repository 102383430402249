import React from 'react'
import styled from 'styled-components'
import { Headline4, Accent, Box, Button, useMedia } from '@boxine/tonies-ui'
import { Trans, useTranslation } from 'react-i18next'
import theme from '../../theme'
import { useShop } from '../../hooks/useShop'

const StyledHeadline = styled(Headline4)`
  margin: 0.5rem 0;
  font-size: 1.25rem;

  span {
    /* To prevent text from disappearing into background image */
    padding: 0.3rem;
    background: rgba(255, 255, 255, 0.75);
    box-decoration-break: clone;
  }

  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

export function ShopBox() {
  const { t } = useTranslation(['default', 'home'])
  const isLaptopWidth = useMedia({ minWidth: theme.screenLaptop })

  const { createLinkByMainCategory } = useShop()

  return (
    <Box
      backgroundColor={theme.colors.white}
      p="spacing-s"
      borderRadius={['xs']}
      mx={!isLaptopWidth ? 'spacing-s' : undefined}
    >
      <StyledHeadline weight={500} asHTMLTag="h2">
        <Trans i18nKey="home:GetNewTonies">
          <span>
            Lust auf neue Tonies? In unserem <Accent>Shop</Accent> gibt es jede
            Menge Hörfiguren mit spannenden Geschichten.
          </span>
        </Trans>
      </StyledHeadline>

      <Button
        as="a"
        variant="primary"
        href={createLinkByMainCategory('tonies')}
        rel="noopener noreferrer"
        target="_blank"
        data-trackingid="home__to-shop-link__button-press"
        data-testid="shop-link-home"
      >
        {t('home:ButtonShop')}
      </Button>
    </Box>
  )
}
