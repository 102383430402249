/**
 * Enum
 * Describes the three types of access rights to a household
 * @type {{full: string, limited: string}}
 */
export const MEMBER_ACCESS_RIGHTS = Object.freeze({
  full: 'full',
  limited: 'limited',
  owner: 'owner',
})

/**
 * Enum
 * Describes the two types of access rights to a household
 * @type {{full: string, limited: string}}
 */
export const CREATIVE_TONIE_PERMISSIONS = Object.freeze({
  implicit: 'implicit',
  explicit: 'explicit',
  none: 'none',
})

/**
 * Stolen from Stackoverflow
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param a
 * @returns {string}
 */
export const formatBytes = (a: number) => {
  if (0 === a) return '0 Bytes'
  const c = 1024,
    d = 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

/**
 * Enum
 * Describes the two types of access rights for an invitation
 * @type {{full: string, limited: string}}
 */
export const INVITATION_TYPES = Object.freeze({
  full: 'full',
  limited: 'limited',
})

export const CLIENT_ERROR_CODE = Object.freeze({
  unknown: 600,
  chunkLoading: 601,
  cookiesDisabled: 602,
  i18nFailed: 603,
} as const)

/**
 * Chapter Upload Status
 */
export const UPLOAD_PENDING = 'pending'
export const UPLOAD_PROGRESS = 'progress'
export const UPLOAD_COMPLETED = 'completed'

/**
 * How many times we try to upload a file (e.g. we retry one less time than this number).
 * @type {number}
 */
export const MAX_UPLOAD_TRIES = 5

/**
 * Number of parallel uploads
 * @type {number}
 */
export const MAX_UPLOAD_CONCURRENCY = 3

/**
 * Setup state recovery cookie configuration
 */
export const SETUP_COOKIE_OPTIONS = { expires: 1, sameSite: 'Lax' }
