import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import {
  Button,
  Lead,
  Headline1,
  SeparationLine,
  themeHelpers,
  Box,
  Modal,
  toast,
} from '@boxine/tonies-ui'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import NotFound from '../../../components/error-pages/NotFound'
import BoxContactSupport from '../../../components/sidebar-elements/box-contact-support'
import { http } from '../../../http.service'
import ChangeAccessModalContent from '../../../components/ChangeAccessModal/ChangeAccessModalContent'
import { Head } from '../../../components/head'
import { StyledAccent, Text } from '../../../components/Typography'
import DetailsBox from '../../../components/details-box/details-box'
import invitationDetailsQuery from '../../../queries/invitation-details-query'
import { LayoutV1 } from '../../../layouts/LayoutV1'
import RevokeInvitationModalContent from './RevokeInvitationModalContent'

const { media } = themeHelpers

const Line = styled(SeparationLine)`
  margin: 0 1em 1em 1em;
  width: auto;
`

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: max-content;

  ${media.tabletL`
    grid-template-columns: max-content max-content;
    grid-column-gap: 1rem;
  `}
`

export class InvitationDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isRevokeInvitationModalOpen: false,
      render404: false,
      invitation: null,
      isChangeAccessModalOpen: false,
      redirectToHouseholds: false,
    }
  }

  componentDidMount() {
    const { householdId, invitationId } = this.props.match.params

    http
      .post(`/graphql`, {
        query: invitationDetailsQuery(householdId, invitationId),
      })
      .then(({ data: { data } }) => {
        if (
          data.households.length === 0 ||
          data.households[0].invitations.length === 0
        ) {
          this.setState({ render404: true })
        } else {
          this.setState({ invitation: data.households[0].invitations[0] })
        }
      })
      .catch(error => {
        if (error.response) {
          toast(this.props.t('default:TOASTSomethingWentWrong'), 'error', {
            autoClose: false,
            toastId: 'something-went-wrong',
          })
        }
      })
  }

  async resendInvitationMail(event) {
    event.stopPropagation()

    const { householdId, invitationId } = this.props.match.params

    try {
      await http.post(
        `/households/${householdId}/invitations/${invitationId}/resend`
      )
      toast(
        this.props.t('invitation-details:TOASTResendInvitationSuccess'),
        'success',
        { toastId: 'resend-invitation-success' }
      )
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          toast(
            this.props.t(
              'invitation-details:TOASTMaybeInvitationAlreadyAccepted'
            ),
            'error',
            {
              toastId: 'resend-invitation-failure',
            }
          )
        } else {
          toast(
            this.props.t('invitation-details:TOASTResendInvitationFailure'),
            'error',
            {
              toastId: 'resend-invitation-failure',
            }
          )
        }
      }
    }
  }

  openRevokeInvitationModal(event) {
    event.stopPropagation()

    this.setState({
      ...this.state,
      isRevokeInvitationModalOpen: true,
    })
  }

  cancelRevokeInvitation = event => {
    if (event) event.stopPropagation()

    this.setState({
      ...this.state,
      isRevokeInvitationModalOpen: false,
    })
  }

  openChangeAccessModal = () => {
    this.setState({ isChangeAccessModalOpen: true })
  }

  cancelAccessChange = event => {
    if (event) event.stopPropagation()
    this.setState({ isChangeAccessModalOpen: false })
  }

  changeAccess = event => {
    event.stopPropagation()

    const { householdId, invitationId } = this.props.match.params
    const newType = { full: 'limited', limited: 'full' }[
      this.state.invitation.itype
    ]

    http
      .patch(`/households/${householdId}/invitations/${invitationId}`, {
        itype: newType,
      })
      .then(({ data: invitation }) => {
        this.setState({ isChangeAccessModalOpen: false, invitation })
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 404) {
            toast(this.props.t('default:TOASTInvitationNotFound'), 'error', {
              toastId: 'invitation-not-found',
            })
          } else {
            toast(this.props.t('default:TOASTSomethingWentWrong'), 'error', {
              toastId: 'invitation-not-found',
            })
          }
        }
      })
  }

  revokeInvitation = async event => {
    event.stopPropagation()

    const { householdId, invitationId } = this.props.match.params

    try {
      await http.delete(
        `/households/${householdId}/invitations/${invitationId}`
      )
      toast(
        this.props.t('revoke-invitation-modal:TOASTRevokeInvitationSuccess'),
        'success',
        { toastId: 'revoke-invitation-success' }
      )
      this.setState({ redirectToHouseholds: true })
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 403:
            toast(this.props.t('default:TOASTNoPermission'), 'error', {
              toastId: 'revoke-invitation-failure',
            })
            break
          case 404:
            this.setState({ redirectToHouseholds: true })
            break
          default:
            toast(this.props.t('default:TOASTSomethingWentWrong'), 'error', {
              toastId: 'revoke-invitation-failure',
            })
        }
      }
    }
  }

  render() {
    const { householdId } = this.props.match.params

    return this.state.redirectToHouseholds ? (
      <Redirect to="/households" />
    ) : (
      <LayoutV1>
        {(this.state.render404 && <NotFound />) || (
          <main className="invitation-details-grid">
            <Head pageTitle={this.props.t('invitation-details:PageTitle')} />
            {this.state.invitation && (
              <>
                <>
                  <div style={{ gridArea: 'headline' }}>
                    <Trans i18nKey="invitation-details:PageHeader">
                      <Headline1>
                        <StyledAccent>Detailinfos</StyledAccent> zu dieser
                        Einladung
                      </Headline1>
                    </Trans>
                    <Text>
                      {this.props.t('invitation-details:IntroductionText', {
                        name: this.state.invitation.email,
                      })}
                    </Text>
                    <Line />
                  </div>
                  <div style={{ gridArea: 'details' }}>
                    {householdId && (
                      <>
                        <DetailsBox
                          model={this.state.invitation}
                          onChangeAccess={this.openChangeAccessModal}
                        />
                        <ButtonRow>
                          <Button
                            onClick={this.resendInvitationMail.bind(this)}
                          >
                            {this.props.t(
                              'invitation-details:ResendInvitation'
                            )}
                          </Button>
                          <Button
                            backgroundColor="white"
                            onClick={this.openRevokeInvitationModal.bind(this)}
                          >
                            {this.props.t(
                              'invitation-details:RevokeInvitation'
                            )}
                          </Button>
                        </ButtonRow>
                      </>
                    )}
                  </div>
                  <div style={{ gridArea: 'sidebar' }}>
                    <Box>
                      <Trans i18nKey="invitation-details:GoodToKnow">
                        <Lead>
                          Gut zu
                          <StyledAccent>wissen</StyledAccent>
                        </Lead>
                      </Trans>
                      <Text>
                        {this.props.t('invitation-details:ChangeAccessAnytime')}
                      </Text>
                      <Text>
                        {this.props.t(
                          'invitation-details:FullAccessToniesAnytime'
                        )}
                      </Text>
                      <Text>
                        {this.props.t('invitation-details:Important')}
                      </Text>
                    </Box>
                    <BoxContactSupport />
                  </div>
                </>

                <Modal
                  headline={this.props.t(
                    'revoke-invitation-modal:RevokeInvitation'
                  )}
                  isOpen={this.state.isRevokeInvitationModalOpen}
                  onClose={this.cancelRevokeInvitation}
                >
                  <RevokeInvitationModalContent
                    onClose={this.cancelRevokeInvitation}
                    invitation={this.state.invitation}
                    onSubmit={this.revokeInvitation}
                  />
                </Modal>

                <Modal
                  headline={this.props.t('default:ChangeAccessModalTitle')}
                  isOpen={this.state.isChangeAccessModalOpen}
                  onClose={this.cancelAccessChange}
                >
                  <ChangeAccessModalContent
                    onClose={this.cancelAccessChange}
                    onSubmit={this.changeAccess}
                    type={this.state.invitation.itype}
                  />
                </Modal>
              </>
            )}
          </main>
        )}
      </LayoutV1>
    )
  }
}

InvitationDetails.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

export default withTranslation(['default', 'invitation-details'])(
  InvitationDetails
)
