import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const httpConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
} as const

export const isHttpError = axios.isAxiosError

/**
 * Axios instance to use for http calls
 */
export const http = axios.create(httpConfig)

/**
 * Interceptors
 */

type SuccessFn =
  | ((
      value: AxiosResponse<any>
    ) => AxiosResponse<any> | Promise<AxiosResponse<any>>)
  | undefined

type RequestFn = (value: AxiosRequestConfig) => AxiosRequestConfig

type ErrorFn = ((error: any) => any) | undefined

export function addRequestInterceptor(success: RequestFn) {
  http.interceptors.request.use(success)
  return true
}

export function addResponseInterceptor(success: SuccessFn, error: ErrorFn) {
  http.interceptors.response.use(success, error)
  return true
}

export function onNetworkError(callback: (error: any) => void) {
  http.interceptors.response.use(
    success => success,
    error => {
      if (!error.response && error.request) {
        callback(error)
      }
      return Promise.reject(error)
    }
  )
  return true
}

export function onUnauthorizedError(callback: () => void) {
  http.interceptors.response.use(
    success => success,
    error => {
      if (error.response?.status === 401) {
        callback()
      }
      return Promise.reject(error)
    }
  )
}
