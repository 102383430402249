import { gql } from 'graphql-tag'

export const contentTonieDefaultData = gql`
  query ContentTonieDetail($householdId: String, $contentTonieId: String) {
    households(id: $householdId) {
      id
      contentTonies(id: $contentTonieId) {
        id
        defaultEpisodeId
        title
        secondsPresent
        genre {
          key
          title
        }
        minAge
        languageUnicode
        series {
          name
        }
      }
    }
  }
`
export interface ContentTonieDefaultData {
  households: Array<{
    id: string
    contentTonies: Array<{
      id: string
      defaultEpisodeId: string
      title: string
      secondsPresent: number
      genre?: {
        key: string
        title: string
      }
      minAge: number
      languageUnicode: string
      series?: {
        name: string
      }
    }>
  }>
}
