import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'

enum UtmKeys {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
}

/**
 * Returns the UTM parameters from session storage.
 * @returns {Record<string, string>} UTM parameters from session storage
 */
function getUtmFromSessionStorage(): Record<string, string> {
  try {
    const utmObject = {}

    Object.values(UtmKeys).forEach(key => {
      const value = window.sessionStorage.getItem(key)

      if (value) {
        utmObject[key] = value
      }
    })

    return utmObject
  } catch (error) {
    console.error(error)

    return {}
  }
}

/**
 * Checks if all UTM parameters are present.
 * @param searchParams {URLSearchParams}
 * @returns {boolean} true if all UTM parameters are present
 */
function hasUtmParams(searchParams: URLSearchParams): boolean {
  return Object.values(UtmKeys).every(value => searchParams.has(value))
}

const UtmContext = createContext<{
  appendUtmToUrl(url: string): string
  getUtmParams(): URLSearchParams
}>({
  appendUtmToUrl(url) {
    return url
  },
  getUtmParams() {
    return new URLSearchParams()
  },
})

export function useUtm() {
  const context = useContext(UtmContext)

  if (!context)
    throw new Error(
      'In order to use this hook, please add the <UtmProvider> to your app'
    )

  return context
}

export function UtmProvider(props: { children: ReactNode }) {
  const location = useLocation()

  /**
   * Set utm_source, utm_medium, and utm_campaign from URL params
   * on on first sight (first render or set afterwards if there aren't set already).
   *
   * See: https://boxine.atlassian.net/browse/TWAS-4320
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    // If the URL doesn't contain any UTM parameters, don't do anything.
    if (!hasUtmParams(searchParams)) {
      return
    }

    const utmObject = getUtmFromSessionStorage()

    // If the utm parameters in the session storage are already set, don't do anything.
    if (Object.values(utmObject).length > 0) {
      return
    }

    // Set the utm parameters in the session storage.
    searchParams.forEach((value, key) => {
      if (value === undefined) {
        return
      }

      try {
        window.sessionStorage.setItem(key, value)
      } catch (error) {
        console.error(error)
      }
    })
  }, [location.search])

  /**
   * Returns a given URL with UTM params appended.
   * @param url {string} URL to append UTM params to
   * @returns {string} URL with UTM params
   */
  function appendUtmToUrl(url: string): string {
    try {
      const nextUrl = new URL(url)

      const utmObject = getUtmFromSessionStorage()

      Object.entries(utmObject).forEach(([key, value]) => {
        const encodedValue = encodeURIComponent(value)

        nextUrl.searchParams.set(key, encodedValue)
      })

      return nextUrl.toString()
    } catch (error) {
      console.error(error)

      return url
    }
  }

  function getUtmParams(): URLSearchParams {
    const utmObject = getUtmFromSessionStorage()

    return new URLSearchParams(utmObject)
  }

  return (
    <UtmContext.Provider
      value={{
        appendUtmToUrl,
        getUtmParams,
      }}
    >
      {props.children}
    </UtmContext.Provider>
  )
}
