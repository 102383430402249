import { useEffect, useState } from 'react'

export const loadingScripts = new Map<string, HTMLScriptElement>()
export const loadedScripts = new Set<string>()

type LoadingState = 'initial' | 'loading' | 'loaded' | 'error'

export default function useScriptLoader(
  src: string,
  shouldInsertScript = true,
  onScriptElementCreated?: (element: HTMLScriptElement) => void,
  pause?: boolean
) {
  const [status, setStatus] = useState<LoadingState>('initial')

  useEffect(() => {
    if (pause) return

    if (loadedScripts.has(src)) {
      setStatus('loaded')
      return
    }

    if (loadingScripts.has(src)) {
      setStatus('loading')
      // another hook instance created and inserted the script, so only
      // attach event listeners, so we know when it finished loading
      // ! is necessary, unfortunately => https://github.com/microsoft/TypeScript/issues/9619
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      addListeners(loadingScripts.get(src)!)
      return
    }

    function onLoad() {
      setStatus('loaded')

      loadingScripts.delete(src)
      loadedScripts.add(src)
    }

    function onError() {
      setStatus('error')
      loadingScripts.delete(src)
    }

    function addListeners(scriptElement: HTMLScriptElement) {
      scriptElement.addEventListener('load', onLoad)
      scriptElement.addEventListener('error', onError)
    }

    const scriptElement = document.createElement('script')
    if (shouldInsertScript) {
      scriptElement.src = src

      if (onScriptElementCreated) {
        onScriptElementCreated(scriptElement)
      }

      addListeners(scriptElement)

      document.body.appendChild(scriptElement)
      loadingScripts.set(src, scriptElement)
    }

    return () => {
      scriptElement.removeEventListener('load', onLoad)
      scriptElement.removeEventListener('error', onError)
    }
  }, [onScriptElementCreated, pause, shouldInsertScript, src])

  return { loaded: status === 'loaded', error: status === 'error' }
}
