import { gql } from 'graphql-tag'
import { FlagsResponse, MeResponse } from './profileTypes'

export type UserProfileQuery = {
  me: MeResponse
  flags: FlagsResponse
}

export const userProfileQuery = gql`
  query UserProfileQuery {
    flags {
      canBuyTunes
      canBuyTunesForContentTonies
      contentRegion
      retailerUrl
    }
    me {
      acceptedTermsOfUse
      anyPublicContentTokens
      authCode
      country
      creativeTonieShopUrl
      email
      firstName
      hasAnyContentTonies
      hasAnyCreativeTonies
      inHouseholdWithTunesOwners
      isBetaTester
      isEduUser
      lastName
      notificationCount
      ownsTunes
      profileImage
      region
      tracking
      unicodeLocale
      uuid
    }
  }
`
