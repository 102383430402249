import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../../providers/auth'
import { useUserProfile } from '../../providers/user-profile'

interface RedirectExternalProps extends RouteProps {
  path: string
  target: string
}

// Ensure that we don't redirect with the fallback geoip values (when not logged in)
// ignore that when the user is already authenticated
function WaitForGeoIp({ target }: { target: string }) {
  const { isFetching } = useUserProfile()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isFetching || isAuthenticated) {
      window.location.replace(target)
    }
  }, [isFetching, isAuthenticated, target])
  return null
}

// React-Router's Redirect only works for internal links
const RedirectExternal = ({ target, path }: RedirectExternalProps) => (
  <Route
    path={path}
    render={() => {
      return <WaitForGeoIp target={target} />
    }}
  />
)

export default RedirectExternal
