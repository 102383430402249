import { createGlobalStyle } from 'styled-components'
import { media } from './theme'

const isEdge18 = /\sEdge\/18\./.test(navigator.userAgent)
const supportsGridStart = isEdge18 ? '' : '@supports (display: grid) {'
const supportsGridEnd = isEdge18 ? '' : '}'

export const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    min-height: calc(100 * var(--vh, '1vh'));
    flex-direction: column;

    /**
     * The body color is dynamically set to change the status
     * bar color on iOS 15. Therefore we set the main background
     * color on the React container we render our app into.
     */
    background: rgb(236, 237, 238);
  }

  hr {
    background-color: #dfdfdf;
    box-shadow: 0 1px 0 0 #f1f1f1;
    border: none;
    height: 1px;
  }

  .container {
    width: 100%;
    margin: 0 auto;
  }

  .page-spinner {
    display: grid;
    justify-items: center;
  }

  ${supportsGridStart}
    .grid {
      display: grid;
      position: relative;
      min-height: ${props =>
        props.theme.application.loadingSpinnerHeight * 2}px;
      grid-gap: 1em;
      ${media.laptop`
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
      `}
    }

    .tonieboxes-grid,
    .creative-tonies-grid,
    .members-grid,
    .member-details-grid,
    .invitation-details-grid {
      display: grid;
      grid-gap: 1em;
      grid-template-areas: "headline" "details" "sidebar";
      ${media.laptop`
         grid-template-areas: "headline headline" "details sidebar";
         grid-template-columns: 2fr 1fr;
      `}
    }

    .creative-tonie-details-grid,
    .toniebox-details-grid {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 1em;
      grid-template-areas: "navigation" "content" "sidebar";

      ${media.laptop`
         grid-template-areas: 
          "navigation navigation"
          "content sidebar";
         grid-template-columns: 66.66% 33.33%;
      `}
    }
  ${supportsGridEnd}

  button {
    cursor: pointer;
  }
`
