import { FeatureFlagAdapter, Flags } from '../types'
import { newEmitter } from '../utils'

interface NoopAdapter extends FeatureFlagAdapter {
  update(flags: Flags): void
}

export function newNoopAdapter(
  initialFlags: Flags = {},
  isReady = true
): NoopAdapter {
  const emitter = newEmitter()

  const state = { isReady }
  emitter.on('ready', () => (state.isReady = true))

  let flags = initialFlags

  return {
    update(nextFlags: Record<string, any>) {
      flags = { ...flags, ...nextFlags }
      emitter.trigger('change', flags)
    },
    allFlags: () => flags,
    setUser: () => {},
    get isReady() {
      return state.isReady
    },
    ...emitter,
  }
}
