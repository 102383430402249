import React from 'react'
import styled from 'styled-components'
import { media } from '@boxine/tonies-ui'

const PageHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 1.75rem;

  ${media.laptop`
    flex-direction: row;
    align-items: center;
    padding-bottom: 1.75rem;
    padding-top: 2rem;
  `}
`

const ChildrenWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-top: 1.5rem;
  align-items: center;
  justify-content: flex-start;

  ${media.tablet`
    margin-top: 1rem;
  `}

  ${media.laptop`
    padding: 0 0 0 4rem;
    margin-top: 0.125rem;
  `}
`
const TitleWrapper = styled.span`
  white-space: nowrap;
  display: flex;

  ${media.laptop`
    align-self: flex-start;
  `}
`

interface PageTitleSectionProps {
  title?: React.ReactNode
  children?: React.ReactNode
  hasHouseholdFilter?: boolean
}

export function PageTitleSection({
  title,
  children,
  hasHouseholdFilter = false,
}: PageTitleSectionProps) {
  return (
    <PageHeaderInner>
      {hasHouseholdFilter ? <TitleWrapper>{title}</TitleWrapper> : title}
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </PageHeaderInner>
  )
}
