import React from 'react'
import {
  Headline2,
  Headline3,
  Lead,
  Paragraph,
  theme,
  Box,
} from '@boxine/tonies-ui'
import styled from 'styled-components'
import { Skeleton } from '../Skeleton'
const Sidebar = styled.div`
  grid-area: sidebar;
  p:first-child {
    margin-top: 0;
  }
`

const DownloadSteps = styled.ol`
  list-style: none;
  margin-left: 16px;

  li {
    margin: 0.5rem 0;
  }

  a {
    color: ${theme.BrandPrimary};
    text-decoration: none;
  }
`

export const InnerFeaturePreview = function () {
  return (
    <div style={{ gridArea: 'details', position: 'relative' }}>
      <>
        <Headline3>
          <Skeleton width={300} />
        </Headline3>
        <Paragraph>
          <Skeleton width="100%" />
        </Paragraph>
        <Box
          backgroundColor={theme.colors.white}
          p="spacing-s"
          borderRadius={['xs']}
        >
          <Headline2>
            <Skeleton width={300} />
          </Headline2>
          <Paragraph>
            <Skeleton width="100%" />
          </Paragraph>
          <Headline2>
            <Skeleton width={300} />
          </Headline2>
          <Paragraph>
            <Skeleton width="100%" />
          </Paragraph>
          <Headline2>
            <Skeleton width={300} />
          </Headline2>
          <Paragraph>
            <Skeleton width="100%" />
          </Paragraph>
          <Headline2>
            <Skeleton width={300} />
          </Headline2>
          <Paragraph>
            <Skeleton width="100%" />
          </Paragraph>
        </Box>
      </>
    </div>
  )
}

export class FeaturePreview extends React.PureComponent {
  state = {
    shouldRender: false,
  }

  timeout = null

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        shouldRender: true,
      })
    }, 500)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return this.state.shouldRender ? (
      <main className="creative-tonies-grid">
        <>
          <InnerFeaturePreview />
          <Sidebar>
            <Box>
              <Lead>
                <Skeleton width="100%" />
              </Lead>
              <Paragraph>
                <Skeleton width="100%" />
              </Paragraph>
              <DownloadSteps>
                <li>
                  <Skeleton width="100%" />
                </li>
                <li>
                  <Skeleton width="100%" />
                </li>
                <li>
                  <Skeleton width="100%" />
                </li>
              </DownloadSteps>
            </Box>
            <Box style={{ marginTop: '16px' }}>
              <Lead>
                <Skeleton width="100%" />
              </Lead>
              <Paragraph>
                <Skeleton width="100%" />
              </Paragraph>
            </Box>
          </Sidebar>
        </>
      </main>
    ) : null
  }
}
