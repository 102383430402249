import React from 'react'
import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'

const Container = styled.main`
  background-color: ${props => props.theme.White};
  position: relative;
  margin-top: 1rem;

  &::before {
    background-color: ${props => props.theme.White};
    content: '';
    display: block;
    height: 5rem;
    width: 100%;
    position: absolute;
    top: -5rem;
  }
`

export const InnerContainer = styled.div<{ hasHouseholdFilter?: boolean }>`
  z-index: 1;
  max-width: ${props => props.theme.application.maxContentWidth};
  padding-left: 1em;
  padding-right: ${props => (props.hasHouseholdFilter ? '0' : '1em')};
  padding-bottom: ${props => (props.hasHouseholdFilter ? '0.75rem' : '0')};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  white-space: ${props => (props.hasHouseholdFilter ? 'nowrap' : 'initial')};
  overflow: ${props => (props.hasHouseholdFilter ? 'hidden' : 'initial')};

  ${media.laptop`
    padding-right: 1em;
    white-space: normal;
  `}
`

interface Props {
  children: React.ReactNode
  className?: string
}

export function LayoutV2({ children, className }: Props) {
  return <Container className={className}>{children}</Container>
}
